import React, {useEffect} from "react";
import {useNavigate} from 'react-router-dom';

//import BrandSlider from "./../components/sliders/Brand";
import "./BasesConcurso.css"

import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import CardContainer from "../components/elements/CardContainer";


const BasesConcurso = (props) => {
    const urlImageDesktopTeleton = process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/teleton2024/bannerTCDesktop.png"
    const urlImageMobileTeleton = process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/teleton2024/bannerMobile.png"
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {

    }, [])
    const bodyInfoTeleton1 = [


        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",

                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",

                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": urlImageDesktopTeleton,
                }
            ]
        },


    ]
    const bodyInfoTeleton1Mobile = [


        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",
                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "#donation-form",
                    "image": "",
                    "type": "text",
                    "imagepath": urlImageMobileTeleton
                }
            ]
        }
    ]
    return (
        <>


            {fullScreen ?
             <section className="home-slider position-relative ">
             <CardContainer key={"card1"} data={bodyInfoTeleton1Mobile} config={{}}/>
             </section>

             :
             <>
             <section className="home-slider position-relative">
             <CardContainer key={"card1"} data={bodyInfoTeleton1} config={{}}/>
             </section>

             </>
             }

            <div id={"bases"}>
                <h1>Bases y Condiciones del Sorteo 2024</h1>
                <h1>Agradecemos a ROHO, BOA Y TOTTO, empresas comprometidas con la niñez, por hacer posible el reconocimiento a los héroes y heroínas que contribuyen al cumplimiento de la misión de UNICEF.</h1>
                <section>
                    <h2>Sobre quiénes pueden participar del sorteo</h2>
                    <p>
                        Participan del sorteo:
                        <ul>
                            <li>Donantes individuales mensuales actuales que se encuentren ACTIVOS al 11 de agosto y;
                            </li>
                            <li>Donantes individuales mensuales NUEVOS que se hayan afiliado desde el 1 de julio al 11
                                de agosto del 2024.
                            </li>
                        </ul>
                        <strong>Notas:</strong>
                        <ul>
                            <li>No podrán participar del sorteo las personas que realicen una donación única.</li>
                            <li>No podrán participar del sorteo el personal de UNICEF y RED UNO ni sus familiares en
                                primer grado.
                            </li>
                            <li>No podrán participar menores de edad.</li>
                        </ul>
                    </p>
                </section>

                <section>
                    <h2>Sobre los tipos de donantes y las formas de participación del sorteo</h2>
                    <p>
                        Para los fines del sorteo se entiende:
                        <ul>
                            <li><strong>Donante mensual recurrente ACTIVO:</strong> La persona natural mayor de 18 años
                                que realiza donaciones mensuales a UNICEF a través de una tarjeta de débito, crédito, o
                                cuenta bancaria cuya fecha de captación se haya realizado hasta el 30 de junio de 2024.
                            </li>
                            <li><strong>Donante mensual recurrente NUEVO:</strong> La persona natural mayor de 18 años
                                que tiene una donación mensual activa cuya fecha de captación se haya realizado a partir
                                del 1 de julio de 2024 hasta el momento del sorteo.
                            </li>
                        </ul>
                    </p>
                </section>

                <section>
                    <h2>Participación en el sorteo para donantes mensuales recurrentes ACTIVOS de UNICEF</h2>
                    <p>
                        Podrán participar del sorteo todos los donantes ACTIVOS hasta el 11/08/24 de forma automática accediendo a una oportunidad.
                        <br/>
                        Podrán participar del sorteo todos los donantes ACTIVOS que realicen un incremento en su
                        donación mensual actual de 20 Bs. o más a través del call center habilitado por UNICEF (<a href="tel:800109333">800 10 93 33</a>) de forma automática accediendo gracias a este incremento a una oportunidad adicional
                        (sumando 2 oportunidades en total).
                    </p>
                </section>

                <section>
                    <h2>Participación en el sorteo para donantes mensuales recurrentes NUEVOS de UNICEF</h2>
                    <p>
                        Podrán participar los donantes NUEVOS que acepten realizar una donación mensual a través de los
                        siguientes canales de captación habilitados por UNICEF:
                        <ul>
                            <li>Mediante un formulario físico debidamente firmado y aceptado por la institución
                                financiera por un monto mínimo de 60 bolivianos mensuales a través de una cuenta
                                bancaria.
                            </li>
                            <li>Mediante una donación mensual efectiva de un monto mínimo de 60 Bs. por la página web <a href="https://www.unicef.org.bo" target="_blank">www.unicef.org.bo</a> mediante una tarjeta de débito o crédito.
                            </li>
                        </ul>
                        <strong>Nota:</strong> Una vez recibido el premio, el ganador se compromete a mantener activa su
                        donación mensual por al menos 12 meses consecutivos.
                    </p>
                </section>

                <section>
                    <h2>Sobre el sorteo</h2>
                    <p>
                        <ul>
                            <li>EL sorteo de los premios se realizará mediante tómbola digital, en los horarios de la transmisión al vivo de la TELETÓN “Tiempo de Actuar”, del día 11 de agosto de 2024, evento que será realizado en el estudio 5 de la RED UNO, ubicado en Av. Piraí 6to anillo de la ciudad de Santa Cruz.</li>
                            <li>Será transmitido en vivo por la señal nacional de RED UNO, y simultáneamente en sus Redes Sociales y las de UNICEF.</li>
                            <li>El sorteo se realizará en presencia de Notario de fe pública entre todos los participantes/donantes MENSUALES recurrentes de acuerdo con lo descrito anteriormente.</li>
                            <li>El sorteo se realizará al azar, con todos los datos de las personas/donantes que hayan cumplido con los requisitos descritos para participar de la presente actividad. La modalidad de selección al azar define que el donante ganador será el tercero en obtenerse de manera aleatoria. El primer número será descartado (enviado al agua), el segundo número recibirá otro premio donado por otro auspiciador y el tercero el premio principal donado por ROHO.</li>
                            <li>Para no revelar en la totalidad información personal del ganador, el numero revelado durante el sorteo será el de su carnet de identidad.</li>
                            <li>Se comunicará el resultado en vivo y se realizará la llamada correspondiente al ganador para efectos de transparencia del sorteo. De no encontrarse al ganador se intentará el contacto durante los siguientes 5 días de realizado el sorteo. Si el ganador no contestara o no se apersonará a recoger el premio se aplicará lo estipulado en el punto 4 (SOBRE LOS PREMIOS Y LA ENTREGA).</li>
                            <li>La fecha del sorteo podrá ser modificada por los organizadores en caso de fuerza mayor y comunicada al público con una antelación de al menos 5.</li>
                        </ul>
                    </p>
                </section>

                <section>
                    <h2>Sobre los premios y la entrega</h2>
                    <p>
                        <strong>Premios Secundarios:</strong>
                        <ul>
                            <li>Consiste en dos pasajes aéreos ida y vuelta a Miami donados por la empresa Boliviana de
                                Aviación BOA.
                            </li>
                            <li>Tres (3) maletas nuevas donadas por la empresa Totto con las siguientes características:
                                <ul>
                                    <li>Marca Totto Travel Sentry (sistema de seguridad)</li>
                                    <li>Color maletas: blancas</li>
                                    <li>Sistema de ruedas 360</li>
                                    <li>Comprensión interna</li>
                                    <li>Manija telescópica</li>
                                    <li>Sistema de doble rueda</li>
                                </ul>
                            </li>
                        </ul>
                        <strong>Premio Principal:</strong>
                        <p>Consiste en renovar el hogar del ganador con los siguientes insumos donados por la empresa
                            ROHO:</p>
                        <ul>
                            <li>COCINA 4H VIDRIO TEMPLADO C/GRILL NEGRA (1)</li>
                            <li>REFRIG 250L SEMI SECO INOX BEKO (1)</li>
                            <li>LAVADORA 9.5KG CARGA SUPERIOR BLANCA ELECTROLUX (1)</li>
                            <li>LICUADORA 3L 1200W 5VEL JARRA PLASTICA BLANCA PHILIPS (1)</li>
                            <li>MICROONDAS 23L 1150W NEGRO SAMSUNG (1)</li>
                            <li>PLANCHA A VAPOR 2000W SUELA ANTIADHERENTE CELESTE PHILIPS (1)</li>
                            <li>SANDWICHERA GRILL 1200W ANTIADHERENTE BLANCA DICTROLUX (1)</li>
                            <li>CAFETERA DE GOTEO 0.6L 600W NEGRA ELECTROLUX (1)</li>
                            <li>BATIDORA DE MANO 300W 6VEL DICTROLUX (1)</li>
                            <li>VENTILADOR DE MESA 3EN1 60W 40CM 5ASPAS DICTROLUX (3)</li>
                            <li>TV 50 SMART LED UHD GOOGLE TV 50P735 TCL (1)</li>
                            <li>BATERÍA DE COCINA TEFLON AZUL 9PCS TRAMONTINA (1)</li>
                            <li>JUEGO CUBIERTOS 24PZAS ACERO INOX BELLAGIO BRINOX (1)</li>
                            <li>SET UTENSILIOS UTILITY TRAMONTINA (1)</li>
                            <li>JUEGO DE VAJILLA CERÁMICA PLISAN BLANCO 16PCS ALLEANZA (1)</li>
                            <li>COMBO COLCHON SEMIPEDIC+SOMIER 2 PLZ 140X190CM HEAVEN (1)</li>
                            <li>JGO SABANAS 2 PLAZAS BLANCO HOME BEAUTY (1)</li>
                            <li>CUBRECAMA C/FUNDA ULTRASONIC FLORAL BLANCO 2 PLZ HB (1)</li>
                            <li>ALMOHADA BASICA MICRO ALGODON 500G 50X70CM CAMESA (4)</li>
                            <li>CABECERA LUCIUS BURITI/FENDI 268/288/328X119X46CM (1)</li>
                            <li>SOFA NEYVA 2P GRIS OSCURO P.G 165X75X90CM (1)</li>
                            <li>SOFA NEYVA 3P GRIS OSCURO P.G 232X75X90CM (1)</li>
                            <li>MESA DE CENTRO STEEL VERMONT 35X100X60CM (1)</li>
                            <li>RACK DE TV AEGON 70" HAVANA/FENDI 180X40X74CM (1)</li>
                            <li>MESA LUCERNA CAFÉ OSCURO 200X100X75CM (1)</li>
                            <li>SILLA VALENCIA K. PLATA 50X50X95CM (6)</li>
                        </ul>
                    </p>
                </section>

                <section>
                    <h2>Entrega:</h2>
                    <p>
                        <strong>Premios secundarios:</strong>
                        <p>
                        Para hacer efectiva la entrega de los pasajes aéreos UNICEF se pondrá en contacto con el ganador/a y recabará la información relacionada a los pasajeros que harán uso de dichos pasajes, así como la fecha de ida y retorno y todos aquellos datos requeridos para la emisión de los pasajes.
                            <br/>
                        Esto será comunicado a la empresa BOA quien emitirá los pasajes. Todos los gastos relacionados a impuestos, gastos de aeropuerto o cambios de fecha deberán ser cubiertos por el ganador/a.
                            <br/>
                        Antes de emitir el pasaje, el ganador deberá cancelar los pagos e impuestos respectivos para que el pasaje pueda ser emitido. Esto será coordinado por UNICEF y BOA de manera oportuna.
                            <br/>
                        El o la ganadora de las maletas deberá recogerlas en el plazo máximo de tres (3) días contando a partir de la realización del sorteo de las oficinas de UNICEF ubicadas en el Cowork Hub 40, piso 9 de la Torre 2 del Edificio Manzano 40.
                        </p>
                        <br/>
                        <strong>Premio principal:</strong>
                        <p>
                            El premio será entregado en el transcurso de los tres siguientes cinco días de realizado el sorteo previa coordinación con el ganador, entre tanto permanecerán en custodia de ROHO. El costo del traslado y recojo del premio deberá ser asumido por el ganador quien deberá apersonarse con sus medios para el respectivo recojo.
                            <br/>
                            Si el ganador/a del premio principal, no contara con domicilio legal en la ciudad de Santa Cruz, el premio será enviado por flota hasta su ciudad de residencia. UNICEF coordinará él envió, sin embargo, los costos deberán ser cubiertos por el ganador/a.
                            <br/>
                            La entrega de todos los premios quedará registrada y se hará una publicación mediante redes sociales.
                            <br/>
                            En caso de que los premios no se cobren o retiren oportunamente por razones ajenas al ORGANIZADOR se efectuarán las siguientes gestiones:
                            <ul>
                                <li>Los pasajes aéreos deben utilizarse en el periodo de 12 meses calendario contando a partir del día del sorteo, 11 de agosto de 2024 hasta el 10 de agosto de 2025. Pasado este tiempo el premio vence y no podrá cobrarse.</li>
                                <li>En caso de no recibir respuesta del ganador de “renueva tu departamento”, o aun habiendo recibido respuesta, sin que el ganador se haya apersonado por la empresa a recoger el premio dentro de los cinco días hábiles siguientes al día en que haya concluido el sorteo, UNICEF procederá a publicar mediante redes sociales el nombre del ganador con su nombre y su cédula de identidad. La publicación que será emitida por espacio de 2 semanas o hasta que el ganador se apersone por la empresa a recabar su premio (lo que ocurra primero).</li>
                                <li>Tras ese plazo, ante la inconcurrencia de la(s) persona(s) ganadora(s), UNICEF y ROHO realizarán un nuevo sorteo, también mediante tómbola digital, ante notario de fe pública y ante personal de ROHO y UNICEF y se entregará hasta 5 días después de realizar este último sorteo. En este caso se tomará como ganador al primer número que salga de la tómbola digital. A fines de asegurar la transparencia del sorteo se publicará la entrega de este mediante redes sociales.</li>
                            </ul>
                        </p>
                    </p>
                </section>

                <section>
                    <h2>Sobre el ganador</h2>
                    <p>
                        El ganador de los premios deberá presentar documento de identidad original vigente (cédula de
                        identidad o pasaporte) al momento de cobrarlos también deberá cumplir con todos los requisitos y
                        políticas de esta actividad de beneficencia.
                        <br/>
                        Todos los premios deberán ser recogidos personalmente o a través de apoderado con poder
                        notariado.
                        <br/>
                        El ganador autoriza expresamente a UNICEF RED UNO y las empresas que donaron los premios (BOA y
                        ROHO) y a sus Agencias de Publicidad a difundir su nombre, videos, fotos e imágenes y/o voz en
                        medios masivos y en la forma que crean conveniente; sin derecho previo o posterior a
                        compensación alguna.
                    </p>
                    <p>
                        UNICEF se reserva el derecho de producir modificaciones en las presentes Bases y toda cuestión
                        no resuelta en las Bases será decidida por UNICEF siendo estas decisiones únicas e inapelables.
                        Toda situación de modificación se anunciará en el sitio web: <a href="https://www.unicef.org.bo" target="_blank">www.unicef.org.bo</a>
                    </p>
                </section>
                <section>
                    <h1>Es tiempo de actuar #PorUnaInfanciaFeliz</h1>
                </section>
            </div>


        </>
    );
}

BasesConcurso.propTypes = {};

export default BasesConcurso;
