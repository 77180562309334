import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//import BrandSlider from "./../components/sliders/Brand";

import {useNavigate, useParams} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {styled, useTheme} from '@mui/material/styles';
import Button from '@mui/material/Button';
import {TextField} from "@mui/material";

var wH = window.innerHeight

const cTextField = styled(TextField)(({theme}) => ({


    height: 65

}));


const ColorButton = styled(Button)(({theme}) => ({
    color: theme.palette.getContrastText("#000"),
    fontSize: 20,
    fontWeight: "700",
    padding: 1,
    height: 65,
    borderRadius: 3,
    /*
    fontFamily: [

        'sans-serif',
      ].join(','),*/
    width: "100%",
    backgroundColor: "#f8632c",
    '&:hover': {
        backgroundColor: "#f8632c",
    },
}));


const Home = (props) => {

    let {token} = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showAmount, setShowAmount] = useState(false);
    const [error, setError] = useState("");

    const [amount, setAmount] = useState(120);


    const bodyInfo1 = [


        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",

                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",

                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/Imagen-Web-Bebe-_final-sin.png",
                    "imagestyle": {height: wH}
                }
            ]
        },


    ]


    const bodyInfo1Mobile = [


        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",

                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "#donation-form",
                    "image": "",
                    "type": "text",

                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/Imegen-Web.png"
                }
            ]
        },


    ]


    const handleChange = (event) => {

        if (event.target.value === "") {
            setAmount(event.target.value)
        } else {

            if (/^[0-9]+$/.test(event.target.value)) {
                //console.log("mal")
                setError("")
                setAmount(event.target.value)
                if (event.key === 'Enter') {
                    console.log("vaaa")
                    donate(event)
                }
            }
        }


    };

    const donate = (event) => {
        console.log("donate")
        if (parseInt(amount) >= 60) {
            navigate("/donar/" + amount);
        } else {
            setError("El monto debe ser mayor a Bs 60")
        }
    }


    useEffect(() => {
        console.log(wH)

    }, [])

    const {open, onClose} = props;
    const dispatch = useDispatch();

    const {defConfig, params, defLang, activeForm} = useSelector((state) => state.Login);

    const {
        componentConfig,
        processSuccess,
        processError,
        requesting,
        processMessage,
        formFields,
        initialValues,
        validationSchema
    } = useSelector(
        (state) => state.Process
    );

    const deleteItem = (event) => {
        console.log("delete")
    };

    return (
        <>
            {/*
		<Dialog open={true} onClose={onClose} maxWidth="xs">
			<Container>
				<Grid container sx={{ mt: 1, mb: 2 }} spacing={1} >
				<Grid item lg={12} xs={12}>
					<Alert severity="success" variant="outlined" style={{boderColor: "#2ea033"}}>
						<AlertTitle color="success" variant="h6" style={{color: "#2ea033"}} > Comprobante </AlertTitle>
						<Typography
						variant="h6"
						style={{
						fontWeight: "bold",

						fontSize:24,
						marginTop: 20,
						marginBottom: 20,
						color: "#2ea033"
						}}>
						 <a style={{color: "#2ea033", fontSize:24 }} href={ process.env.REACT_APP_API_CONFIG+"/public/orders/getPdfOrder/"+token }>	Descargar Comprobante </a>
						</Typography>

					</Alert>
				</Grid>
				</Grid>
			</Container>
			</Dialog>
			*/}

            <div style={{minHeight: wH}}>
                {/*  <IntroPopup />*/}
                <iframe height={wH} width="100%"
                        src={process.env.REACT_APP_API_CONFIG + "/public/orders/getPdfOrder/" + token}></iframe>

                {/*fullScreen ?
				   <section className="home-slider position-relative ">
					   <CardContainer key={"card1"} data={bodyInfo1Mobile} config={{}}/>
				   </section>

			   :
				<>
                	<section className="home-slider position-relative">
                		<CardContainer key={"card1"} data={bodyInfo1} config={{}}/>
                	</section>

				</>*/
                }


            </div>
        </>
    );
}

Home.propTypes = {};

export default Home;
