/* eslint-disable jsx-a11y/alt-text */

import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { getImagePath } from "../../utils/Functions";

const CardContainer = ({ data, config, goHome, action }) => {
  return !data
    ? ""
    : data.length > 0 && (
        <section
          style={config.style ? config.style : {}}
          className={
            "featured section-padding position-relative " +
            (config.middle ? " flex-middle" : "") +
            (config.center ? " flex-center" : "")
          }
        >
          <div className="full-container my-0">
            {data.map((item, i) => {
              var style_item_body = {};

              item.childs.map((check) => {
                if (check.button !== "" && check.link) {
                  style_item_body = { minHeight: 83 };
                } else {
                }
              });

              return (
                <div
                  key={"k1" + i}
                  className={
                    (item.full ? item.full === "true" && "full-" : "") +
                    "container"
                  }
                >
                  <div key={"carddiv" + i} className="row">
                    {item.childs.map((item2, key2) => {
                      if (item2.updown.indexOf("JUST") >= 0) {
                        return (
                          <div
                            key={"bs1-2" + i + key2}
                            style={item2.style ? item2.style : {}}
                            className={
                              (item2.size
                                ? item2.size
                                : " col-lg-" + 12 / item.childs.length) +
                              " col-md-12 col-sm-12 col-12 "
                            }
                          >
                            <>
                              {item2.updown === "JUSTBUTTON" &&
                                item2.button && (
                                  <Link
                                    className={"item-link big-button"}
                                    key={"bs3-3" + i + key2}
                                    to={item2.link ? item2.link : "#"}
                                  >
                                    {item2.button}
                                  </Link>
                                )}
                              {item2.updown === "JUSTSUBTITLE" &&
                                item2.subtitle && (
                                  <div key={"bs7-2" + i + key2}>
                                    <span
                                      style={
                                        item2.subtitlestyle
                                          ? item2.subtitlestyle
                                          : {}
                                      }
                                      key={"bs8-2" + i + key2}
                                    >
                                      {item2.subtitle}
                                    </span>
                                  </div>
                                )}
                              {item2.updown === "JUSTTITLE" && item2.title && (
                                <div key={"bs7-2" + i + key2}>
                                  <span
                                    style={
                                      item2.titlestyle ? item2.titlestyle : {}
                                    }
                                    className="text-600"
                                    key={"bs8-2" + i + key2}
                                  >
                                    {item2.title}
                                  </span>
                                </div>
                              )}

                              {item2.updown === "JUSTBODY" && item2.body && (
                                <>
                                  {item2.bodystyleCont ? (
                                    <div
                                      key={"JUSTBODY8" + i + key2}
                                      style={item2.bodystyleCont}
                                    >
                                      <p
                                        style={
                                          item2.bodystyle ? item2.bodystyle : {}
                                        }
                                        key={"bs8-2" + i + key2}
                                      >
                                        {item2.body}
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        width: item2.cwidth
                                          ? item2.cwidth
                                          : "auto",
                                      }}
                                    >
                                      {item2.includequot ? (
                                        <>
                                          <img
                                            key={"bs6q2" + i}
                                            style={{
                                              height: 60,
                                              width: 85,
                                              position: "relative",
                                              left: -18,
                                              top: -11,
                                            }}
                                            src={getImagePath(
                                              process.env.REACT_APP_URL_CONFIG +
                                                "/assets/imgs/quot1.png"
                                            )}
                                          />
                                          <p
                                            style={
                                              item2.bodystyle
                                                ? item2.bodystyle
                                                : {}
                                            }
                                            key={"bs8-2" + i + key2}
                                          >
                                            {item2.body}
                                          </p>
                                          <img
                                            key={"bs6q2" + i}
                                            style={{
                                              height: 60,
                                              width: 85,
                                              position: "relative",
                                              float: "right",
                                              right: -10,
                                            }}
                                            src={getImagePath(
                                              process.env.REACT_APP_URL_CONFIG +
                                                "/assets/imgs/quot2.png"
                                            )}
                                          />
                                        </>
                                      ) : (
                                        <p
                                          style={
                                            item2.bodystyle
                                              ? item2.bodystyle
                                              : {}
                                          }
                                          key={"bs8-2" + i + key2}
                                        >
                                          {item2.body}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}

                              {item2.updown === "JUSTIMAGE" &&
                                item2.imagepath !== "" && (
                                  <>
                                    <img
                                      key={"bs6" + i}
                                      id={"IMG_" + item2.id}
                                      style={
                                        item2.imagestyle ? item2.imagestyle : {}
                                      }
                                      src={getImagePath(item2.imagepath)}
                                      onClick={() => {
                                        if (action) {
                                          action();
                                        }
                                      }}
                                      alt={item2.title}
                                    />

                                    {item2.button !== "" &&
                                      item2.buttonlink && (
                                        <div
                                          style={
                                            item2.buttonstyle
                                              ? item2.buttonstyle
                                              : {}
                                          }
                                          className={"mt-1 item-link"}
                                          key={"bs3-2" + i + key2}
                                          to={item2.link ? item2.link : "#"}
                                        >
                                          {item2.button}
                                        </div>
                                      )}
                                  </>
                                )}

                              {item2.updown === "JUSTVIDEO" &&
                                item2.imagepath !== "" && (
                                  <div
                                    key={"bs2-2" + i + key2}
                                    className="card-item-body"
                                  >
                                    <video
                                      style={{
                                        width: "100%",
                                        "max-height": "100%",
                                      }}
                                      autoplay={false}
                                      controls="true"
                                      preload="auto"
                                    >
                                      <source
                                        src={item2.imagepath}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                )}

                              {item2.updown === "JUSTYOUTUBE" &&
                                item2.imagepath !== "" && (
                                  <div
                                    key={"bs2-2" + i + key2}
                                    className="card-item-body text-center"
                                  >
                                    <iframe
                                      width={item2.width}
                                      height={item2.height}
                                      src={item2.imagepath}
                                      title="Unicef"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      allowfullscreen
                                    ></iframe>
                                  </div>
                                )}
                            </>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={"bs1-a_" + i + key2}
                            className={
                              item2.size
                                ? item2.size
                                : " col-lg-" +
                                  12 /
                                    (item.childs.length > 5
                                      ? 3
                                      : item.childs.length) +
                                  " col-md-6 col-sm-12 col-12"
                            }
                          >
                            <div
                              key={"bs2" + i + key2}
                              className="fadeIn card-item"
                            >
                              {item2.updown === "IMAGEUP" &&
                                item2.imagepath !== "" && (
                                  <>
                                    {item2.link ? (
                                      <Link
                                        key={"bs3" + i + key2}
                                        to={item2.link ? item2.link : "#"}
                                      >
                                        <div key={"bs5" + i + key2}>
                                          <img
                                            key={"bs6" + i + key2}
                                            style={{ borderRadius: 12 }}
                                            src={getImagePath(item2.imagepath)}
                                            alt={item2.title}
                                          />
                                        </div>
                                      </Link>
                                    ) : (
                                      <div key={"bs5" + i + key2}>
                                        <img
                                          key={"bs6" + i}
                                          style={{ borderRadius: 12 }}
                                          src={getImagePath(item2.imagepath)}
                                          alt={item2.title}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}

                              <div
                                style={style_item_body}
                                key={"bs2-2" + i + key2}
                                className="card-item-body"
                              >
                                {item2.title !== "" && (
                                  <div
                                    className="title"
                                    key={"bs7_" + i + key2}
                                  >
                                    <h2
                                      style={
                                        item2.titlestyle ? item2.titlestyle : {}
                                      }
                                      className="text-600"
                                      key={"bs8" + i + key2}
                                    >
                                      {item2.title}
                                    </h2>
                                  </div>
                                )}
                                {item2.subtitle !== "" && (
                                  <div
                                    className="subtitle"
                                    key={"bs7b" + i + key2}
                                  >
                                    <h3
                                      style={
                                        item2.subtitlestyle
                                          ? item2.subtitlestyle
                                          : {}
                                      }
                                      key={"bs8" + i + key2}
                                    >
                                      {item2.subtitle}
                                    </h3>
                                  </div>
                                )}
                                {item2.body !== "" && (
                                  <div
                                    style={
                                      item2.bodystyle ? item2.bodystyle : {}
                                    }
                                    className="item-body"
                                  >
                                    <p
                                      style={
                                        item2.bodystyle ? item2.bodystyle : {}
                                      }
                                      key={"bs8" + i + key2}
                                    >
                                      {item2.body}
                                    </p>
                                  </div>
                                )}
                                {item2.muted !== "" && (
                                  <div
                                    style={
                                      item2.mutedstyle ? item2.mutedstyle : {}
                                    }
                                    className="item-muted"
                                  >
                                    <p
                                      style={
                                        item2.mutedstyle ? item2.mutedstyle : {}
                                      }
                                      key={"bs9" + i + key2}
                                    >
                                      {item2.muted}
                                    </p>
                                  </div>
                                )}
                                {item2.button !== "" && item2.link && (
                                  <div
                                    onClick={goHome}
                                    style={
                                      item2.buttonstyle ? item2.buttonstyle : {}
                                    }
                                    className={"item-link button"}
                                    key={"bs3-2" + i + key2}
                                  >
                                    {item2.button}
                                  </div>
                                )}
                              </div>

                              {item2.updown === "IMAGEDOWN" &&
                                item2.imagepath !== "" && (
                                  <>
                                    {item2.link ? (
                                      <Link
                                        key={"bs3" + i + key2}
                                        to={item2.link ? item2.link : "#"}
                                      >
                                        <div key={"bs5" + i + key2}>
                                          <img
                                            key={"bs6" + i + key2}
                                            style={{
                                              borderRadius: 12,
                                            }}
                                            src={getImagePath(item2.imagepath)}
                                            alt={item2.title}
                                          />
                                        </div>
                                      </Link>
                                    ) : (
                                      <div key={"bs5" + i + key2}>
                                        <img
                                          key={"bs6" + i + key2}
                                          style={{ borderRadius: 12 }}
                                          src={getImagePath(item2.imagepath)}
                                          alt={item2.title}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      );
};

CardContainer.propTypes = {};

export default CardContainer;
