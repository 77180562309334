import React, {useEffect, useState} from "react";
//import Script from 'next/script'
import {useSelector} from "react-redux";

import GenericForm from "../../components/forms/GenericForm";
import {
    getCookie,
    mergeFormData,
    prepareForm,
    simplePostData,
    simplePutData
} from '../../utils/Functions';
//import ScriptTag from 'react-script-tag';
import Utils from "../../utils/Utils";
import {CircularProgress, Container, Grid, TextField, Typography} from "@mui/material";

import Button from '@mui/material/Button';
//import { useRouter } from 'next/router'
import {useNavigate, useParams} from 'react-router-dom';
import EndPoint from "../../api/Endpoints";

import useMediaQuery from '@mui/material/useMediaQuery';
import {styled, useTheme} from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import LoaderButton from "../../components/forms/LoaderButton";
import { format } from 'date-fns';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


var orderid = 0
var tryout = 0

var wH = window.innerHeight
var fieldType = "hidden"


const ColorButton = styled(Button)(({theme}) => ({
    color: theme.palette.getContrastText("#000"),
    fontSize: 24,
    fontWeight: "700",
    height: 65,
    borderRadius: 35,
    textTransform: "capitalize",
    width: "100%",
    backgroundColor: "#ffff00",
    '&:hover': {
        backgroundColor: "#f26a21",
    },
}));


const CheckOut = (props) => {
    const navigate = useNavigate();
    const {defConfig, defLang} = useSelector((state) => state.Login);
    const [personaldata, setPersonaldata] = React.useState({});
    const [paymentdata, setPaymentdata] = React.useState({});

    const [personaldataSucc, setPersonaldataSucc] = React.useState(false);
    const [personaldataSuccQR, setPersonaldataSuccQR] = React.useState(false);
    const [noButton, setNoButton] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const [showCyberFrame, setShowCyberFrame] = React.useState(false);
    const [paymentToken, setPaymentToken] = React.useState({});
    const [loading, setLoading] = useState(false);
    const [showAmount, setShowAmount] = useState(false);
    const [amount, setAmount] = useState(0);
    const [checkedP, setCheckedP] = useState(true);
    const [loadingQR, setLoadingQR] = useState(false);
    const [imageQr, setImageQr] = useState("");
    const [idQR, setIdQR] = useState("");
    const [isLoadingVerificar, setIsLoadingVerificar] = useState("");
    const [mensajeQR, setMensajeQR] = useState("");
    const [errorQR, setErrorQR] = useState(false);
    const [tmpOrder, setTmpOrder] = React.useState({});
    const [stepQRValues, setStepQRValues] = React.useState({});

    const handleChangeP = (event) => {
        //console.log("ch")
        setCheckedP(event.target.checked);
    };

    const [finalData, setFinalData] = useState({
        access_key: "1"
    });

    const [signature, setSignature] = useState("");
    const [error, setError] = useState("");

    var date = new Date();
    var defDate = date.setMonth(date.getMonth() - (12 * 18));
    const eventAddPaymentMethod = () => {
        //if (process.env.REACT_APP_URL_CONFIG === "https://www.unicef.org.bo") {
        window.fbq('track', 'AddPaymentInfo');
        window.gtag("event", "add_payment_info", {
            currency: "BOB",
            value: amount,
            payment_type: "Credit Card",
            items: [
                {
                    item_id: "SKU_DONACION",
                    item_name: "Donación de " + amount + "Bs",
                    price: amount,
                    quantity: 1
                }
            ]
        });
        //} else {
        //console.log("NO TAGS dev mode")
        //}
    }
    const confirmPersonalData = (values) => {
        if(checkedP) {

            if (personaldataSucc) {
                setPersonaldataSucc(true)
                setTimeout(() => {
                    setPersonaldataSucc(false)
                }, 1);

            } else {
                setPersonaldataSucc(true)
                eventAddPaymentMethod();
                let _formData = formData
                Object.assign(_formData, values);
                setFormData(_formData)

                let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source")
                let stepValues = {
                    amount, step: "personal-info",
                    utm_source: utm_source,
                    birthday: values.birthday,
                    city: values.city,
                    dni: values.dni,
                    email: values.email,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    phonenumber: values.phonenumber,
                    sessionid: tmpOrder
                }
                //step2
                simplePutData(EndPoint.setStep, stepValues);
            }
        }

    };

    const confirmPersonalDataQR = async (values) => {
        setStepQRValues(values);
        setError(false);
        if(checkedP) {
            setPersonaldataSuccQR(true)
            eventAddPaymentMethod();
            let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source")
            let utm_id = getCookie(process.env.REACT_APP_NAME + "utm_id")
            let utm_content = getCookie(process.env.REACT_APP_NAME + "utm_content")
            let utm_medium = getCookie(process.env.REACT_APP_NAME + "utm_medium")
            let utm_campaign = getCookie(process.env.REACT_APP_NAME + "utm_campaign")
            let utm_term = getCookie(process.env.REACT_APP_NAME + "utm_term")
            let stepValues = {
                amount, step: "personal-info",
                utm_source,
                birthday: values.birthday,
                city: values.city,
                dni: values.dni,
                email: values.email,
                firstname: values.firstname,
                lastname: values.lastname,
                phonenumber: values.phonenumber,
                sessionid: tmpOrder,
                phonecode: "+591",
                addressinfo: "",
                insert_user: "public",
                utm_id,
                utm_content,
                utm_medium,
                utm_campaign,
                utm_term,
            }
            console.log("----", stepValues)
            setLoadingQR(true)
            await simplePostData(EndPoint.getQR, stepValues).then((response) => {
                console.log(response);
                if(response.imagenQr) {
                    setImageQr(response.imagenQr)
                    setIdQR(response.idQr)
                    setLoadingQR(false);
                    setErrorQR(false);
                } else {
                    setLoadingQR(false);
                    setErrorQR(true)
                    console.log("No se Pudo Generar QR")
                }
            });
        }
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fullScreen2 = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);
    const [errMessage, setErrMessage] = React.useState("");

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const confirmPayment = async (values) => {
        let _formData = formData
        Object.assign(_formData, values);
        setFormData(_formData)
        setLoading(true)
        setError("")

        _formData["amount"] = amount

        if (_formData["birthday"] == "") {
            //	console.log("defDate",defDate)
            _formData["birthday"] = new Date(defDate)
        }
        setErrMessage("")
        let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source")
        let utm_id = getCookie(process.env.REACT_APP_NAME + "utm_id")
        let utm_content = getCookie(process.env.REACT_APP_NAME + "utm_content")
        let utm_medium = getCookie(process.env.REACT_APP_NAME + "utm_medium")
        let utm_campaign = getCookie(process.env.REACT_APP_NAME + "utm_campaign")
        let utm_term = getCookie(process.env.REACT_APP_NAME + "utm_term")

        _formData["utm_source"] = utm_source
        _formData["utm_id"] = utm_id
        _formData["utm_content"] = utm_content
        _formData["utm_medium"] = utm_medium
        _formData["utm_campaign"] = utm_campaign
        _formData["utm_term"] = utm_term


        _formData["cardnumber"] = _formData["cardnumber"].replaceAll(" ", "").replaceAll("-", "").replaceAll("_", "").trim()
        _formData["firstname"] = _formData["firstname"].replaceAll("-", " ").replaceAll("_", " ").trim()
        _formData["lastname"] = _formData["lastname"].replaceAll("-", " ").replaceAll("_", " ").trim()

        let stepValues = {
            amount,
            step: "checkout-order-step1",
            utm_source: utm_source,
            dni: _formData.dni,
            email: _formData.email,
            firstname: _formData.firstname,
            lastname: _formData.lastname,
            phonenumber: _formData.phonenumber,
            cardnumber: _formData.cardnumber,
            month: _formData.expiremonth,
            year: _formData.expireyear,
            cvv: _formData.cvv,
            sessionid: tmpOrder,
            utm_id,
            utm_content,
            utm_medium,
            utm_campaign,
            utm_term,
        }
        //step3
        simplePutData(EndPoint.setStep, stepValues);

        var response = await simplePutData(EndPoint.createOrderOneShot, _formData);

        if (response.status) {

            tryout = 0
            orderid = response.id
            var data = {
                ci: _formData.dni,
                phone: _formData.phonenumber,
                nit: _formData.dni,
                amount: amount,
                referenceNumber: response.id,
                email: _formData.email,
                card_name: _formData.firstname,
                card_lastname: _formData.lastname,
                card_number: _formData.cardnumber,
                month: _formData.expiremonth,
                year: _formData.expireyear,
                cvv: _formData.cvv,
                device_fingerprint_id: tmpOrder,
                transaction_type: "sale",
                isOneShot: true
            }
            response = await simplePostData(EndPoint.generateSignature, data);

            if (response.status) {
                setFinalData(response.data)
                setSignature(response.signature)

                setTimeout(async () => {

                    let stepValues = {
                        amount,
                        step: "checkout-cyber-step-2",
                        orderid: response.id,
                        utm_source: utm_source,
                        sessionid: tmpOrder
                    }
                    //step4
                    await simplePutData(EndPoint.setStep, stepValues);
                    document.getElementById("formCyber").submit();
                }, 200);
            } else {
                setLoading(false)
                setError("Hubo un problema por favor intentelo nuevamente")
            }
        } else {
            setLoading(false)
            if (response.userInfo) {
                setErrMessage(response.message)
                handleOpen(true)
            } else {
                if (response.message === "INVALID") {
                    setError("Revise los datos de Carnet de identidad, nombres y tel\u00E9fono")
                } else {
                    setError("Hubo un problema por favor intentelo nuevamente")
                }
            }

        }
    };

    const handleChange = (event) => {


        if (event.target.value === "") {
            setAmount(event.target.value)
        } else {

            if (/^[0-9]+$/.test(event.target.value)) {

                setAmount(event.target.value)
            }
        }

    };

    const callbackHandleChange = (field, value, postAction) => {
        if (field == "paymenttypecode") {
            if (value == "qr") {
                setNoButton(true)
            } else {
                setNoButton(false)
            }
        }
    };


    const loadData = async (field, value, postAction) => {

        let formpersonaldata
        let formpaymentdata
        formpersonaldata = Utils.datos_personales
        formpaymentdata = Utils.datos_pago

        let initialFormData = {
            amount: 0,
            shippingcost: 0,
            discount: 0,
            customerid: -1,
            phonecode: '+591',
            addressinfo: "",
            insert_user: "guest",
            cityid: 0,
            products: [],
        }
        setFormData(initialFormData)


        let userInfo = {
            //birthday: defDate
        }

        if (process.env.REACT_APP_dev_ftq == "YES") {

            userInfo = {

                firstname: "Franz",
                lastname: "Torrez",
                phonenumber: "62551000",
                email: "franz.torrez@bolcore.com",
                invoicename: "yam",
                invoicenumber: "22222",
                dni: "5958217",
                cardnumber: "4111111111111111",
                expiremonth: "12",
                expireyear: "2024",
                //cvv: "123",
                //birthday: defDate
            }
        }

        if (formpersonaldata) {
            const loadForm = async () => {


                let formData = await prepareForm(formpersonaldata)
                //preloadForm(formData)


                formData = mergeFormData(formData, userInfo)
                let initialValues = {}
                formData.map(function (key, item) {
                    initialValues[key.name] = key.value
                });


                setPersonaldata({data: formData, formValues: initialValues})
            };
            loadForm();
        }

        if (formpaymentdata) {

            const loadForm2 = async () => {
                let formData = await prepareForm(formpaymentdata)

                formData = mergeFormData(formData, userInfo)

                let initialValues = {}
                formData.map(function (key, item) {
                    initialValues[key.name] = key.value
                });
                setPaymentdata({data: formData, formValues: initialValues})
            };

            loadForm2();
        }
    };


    //router.push('/about')
    let oldAmount = 0;
    let {newamount} = useParams();
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100);
        if (amount && amount > 0 && amount !== oldAmount) {

            oldAmount = amount;
            console.log("------Activado------")
            console.log(amount)
            //if (process.env.REACT_APP_URL_CONFIG === "https://www.unicef.org.bo") {
                window.fbq('track', 'InitiateCheckout');
                window.gtag("event", "generate_lead", {
                    currency: "BOB",
                    value: amount
                });
            //} else {
            //    console.log("NO TAGS dev mode", amount, oldAmount)
            //}

        }
    }, [amount])

    useEffect(() => {

        let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source")
        //step1
        let stepValues = {amount: parseInt(amount), step: "init-donation", utm_source: utm_source}
        simplePutData(EndPoint.setStep, stepValues);
        if (newamount) {

            if (isNaN(newamount)) {
                navigate("/");
            } else {
                if (newamount < 60) {
                    navigate("/");
                }
            }
            setAmount(newamount)
        }

        loadData()

        var _tmpOrder = getCookie(process.env.REACT_APP_NAME + "_DFP_session_id")
        if (!_tmpOrder) {

            try {
                window.reload();
            } catch (exc) {

            }

        }
        setTmpOrder(_tmpOrder)
    }, []);


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="dialog-title" onClose={handleClose}>
                    ATENCI&Oacute;N
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Estimado cliente:
                    </Typography>
                    <Typography gutterBottom>
                        {errMessage}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Entendido
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <section className="mt-10 mb-10">
                <div className="container">
                    <div className="row flex-center">
                        <div className="col-md-12 col-sm-12 col-lg-10 col-xl-8 mt-2">
                            <div style={{minHeight: wH - 150}} className="order_review">
                                <Typography sx={{ml: {xs: 0, lg: 4}}} mt={3} mb={1} style={{fontSize: "1rem"}}>
                                    HAR&Aacute;S UNA DONACI&Oacute;N DE: {newamount ? <span
                                    style={{color: "#009ce0", fontWeight: "bold"}}>{"Bs " + newamount}</span> : ""}
                                </Typography>


                                {showCyberFrame ?
                                    <iframe width={370}
                                            height={650}
                                            style={{"borderStyle": "none"}}
                                            src={process.env.REACT_APP_API_CONFIG + "/public/payments/getPaymentCyber/" + paymentToken}>
                                    </iframe>
                                    :
                                    <>

                                        {!newamount &&
                                            <Container>
                                                <Grid container spacing={1} mt={4} mb={6}>
                                                    <Grid item lg={3} xs={6}>
                                                        <ColorButton style={{
                                                            textTransform: "capitalize",
                                                            backgroundColor: amount === 120 ? "#f26a21" : "#ffff00"
                                                        }} onClick={() => {
                                                            setAmount(120);
                                                            setShowAmount(false)
                                                        }} variant="contained">Bs 120</ColorButton>
                                                    </Grid>

                                                    <Grid item lg={3} xs={6}>
                                                        <ColorButton style={{
                                                            textTransform: "capitalize",
                                                            backgroundColor: amount === 90 ? "#f26a21" : "#ffff00"
                                                        }} onClick={() => {
                                                            setAmount(90);
                                                            setShowAmount(false)
                                                        }} variant="contained">Bs 90</ColorButton>
                                                    </Grid>
                                                    <Grid item lg={3} xs={6}>
                                                        <ColorButton style={{
                                                            textTransform: "capitalize",
                                                            backgroundColor: amount === 60 ? "#f26a21" : "#ffff00"
                                                        }} onClick={() => {
                                                            setAmount(60);
                                                            setShowAmount(false)
                                                        }} variant="contained">Bs 60</ColorButton>
                                                    </Grid>
                                                    <Grid item lg={3} xs={6}>
                                                        <ColorButton style={{
                                                            textTransform: "capitalize",
                                                            backgroundColor: amount !== 120 && amount !== 90 && amount !== 60 ? "#f26a21" : "#ffff00"
                                                        }} onClick={() => {
                                                            setAmount(60);
                                                            setShowAmount(true)
                                                        }} variant="contained">Otro</ColorButton>
                                                    </Grid>
                                                    {showAmount &&
                                                        <Grid item lg={12} xs={12} mt={4}>
                                                            <TextField

                                                                fullWidth
                                                                label={"Monto a Donar (Expresado en Bolivianos)"}
                                                                value={amount}
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Container>
                                        }
                                        {personaldata.data?.length > 0 && !personaldataSuccQR &&
                                            <>
                                                {personaldataSucc ?
                                                    ""
                                                    : <GenericForm buttonClassName="btn-success" allDisabled={false}
                                                                 buttonLabel={"Pago Con Tarjeta"}
                                                                 buttonLabel2={"Pago con QR"}
                                                                 button2ClassName={"btn-success"}
                                                                 action={confirmPersonalData}
                                                                 action2={confirmPersonalDataQR}
                                                                 button2Position={"right"}
                                                                 buttonPosition={"left"}
                                                                 button2Padding={"0 5px 0 0px"}
                                                                 buttonPadding={"0 0px 0 5px"}
                                                                 buttonwidth={fullScreen2 ? "100%" : "initial"}
                                                                 buttonwidth2={fullScreen2 ? "100%" : "initial"}
                                                                 formData={personaldata}
                                                                 addAcceptTerms={true}
                                                                 checkedP={checkedP}
                                                                 handleChangeP={handleChangeP}
                                                                 componentName={"personalData"} id={1}/>

                                                }
                                            </>
                                        }

                                        {personaldataSucc && paymentdata.data?.length > 0 &&
                                            <>
                                                <GenericForm loading={loading}
                                                             callbackHandleChange={callbackHandleChange}
                                                             buttonLabel={noButton ? "NOBUTTON" : defConfig[defLang].paylabel}
                                                             buttonLoadingLabel={noButton ? "NOBUTTON" : defConfig[defLang].validatingpaymentlabel}
                                                             action={confirmPayment}
                                                             buttonLabel2={defConfig[defLang].backlabel}
                                                             action2={() => {
                                                                 setPersonaldataSucc(false)
                                                                 setPersonaldataSuccQR(false)
                                                             }}
                                                             buttonwidth={fullScreen2 ? "100%" : "initial"}
                                                             buttonwidth2={fullScreen2 ? "100%" : "initial"}
                                                             formData={paymentdata}
                                                             inheritedformData={formData}
                                                             componentName={"checkout"} id={1}/>

                                                <Grid container spacing={1} sx={{mt: 1}}>
                                                    <Grid xs={12} sx={{mt: 2, mb: 2}} container justifyContent="center">
                                                        <img className="payment-method"
                                                             src={process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/tarjetas-credito.png"}
                                                             alt={"payment-method"}/>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                        {personaldataSuccQR && (
                                            <div style={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                align: "center",
                                                textAlign: "center",
                                                paddingTop: "20px"}}>
                                                {loadingQR && (
                                                    <>
                                                        <div>
                                                            <CircularProgress color="secondary" />
                                                            <CircularProgress color="success" />
                                                            <CircularProgress color="inherit" />
                                                        </div>
                                                        <b>Estamos generando el c&oacute;digo QR por favor espere unos segundos.</b>
                                                    </>
                                                )}

                                                {!loadingQR && errorQR && (
                                                    <>
                                                        <b>Hubo un Error Generando el QR, Por Favor Intente Nuevamente.</b>
                                                        <div>
                                                            <LoaderButton
                                                                className={"btn-success"}
                                                                style={{paddingTop: "10px"}}
                                                                width={"300px"}
                                                                onClick={() => {
                                                                    confirmPersonalDataQR(stepQRValues);
                                                                }}
                                                                label={"Reintentar"}
                                                                loadingLabel={"Cargando"}
                                                            />
                                                        </div>
                                                    </>
                                                )}

                                                {!loadingQR && !errorQR && (
                                                    <>
                                                        <img src={`data:image/jpeg;base64,${imageQr}`} style={{maxWidth: "300px"}}/>
                                                        <p>QR v&aacute;lido hasta el d&iacute;a {format(date.setDate(date.getDate() + 1),'dd/MM/yyyy')}</p>
                                                        <div>
                                                            <LoaderButton
                                                                className={"btn-success"}
                                                                style={{paddingTop: "10px"}}
                                                                width={"300px"}
                                                                onClick={() => {
                                                                    let a = document.createElement("a"); //Create <a>
                                                                    a.href = "data:image/png;base64," + imageQr; //Image Base64 Goes here
                                                                    a.download = "DonacionQRUnicef.png"; //File name Here
                                                                    a.click();
                                                                }}
                                                                label={"Descargar"}
                                                                loadingLabel={"Cargando"}
                                                            />
                                                        </div>
                                                        <div style={{marginTop: "15px"}}>
                                                            <LoaderButton
                                                                className={"btn-success"}
                                                                style={{paddingTop: "10px"}}
                                                                loading={isLoadingVerificar}
                                                                width={"300px"}
                                                                onClick={async () => {
                                                                    try{
                                                                        setIsLoadingVerificar(true)
                                                                        let data = {
                                                                            idQr: idQR
                                                                        }
                                                                        let response = await simplePostData(EndPoint.verificarQR, data)
                                                                        console.log(response)
                                                                        let responseData = response.data.result[0];
                                                                        if(responseData.STATUSID ===4) {
                                                                            setMensajeQR("Pago en QR Recibido")
                                                                            let UTM_ID = responseData.UTM_ID
                                                                            let UTM_SOURCE = responseData.UTM_SOURCE
                                                                            let UTM_MEDIUM = responseData.UTM_MEDIUM
                                                                            let UTM_CAMPAIGN = responseData.UTM_CAMPAIGN
                                                                            let UTM_CONTENT = responseData.UTM_CONTENT

                                                                            var utmData = ""

                                                                            if (UTM_ID) {
                                                                                utmData += "&utm_id=" + UTM_ID
                                                                            }
                                                                            if (UTM_SOURCE) {
                                                                                utmData += "&utm_source=" + UTM_SOURCE
                                                                            }
                                                                            if (UTM_MEDIUM) {
                                                                                utmData += "&utm_medium=" + UTM_MEDIUM
                                                                            }
                                                                            if (UTM_CAMPAIGN) {
                                                                                utmData += "&utm_campaign=" + UTM_CAMPAIGN
                                                                            }
                                                                            if (UTM_CONTENT) {
                                                                                utmData += "&utm_content=" + UTM_CONTENT
                                                                            }


                                                                            window.location.href = process.env.REACT_APP_URL_CONFIG + '/donacion-unica-realizada?orderid=' + responseData.ORDERID + '&name=' + responseData.FIRSTNAME + '&email=' + responseData.EMAIL + '&amount=' + responseData.AMOUNT + utmData;

                                                                        } else {
                                                                            setMensajeQR("Pago en QR No Recibido")
                                                                        }
                                                                        setIsLoadingVerificar(false)
                                                                    } catch(e) {
                                                                        setIsLoadingVerificar(false)
                                                                        setMensajeQR("Hubo un Error")
                                                                    }
                                                                }}
                                                                label={"Verificar Pago"}
                                                                loadingLabel={"Cargando"}
                                                            />
                                                        </div>
                                                        <br/>
                                                        <b>{mensajeQR}</b>

                                                    </>
                                                )}
                                            </div>

                                        )}
                                        <span> {error} </span>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <form id="formCyber" action={process.env.REACT_APP_cyberPostUrl} method="post">

                <input type={fieldType} name="access_key" value={finalData.access_key}/>
                <input type={fieldType} name="profile_id" value={finalData.profile_id}/>
                <input type={fieldType} name="transaction_uuid" value={finalData.transaction_uuid}/>
                <input type={fieldType} name="signed_field_names" value={finalData.signed_field_names}/>
                <input type={fieldType} name="unsigned_field_names" value={finalData.unsigned_field_names}/>
                <input type={fieldType} name="signed_date_time" value={finalData.signed_date_time}/>
                <input type={fieldType} name="locale" value={finalData.locale}/>
                <input type={fieldType} name="merchant_defined_data1" value={finalData.merchant_defined_data1}/>
                <input type={fieldType} name="merchant_defined_data2" value={finalData.merchant_defined_data2}/>
                <input type={fieldType} name="merchant_defined_data3" value={finalData.merchant_defined_data3}/>
                <input type={fieldType} name="merchant_defined_data6" value={finalData.merchant_defined_data6}/>
                <input type={fieldType} name="merchant_defined_data7" value={finalData.merchant_defined_data7}/>
                <input type={fieldType} name="merchant_defined_data9" value={finalData.merchant_defined_data9}/>
                <input type={fieldType} name="merchant_defined_data10" value={finalData.merchant_defined_data10}/>
                <input type={fieldType} name="merchant_defined_data11" value={finalData.merchant_defined_data11}/>
                <input type={fieldType} name="merchant_defined_data12" value={finalData.merchant_defined_data12}/>
                <input type={fieldType} name="merchant_defined_data14" value={finalData.merchant_defined_data14}/>
                <input type={fieldType} name="merchant_defined_data15" value={finalData.merchant_defined_data15}/>
                <input type={fieldType} name="merchant_defined_data17" value={finalData.merchant_defined_data17}/>
                <input type={fieldType} name="merchant_defined_data18" value={finalData.merchant_defined_data18}/>
                <input type={fieldType} name="merchant_defined_data19" value={finalData.merchant_defined_data19}/>
                <input type={fieldType} name="merchant_defined_data87" value={finalData.merchant_defined_data87}/>
                <input type={fieldType} name="merchant_defined_data88" value={finalData.merchant_defined_data88}/>
                <input type={fieldType} name="merchant_defined_data90" value={finalData.merchant_defined_data90}/>
                <input type={fieldType} name="merchant_defined_data91" value={finalData.merchant_defined_data91}/>
                <input type={fieldType} name="device_fingerprint_id" value={finalData.device_fingerprint_id}/>
                <input type={fieldType} name="transaction_type" value={"sale"}/>
                <input type={fieldType} name="reference_number" value={finalData.reference_number}/>
                <input type={fieldType} name="amount" id="amount" value={finalData.amount}/>
                <input type={fieldType} name="currency" id="currency" value={finalData.currency}/>
                <input type={fieldType} name="payment_method" value={finalData.payment_method}/>
                <input type={fieldType} name="bill_to_email" value={finalData.bill_to_email}/>
                <input type={fieldType} name="bill_to_phone" value={finalData.bill_to_phone}/>
                <input type={fieldType} name="bill_to_address_line1" value={finalData.bill_to_address_line1}/>
                <input type={fieldType} name="bill_to_address_city" value={finalData.bill_to_address_city}/>
                <input type={fieldType} name="bill_to_address_state" value={finalData.bill_to_address_state}/>
                <input type={fieldType} name="bill_to_address_country" value={finalData.bill_to_address_country}/>
                <input type={fieldType} name="bill_to_address_postal_code" value={finalData.bill_to_address_postal_code}/>
                <input type={fieldType} name="override_custom_receipt_page" value={finalData.override_custom_receipt_page}/>
                <input type={fieldType} name="override_backoffice_post_url" value={finalData.override_backoffice_post_url}/>
                <input type={fieldType} name="card_type" value={finalData.card_type}/>
                <input type={fieldType} name="bill_to_forename" value={finalData.bill_to_forename}/>
                <input type={fieldType} name="bill_to_surname" value={finalData.bill_to_surname}/>
                <input type={fieldType} name="card_number" value={formData.cardnumber}/>
                <input type={fieldType} name="card_expiry_date" value={formData.expiremonth + "-" + formData.expireyear}/>
                <input type={fieldType} name="card_cvn" value={formData.cvv}/>
                <input type={fieldType} name="signature" value={signature}/>
            </form>

        </>
    );
};

CheckOut.propTypes = {};

export default CheckOut;
