import React, {useEffect, useState} from "react";

import {useSearchParams} from 'react-router-dom';
import "./OneShot.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {getCookie, setCookie, simplePutData} from '../../utils/Functions';

//Images
import fondoDonacion from "../../assets/imgs/unicef/OneShot/fondoDonacion.png"
import EndPoint from "../../api/Endpoints";

import DonacionUnicaRealizadaContenido from './DonacionUnicaRealizadaContenido'

const DonacionUnicaRealizada = (props) => {




    useEffect(() => {
        //if (process.env.REACT_APP_URL_CONFIG === "https://www.unicef.org.bo") {
        window.fbq('track', 'Purchase', {value: searchParams.get("amount")?searchParams.get("name"):1.00, currency: 'BOB'});
        //Create Unique Identifier
        const date = new Date();
        // ✅ Get timestamp in Milliseconds
        const timestamp = date.getTime();
        console.log(timestamp); //  1650931200000
        window.gtag("event", "purchase", {
            transaction_id: timestamp,
            value: (searchParams.get("amount")?searchParams.get("name"):1.00),
            currency: "BOB",
            items: [{
                item_id: "SKU_DONACION_UNICA",
                item_name: "Donación Unica de X Bs",
                price: 1.00,
                quantity: 1
            }]
        });
        //} else {
        //    console.log("NO TAGS dev mode")
        //}

        var _tmpOrder = getCookie(process.env.REACT_APP_NAME + "_DFP_session_id")
        let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source")
        let stepValues = {
            step: "checkout-finish-OK",
            utm_source: utm_source,
            email: searchParams.get("email"),
            sessionid: _tmpOrder
        }
        simplePutData(EndPoint.setStep, stepValues);

        setTimeout(() => {
            window.scrollTo(0, 0)
            setCookie(process.env.REACT_APP_NAME + "utm_source", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_id", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_content", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_medium", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_campaign", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_term", "", 0.5)
        }, 100);
    }, []);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [error, setError] = useState("");
    const [searchParams] = useSearchParams();



    return (
        <>
            <div id="donation-form">
                <>
                    <section className="home-slider position-relative">
                        <img src={fondoDonacion} alt='image'/>
                    </section>
                    <DonacionUnicaRealizadaContenido
                    name={searchParams.get("name")}
                    email={searchParams.get("email")}/>
                </>

            </div>
        </>
    );
}

DonacionUnicaRealizada.propTypes = {};

export default DonacionUnicaRealizada;
