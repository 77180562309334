import React from "react";
import {Link, useLocation} from "react-router-dom";
import {getImagePath} from "../../utils/Functions";
import Utils from "../../utils/Utils";
import {useSelector} from "react-redux";

const Footer = (props) => {
    const location = useLocation();
    const {defConfig, params, defLang} = useSelector((state) => state.Login);
    return (Utils.excludeFooter.indexOf(location.pathname) >= 0 ? "" :
            <>
                <footer className="main" style={{background: defConfig.backgroundfooter}}>
                    {/*
                <section className="newsletter p-10 text-white wow fadeIn animated">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 mb-md-3 mb-lg-0">
                                <div className="row align-items-center">
                                    <div className="col flex-horizontal-center">

                                        <h4 style={{ color: defConfig.headercolor1 }} className="font-size-20 mb-0 ml-3">
                                        	<i  className= "fi-rs-envelope mr-5" ></i>
                                          Suscr&iacute;bete al Bolet&iacute;n de Noticias
                                        </h4>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-5">
                                <form className="form-subcriber d-flex wow fadeIn animated">
                                    <input
                                        type="email"
                                        className="form-control bg-white font-small"
                                        placeholder="Ingresa tu correo"
                                    />
                                    <button
                                        className="btn bg-dark text-white"
                                        type="submit"
                                    >
                                        Suscr&iacute;bete
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                */}
                    <section className="section-padding footer-mid ">
                        <div className="container pt-15 pb-20">
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <div className="widget-about font-md mb-md-5 mb-lg-0">
                                        <div className="logo footer-logo wow fadeIn animated">
                                            <Link href="/">
                                                <img
                                                    style={{
                                                        width: defConfig.imagefooterwidth,
                                                        minWidth: defConfig.imagefooterwidth
                                                    }}
                                                    src={getImagePath(defConfig.logoimagefooter)}
                                                    alt={"logo"}
                                                />

                                            </Link>
                                        </div>
                                        <h5 style={{color: defConfig.headercolor1}}
                                            className="my-10 fw-600 wow fadeIn animated">
                                            Oficina central Calle 20 No 7720,

                                        </h5>

                                        <h5 style={{color: defConfig.headercolor1}}
                                            className="my-10 fw-600  wow fadeIn animated">

                                            Calacoto La Paz , Bolivia.
                                        </h5>


                                        <a style={{
                                            color: "#eaeaea"
                                        }} href="http://www.unicef.org/bolivia/"
                                           target="_blank">www.unicef.org/bolivia</a>


                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <h5 style={{color: defConfig.headercolor1}}
                                        className="widget-title wow fadeIn animated">
                                        MENU
                                    </h5>
                                    <ul className="footer-list wow fadeIn animated mb-sm-5 mb-md-0">
                                        <li>
                                            <a href="/">INICIO</a>
                                        </li>

                                        <li>
                                            <a href="/nuestro-equipo">Nuestro Equipo</a>
                                        </li>
                                        <li>
                                            <a href="/aviso-de-privacidad">Pol&iacute;ticas de privacidad</a>
                                        </li>
                                        <li>
                                            <a href="/terminos-del-concurso">BASES Y CONDICIONES DEL SORTEO</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <h5 style={{color: defConfig.headercolor1}}
                                        className="widget-title wow fadeIn animated">
                                        CONTACTOS
                                    </h5>
                                    <ul className="footer-list wow fadeIn animated">
                                        <li>
                                            <a href="tel:800109333"> Línea Gratuita: 800 10 9333</a>
                                        </li>
                                        <li>
                                            <a href="https://api.whatsapp.com/send?phone=59171543277&amp;text=Quiero%20saber%20mas%20informaci%C3%B3n%20para%20donar&quot;">
                                                <strong>WhatsApp: </strong> +591 71543277
                                            </a>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </section>
                    <div className="container pb-10 wow fadeIn animated">
                        <div className="row">
                            <div className="col-12 mb-10">
                                <div className="footer-bottom"></div>
                            </div>
                            <div className="col-lg-12">
                                <p style={{color: defConfig.headercolor1}} className="float-md-left font-sm   mb-0">
                                    {/*
                                &copy; {new Date().getFullYear()},
                                <strong className="text-brand"> UNICEF</strong> - Portal de Donaciones
                                    */}
                                    Políticas de privacidad | 2023
                                </p>
                            </div>

                        </div>
                    </div>
                </footer>
            </>
    );
};
Footer.propTypes = {};

export default Footer;
