import React from "react";
import Button from "@mui/material/Button";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ButtonGroup from "@mui/material/ButtonGroup";
import SimpleSlider from "../components/sliders/SimpleSlider";
import CardContainer from "../components/elements/CardContainer";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slide from "@mui/material/Slide";

import { useEffect, useState } from "react";
import { Dialog, Grid, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { getCookie, setCookie } from "../utils/Functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#fff"),
  fontSize: 20,
  fontWeight: "700",
  padding: 1,
  height: 65,
  borderRadius: 3,
  /*
    fontFamily: [

        'sans-serif',
      ].join(','),*/
  width: "100%",
  backgroundColor: "#ffff00",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#000",
  },
}));

const Home = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showAmount, setShowAmount] = useState(false);
  const [error, setError] = useState("");

  const [amount, setAmount] = useState(120);
  const [searchParams] = useSearchParams();
  const customH = window.innerWidth + 80;
  const customW = window.innerWidth - 3;
  const [open, setOpen] = React.useState(false);
  const urlImageDesktop = process.env.REACT_APP_URL_CONFIG +
      "/assets/imgs/banner/2024-09-desktop.png";
  const urlImageMobile = process.env.REACT_APP_URL_CONFIG +
      "/assets/imgs/banner/2024-09-mobile.png";
  const urlImageDesktopTeleton =
    process.env.REACT_APP_URL_CONFIG +
    "/assets/imgs/unicef/teleton2024/bannerDesktop.png";
  const urlImageMobileTeleton =
    process.env.REACT_APP_URL_CONFIG +
    "/assets/imgs/unicef/banner/2024-09-mobile.png";
  const urlImageDesktopEmpresas =
    process.env.REACT_APP_URL_CONFIG +
    "/assets/imgs/unicef/teleton2024/bannerEmpresasDesktop.png";
  const urlImageMobileEmpresas =
    process.env.REACT_APP_URL_CONFIG +
    "/assets/imgs/unicef/teleton2024/bannerEmpresasDesktop.png";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [finalData, setFinalData] = useState({
    utm_source: searchParams.get("utm_source")
      ? searchParams.get("utm_source")
      : "false",
    utm_medium: searchParams.get("utm_medium")
      ? searchParams.get("utm_medium")
      : "false",
    utm_campaign: searchParams.get("utm_campaign")
      ? searchParams.get("utm_campaign")
      : "false",
    utm_id: searchParams.get("utm_id") ? searchParams.get("utm_id") : "false",
    utm_term: searchParams.get("utm_term")
      ? searchParams.get("utm_term")
      : "false",
    utm_content: searchParams.get("utm_content")
      ? searchParams.get("utm_content")
      : "false",
  });

  const [monto, setMonto] = useState("");

  const bodyInfo1 = [
    {
      id: 1,
      full: "true",
      childs: [
        {
          id: 0,
          size: "",
          title: "",

          updown: "JUSTIMAGE",
          body: "",
          button: "",
          buttonlink: "",

          link: "",
          image: "",
          type: "text",
          imagepath: urlImageDesktop,
        },
      ],
    },
  ];
  const bodyInfoTeleton1 = [
    {
      id: 1,
      full: "true",
      childs: [
        {
          id: 0,
          size: "",
          title: "",

          updown: "JUSTIMAGE",
          body: "",
          button: "",
          buttonlink: "",

          link: "",
          image: "",
          type: "text",
          imagepath: urlImageDesktopTeleton,
        },
      ],
    },
  ];
  const bodyInfoEmpresas1 = [
    {
      id: 1,
      full: "true",
      childs: [
        {
          id: 0,
          size: "",
          title: "",

          updown: "JUSTIMAGE",
          body: "",
          button: "",
          buttonlink: "",

          link: "",
          image: "",
          type: "text",
          imagepath: urlImageDesktopEmpresas,
        },
      ],
    },
  ];

  const bodyInfo1Mobile = [
    {
      id: 1,
      full: "true",
      childs: [
        {
          id: 0,
          size: "",
          title: "",
          updown: "JUSTIMAGE",
          body: "",
          button: "",
          buttonlink: "",
          buttonstyle: {},
          link: "#donation-form",
          image: "",
          type: "text",
          imagepath: urlImageMobile,
        },
      ],
    },
  ];
  const bodyInfoTeleton1Mobile = [
    {
      id: 1,
      full: "true",
      childs: [
        {
          id: 0,
          size: "",
          title: "",
          updown: "JUSTIMAGE",
          body: "",
          button: "",
          buttonlink: "",
          buttonstyle: {},
          link: "#donation-form",
          image: "",
          type: "text",
          imagepath: urlImageMobileTeleton,
        },
      ],
    },
  ];
  const bodyInfoEmpresas1Mobile = [
    {
      id: 1,
      full: "true",
      childs: [
        {
          id: 0,
          size: "",
          title: "",
          updown: "JUSTIMAGE",
          body: "",
          button: "",
          buttonlink: "",
          buttonstyle: {},
          link: "#donation-form",
          image: "",
          type: "text",
          imagepath: urlImageMobileEmpresas,
        },
      ],
    },
  ];

  const bodyInfo1B = [
    {
      id: 1,

      childs: [
        {
          id: 0,
          size: "",
          title: "TÚ PUEDES CAMBIAR ESTAS HISTORIAS",
          titlestyle: {
            color: "#fff",
            fontSize: "2.5rem",
            textAlign: "center",
            marginTop: 2,
          },
          buttonstyle: { float: "right", position: "relative", top: 14 },
          button: "",
          buttonlink: "",
          image: "",
          type: "text",
          imagepath: "",
          link: "",
          subtitle: "",
          updown: "JUSTTITLE",
          body: "",
        },
      ],
    },
  ];

  const bodyInfo1C = [
    {
      id: 1,
      childs: [
        {
          id: 0,
          size: "",
          title: "",
          titlestyle: { color: "#f26a21" },
          buttonstyle: { float: "right", position: "relative", top: 14 },
          button: "",
          buttonlink: "",
          image: "",
          type: "text",
          imagepath: "",
          link: "",
          subtitle: "",
          updown: "JUSTBODY",
          style: {
            display: "flex",
            alignItems: "center",
            textAlign: "justify",
            paddingRight: fullScreen ? 0 : 46,
            justifyContent: "end",
          },
          cwidth: fullScreen ? "100%" : "80%",
          bodystyle: {
            fontSize: "1.15rem",
          },
          includequot: true,
          body: (
            <>
              {" "}
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "1.75rem",

                  color: "#ffc20e",
                  background: "#fff",
                  borderRadius: 5,
                  padding: "1px 5px",
                  lineHeight: 1.5,
                }}
              >
                8 de cada 10{" "}
              </span>
              niñas niños o adolescentes, son víctimas de algún tipo violencia
              en el país. Por eso creamos la línea gratuita{" "}
              <span style={{ fontWeight: "700", color: "#ffc20e" }}>
                {" "}
                Familia Segura{" "}
              </span>
              , un servicio gratuito de apoyo psico-emocional para prevenir y
              atender la violencia.
            </>
          ),
        },

        {
          id: "IMAGE_VIDEO",

          size: "",
          title: "",

          button: "",
          buttonlink: "",
          image: "",
          type: "text",

          imagepath:
            process.env.REACT_APP_URL_CONFIG +
            "/assets/imgs/unicef/septiembre2023/banner/foto_video.png",
          imagestyle: { marginTop: 10, borderRadius: 10, cursor: "pointer" },
          link: "",
          subtitle: "",
          updown: "JUSTIMAGE", //JUSTVIDEO
          body: " ",
        },
      ],
    },
  ];

  /*const bodyInfo2 = [
    {
      id: 1,
      childs: [
        {
          id: "donaahora",
          size: "",
          title: "",
          buttonstyle: {
            textAlign: "center",
            position: "relative",
            backgroundColor: "#ffff00",
            color: "rgba(0, 0, 0, 0.87)",
          },
          style: { textAlign: "center" },
          button: "DONA AHORA",
          buttonlink: "#donation-form",
          image: "",
          type: "text",
          imagepath: "",
          link: "#donation-form",
          subtitle: "",
          updown: "IMAGEDOWN",
          body: "",
        },
      ],
    },
  ];*/

  const bodyInfo2B = [
    {
      id: 1,
      childs: [
        {
          id: 0,
          size: "",
          subtitle: "Tú tienes el poder de cambiar estas historias",
          subtitlestyle: {
            color: "#fff",
            textAlign: "center",
            fontSize: "2rem",
          },
          button: "",
          buttonlink: "",
          image: "",
          type: "text",
          imagepath: "",
          link: "",
          updown: "JUSTSUBTITLE",
          body: "",
        },
      ],
    },
  ];

  /*const bodyInfo3a = [
    {
      id: 1,
      childs: [
        {
          id: "donaahora",
          size: "",
          title: "",
          buttonstyle: {
            textAlign: "center",
            position: "relative",
            backgroundColor: "#ffff00",
            color: "rgba(0, 0, 0, 0.87)",
          },
          style: { textAlign: "center" },
          button: "DONA AHORA",
          buttonlink: "#donation-form",
          image: "",
          type: "text",
          imagepath: "",
          link: "#donation-form",
          subtitle: "",
          updown: "IMAGEDOWN",
          body: "",
        },
      ],
    },
  ];*/

  /*const bodyInfo3 = [
    {
      id: 1,
      childs: [
        {
          id: "donaahora",
          size: "",
          title: "",
          buttonstyle: {
            textAlign: "center",
            position: "relative",
            backgroundColor: "#ffff00",
            color: "rgba(0, 0, 0, 0.87)",
          },
          style: { textAlign: "center" },
          button: "DONA AHORA",
          buttonlink: "#donation-form",
          image: "",
          type: "text",
          imagepath: "",
          link: "#donation-form",
          subtitle: "",
          updown: "IMAGEDOWN",
          body: "",
        },
      ],
    },
  ];*/

  const bodyInfo3c = [
    {
      id: 1,
      childs: [
        {
          id: 0,
          size: "",
          subtitle: "Tú también puedes ser un Héroe o Heroína de la Niñez",
          subtitlestyle: {
            color: "#fff",
            textAlign: "center",
            fontSize: "2rem",
          },
          button: "",
          buttonlink: "",
          image: "",
          type: "text",
          imagepath: "",
          link: "",
          updown: "JUSTSUBTITLE",
          body: "",
        },
      ],
    },
  ];

  const slider1 = [
    {
      id: 1,
      childs: [
        {
          id: 0,
          size: "",
          title: "JULIO DIAZ JUSTINIANO",
          subtitle: "JULIO",
          subtitlestyle: { color: "rgb(255, 179, 0)", fontFamily: "Roboto" },
          body: "Mi esposa y yo somos “héroes”. Hace aproximadamente un año mi esposa perdió su trabajo y seis meses después yo perdí el mío. Lógicamente nuestra situación económica pasó a ser apremiante. A pesar de ello, nunca dejamos de aportar, porque nosotros creemos en el trabajo que se realiza gracias a nuestros aportes, porque estamos convencidos que los niños que reciben nuestra ayuda serán los héroes del mañana.",
          button: "",
          buttonlink: "",
          image: "",
          type: "multiple",
          imagepath:
            process.env.REACT_APP_URL_CONFIG +
            "/assets/imgs/unicef/septiembre2023/pagina/julio.jpeg",
          link: "",
          objectPosition: fullScreen ? "center" : "-10px -7px",
        },
      ],
    },
    {
      id: 2,
      childs: [
        {
          id: 0,
          size: "",
          title: "CARLOS CORREA CUELLAR",
          subtitlestyle: { color: "#e2231a", fontFamily: "Roboto" },
          subtitle: "CARLOS",
          body: "Cuando me ofrecieron la oportunidad de aportar con mi granito de arena para cambiar la realidad de tantos niños y niñas, no lo pensé dos veces, y cada día me parece una mejor decisión. Son tiempos difíciles, muchos estamos batallando, tratando de dar lo mejor a nuestras familias, pero no podemos ignorar la triste y dura situación que enfrentan muchos niños y niñas.",
          button: "",
          buttonlink: "",
          image: "",
          type: "multiple",
          imagepath:
            process.env.REACT_APP_URL_CONFIG +
            "/assets/imgs/unicef/septiembre2023/pagina/carlos.jpeg",
          objectPosition: fullScreen ? "center" : "0px -30px",
        },
      ],
    },
    {
      id: 3,
      childs: [
        {
          id: 0,
          size: "",
          title: "ROXANA RIVER CHAVEZ",
          subtitle: "ROXANA",
          subtitlestyle: { color: "#389b14", fontFamily: "Roboto" },
          body: "El inicio de la pandemia representó para mí una nueva etapa, encerrada en mi casa con todo; pero sintiéndome hasta culpable de estar en esa situación y ver que muchas personas se resistían a respetar el confinamiento, porque si no trabajaban, no comían. Todo esto me movió completamente y decidí que, dentro de mis posibilidades, o privándome de algunos gustos, debía y quería ayudar; y me llegó la posibilidad de incorporarme a UNICEF, y aunque estoy convencida que es poco, me siento muy feliz de formar parte y recibir fotos de niñas sonrientes.",
          button: "",
          buttonlink: "",
          image: "",
          type: "multiple",
          imagepath:
            process.env.REACT_APP_URL_CONFIG +
            "/assets/imgs/unicef/septiembre2023/pagina/roxana.jpeg",
          objectPosition: fullScreen ? "center" : "0px -440px",
        },
      ],
    },
    {
      id: 4,
      childs: [
        {
          id: 0,
          size: "",
          title: "NATHALIE PINAYA BARREDA",
          subtitle: "NATHALIE",
          subtitlestyle: { color: "#e68e9d", fontFamily: "Roboto" },
          body: "Fue el 24 de octubre de 2019, mientras caían lágrimas de mis ojos, cuando confirmé que ser HEROÍNA DE LA ÑINEZ, no solo se trata de dar un aporte a UNICEF, sino que es un compromiso con la niñez del país. Ese día recibí un video, Nayerli, una niña de 10 años de Montero que mostraba entusiasmada su nuevo baño, con inodoro y ducha, ella no tenía acceso a saneamiento básico, ya que antes tenía que ir a un hueco para hacer sus necesidades. Terminó el video con un agradecimiento a todos los héroes y dijo que tener un baño le daba salud y dignidad. Cerré los ojos y me pregunté ¿Qué haría yo sin tener acceso a un baño?.",
          button: "",
          buttonlink: "",
          image: "",
          type: "multiple",
          imagepath:
            process.env.REACT_APP_URL_CONFIG +
            "/assets/imgs/unicef/septiembre2023/pagina/nathalie.jpeg",
        },
      ],
    },
    {
      id: 5,
      childs: [
        {
          id: 0,
          size: "",
          title: "ROMINA HAYDEE DE LOS REYES ",
          subtitle: "ROMINA",
          subtitlestyle: { color: "#ff6c00", fontFamily: "Roboto" },
          body: "Me encontraba sentada en una banca, cuando de repente, un grito muy fuerte me distrajo. Este provenía de un niño que hizo el ademán de quitarme el celular. En eso, me di cuenta de que eran ¡cinco niños! ¿No tienes miedo a que te robemos? les dije q no, no tenía miedo. Tenían hambre y sed así que los lleve al kiosko para comprarles algo. eran “niños de la calle” que vivieron realidades muy duras y yo quería ayudar de alguna manera y tiempo después, decidí ser parte del equipo de UNICEF ya que descubrí que mediante ellos podría ayudar a cambiar las realidades de niños que viven en situaciones similares o peores a estos niños.",
          button: "",
          buttonlink: "",
          image: "",
          type: "multiple",
          imagepath:
            process.env.REACT_APP_URL_CONFIG +
            "/assets/imgs/unicef/septiembre2023/pagina/romina.jpeg",
          objectPosition: fullScreen ? "center" : "0px -420px",
        },
      ],
    },
  ];

  let slider2 = [
    {
      id: 11,
      childs: [
        {
          id: 0,
          size: "",
          title: "Ella es Julia",
          subtitle: "",
          customcol: true,
          body: "Tiene 7 años y juega a ser invisible para esconderse de su agresor.",
          button: "",
          buttonlink: "",
          image: "",
          type: "multiple",
          imagepath:
            process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/1.webp",
          imagestyle: {
            width: 390,
            borderRadius: "50%",
          },
          link: "",
        },
      ],
    },
    {
      id: 22,
      childs: [
        {
          id: 0,
          size: "",
          title: "El es Carlitos",
          subtitle: "",
          customcol: true,
          body: "No pudo volverá a clases porque tienen que trabajar, su familia necesita de su ayuda.",
          button: "",
          buttonlink: "",
          image: "",
          type: "multiple",
          imagestyle: {
            width: 390,
            borderRadius: "50%",
          },
          imagepath:
            process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/2.webp",
        },
      ],
    },
    {
      id: 33,
      childs: [
        {
          id: 0,
          size: "",
          title: "Ella es Anita",
          customcol: true,
          subtitlestyle: { color: "#e2231a", fontFamily: "Roboto" },
          subtitle: "",
          body: "Está en tratamiento psicoemocional debido a que fue víctima de abuso sexual.",
          button: "",
          buttonlink: "",
          image: "",
          type: "multiple",
          imagestyle: {
            width: 390,
            borderRadius: "50%",
          },
          imagepath:
            process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/3.webp",
        },
      ],
    },

    {
      id: 44,
      childs: [
        {
          id: 0,
          size: "",
          title: "Ella es Yeriani",
          subtitle: "",
          customcol: true,
          body: "Vive en cobija y con tu donación podrá tener agua potable y acceso a un baño sanitariamente seguro",
          button: "",
          buttonlink: "",
          image: "",
          type: "multiple",
          imagepath:
            process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/4.png",
          imagestyle: {
            width: 390,
            borderRadius: "50%",
          },
          link: "",
        },
      ],
    },
  ];

  const handleChange = (event) => {
    if (event.target.value === "") {
      setAmount(event.target.value);
    } else {
      if (/^[0-9]+$/.test(event.target.value)) {
        setError("");
        setAmount(event.target.value);
        if (event.key === "Enter") {
          donate(event);
        }
      }
    }
  };

  const goHome = (event) => {
    window.scrollTo(0, 0);
  };

  const donate = (event) => {
    if (parseInt(amount) >= 60) {
      if (process.env.REACT_APP_URL_CONFIG === "https://www.unicef.org.bo") {
        window.fbq("track", "AddToCart", {
          content_type: "product",
          value: amount,
          currency: "BOB",
        });
        window.gtag("event", "add_to_cart", {
          currency: "BOB",
          value: amount,
          items: [
            {
              item_id: "SKU_DONACION",
              item_name: "Donación de " + amount + "Bs",
              price: amount,
              quantity: 1,
            },
          ],
        });
      } else {
        console.log("NO TAGS dev mode");
      }

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      navigate("/donar/" + amount);
    } else {
      setError("El monto debe ser mayor a Bs 60");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    let utm_source = "";
    let utm_id = "";
    let utm_content = "";
    let utm_medium = "";
    let utm_campaign = "";
    let utm_term = "";
    let _finalData = finalData;

    if (searchParams.get("utm_source")) {
      utm_source = searchParams.get("utm_source");
      console.log("siutm_source", utm_source);
      _finalData["utm_source"] = utm_source;
    } else {
      utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source");
      if (
        !utm_source ||
        utm_source == "undefined" ||
        utm_source === undefined ||
        utm_source === null ||
        utm_source === "null"
      ) {
        utm_source = "";
      }
      _finalData["utm_source"] = utm_source;
    }

    if (searchParams.get("utm_term")) {
      _finalData["utm_term"] = utm_term;
      utm_term = searchParams.get("utm_term");
    } else {
      utm_term = getCookie(process.env.REACT_APP_NAME + "utm_term");
      if (
        !utm_term ||
        utm_term == "undefined" ||
        utm_term === undefined ||
        utm_term === null ||
        utm_term === "null"
      ) {
        utm_term = "";
      }
      utm_term = searchParams.get("utm_term");
    }

    if (searchParams.get("utm_id")) {
      utm_id = searchParams.get("utm_id");
      _finalData["utm_id"] = utm_id;
    } else {
      utm_id = getCookie(process.env.REACT_APP_NAME + "utm_id");
      if (
        !utm_id ||
        utm_id == "undefined" ||
        utm_id === undefined ||
        utm_id === null ||
        utm_id === "null"
      ) {
        utm_id = "";
      }
      _finalData["utm_id"] = utm_id;
    }

    if (searchParams.get("utm_content")) {
      utm_content = searchParams.get("utm_content");
      _finalData["utm_content"] = utm_content;
    } else {
      utm_content = getCookie(process.env.REACT_APP_NAME + "utm_content");
      if (
        !utm_content ||
        utm_content == "undefined" ||
        utm_content === undefined ||
        utm_content === null ||
        utm_content === "null"
      ) {
        utm_content = "";
      }
      _finalData["utm_content"] = utm_content;
    }

    if (searchParams.get("utm_medium")) {
      utm_medium = searchParams.get("utm_medium");
      _finalData["utm_medium"] = utm_medium;
    } else {
      utm_medium = getCookie(process.env.REACT_APP_NAME + "utm_medium");
      if (
        !utm_medium ||
        utm_medium == "undefined" ||
        utm_medium === undefined ||
        utm_medium === null ||
        utm_medium === "null"
      ) {
        utm_medium = "";
      }
      _finalData["utm_medium"] = utm_medium;
    }

    if (searchParams.get("utm_campaign")) {
      utm_campaign = searchParams.get("utm_campaign");
      _finalData["utm_campaign"] = utm_campaign;
    } else {
      utm_campaign = getCookie(process.env.REACT_APP_NAME + "utm_campaign");
      if (
        !utm_campaign ||
        utm_campaign == "undefined" ||
        utm_campaign === undefined ||
        utm_campaign === null ||
        utm_campaign === "null"
      ) {
        utm_campaign = "";
      }
      _finalData["utm_campaign"] = utm_campaign;
    }

    setTimeout(() => {
      setFinalData(_finalData);
    }, 100);

    setCookie(process.env.REACT_APP_NAME + "utm_source", utm_source, 0.5);
    setCookie(process.env.REACT_APP_NAME + "utm_id", utm_id, 0.5);
    setCookie(process.env.REACT_APP_NAME + "utm_content", utm_content, 0.5);
    setCookie(process.env.REACT_APP_NAME + "utm_medium", utm_medium, 0.5);
    setCookie(process.env.REACT_APP_NAME + "utm_campaign", utm_campaign, 0.5);
    setCookie(process.env.REACT_APP_NAME + "utm_term", utm_term, 0.5);
  }, []);

  const [isFooterVisible, setIsFooterVisible] = useState(false);

  const handleScroll = () => {
    const ele = document.querySelector("#mainActionButton");
    if (!ele) {
      return;
    }

    const { top } = ele.getBoundingClientRect();
    setIsFooterVisible(top <= 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div id="donation-form">
        {/*  <IntroPopup />*/}

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          maxWidth={fullScreen}
          onClose={handleClose}
          sx={{ margin: 0 }}
          className="custom-video"
          style={{ margin: 0 }}
          aria-describedby="alert-dialog-slide-description"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {open && (
              <iframe
                width={fullScreen ? customW : "600"}
                height="385"
                src="https://www.youtube.com/embed/GlNYzpUO6rw?si=gi1a-fV082uy6ZSf"
                title="Unicef"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            )}
          </div>
        </Dialog>

        {fullScreen ? (
          <>
            <section className="home-slider position-relative">
              <CardContainer key={"card1"} data={bodyInfo1Mobile} config={{}} />
            </section>
            <div className="donation-form" style={{ marginBottom: "15px" }}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      mb: { xs: 4, md: 3, lg: 2 },
                      mt: { xs: 4, md: 3, lg: 2 },
                    }}
                    variant={fullScreen ? "h3" : "h4"}
                    style={{
                      textAlign: "center",
                      color: fullScreen ? "#009ce0" : "#fff",
                    }}
                  >
                    Selecciona tu donación mensual
                  </Typography>
                </Grid>

                <Grid item lg={3} xs={3}>
                  <ColorButton
                    className={
                      amount === 120 ? "donate-button-active" : "donate-button"
                    }
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      border: "2px solid #fff",
                      backgroundColor:
                        amount === 120 ? "#009ce0" : "transparent",
                    }}
                    onClick={() => {
                      setError("");
                      setAmount(120);
                      setMonto("");
                      setShowAmount(false);
                    }}
                    variant="contained"
                  >
                    Bs 120
                  </ColorButton>
                </Grid>

                <Grid item lg={3} xs={3}>
                  <ColorButton
                    className={
                      amount === 90 ? "donate-button-active" : "donate-button"
                    }
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      border: "2px solid #fff",
                      backgroundColor:
                        amount === 90 ? "#009ce0" : "transparent",
                    }}
                    onClick={() => {
                      setError("");
                      setAmount(90);
                      setMonto("");
                      setShowAmount(false);
                    }}
                    variant="contained"
                  >
                    Bs 90
                  </ColorButton>
                </Grid>
                <Grid item lg={3} xs={3}>
                  <ColorButton
                    className={
                      amount === 60 ? "donate-button-active" : "donate-button"
                    }
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      border: "2px solid #fff",
                      backgroundColor:
                        amount === 60 ? "#009ce0" : "transparent",
                    }}
                    onClick={() => {
                      setError("");
                      setAmount(60);
                      setMonto("");
                      setShowAmount(false);
                    }}
                    variant="contained"
                  >
                    Bs 60
                  </ColorButton>
                </Grid>
                <Grid item lg={3} xs={3}>
                  <ColorButton
                    className={
                      amount !== 120 && amount !== 90 && amount !== 60
                        ? "donate-button-active"
                        : "donate-button"
                    }
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      border: "2px solid #fff",
                      backgroundColor:
                        amount !== 120 && amount !== 90 && amount !== 60
                          ? "#009ce0"
                          : "transparent",
                    }}
                    onClick={() => {
                      setAmount("");
                      setShowAmount(true);
                      setMonto("texto");
                    }}
                    variant="contained"
                  >
                    Otro
                  </ColorButton>
                </Grid>
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sx={{
                    mb: { xs: 4, md: 3, lg: 2 },
                    mt: { xs: 4, md: 2, lg: 1 },
                  }}
                >
                  <div>
                    <h6 style={{ padding: 4 }}>
                      *** No olvides activar tu tarjeta para compras por
                      internet
                    </h6>
                  </div>
                </Grid>

                <Grid
                  item
                  lg={12}
                  xs={12}
                  mt={1}
                  style={{ textAlign: "center" }}
                >
                  {showAmount ? (
                    <ButtonGroup
                      style={{ width: "100%" }}
                      disableElevation
                      variant="contained"
                      aria-label="Disabled elevation buttons"
                    >
                      <TextField
                        type="number"
                        style={{ width: "50%" }}
                        className="amount-text"
                        value={amount}
                        inputProps={{ pattern: "[0-9]*" }}
                        onChange={handleChange}
                        onKeyDown={handleChange}
                      />

                      <ColorButton
                        id="mainActionButton"
                        onClick={donate}
                        style={{ width: "50%" }}
                      >
                        Dona Ahora
                      </ColorButton>
                    </ButtonGroup>
                  ) : (
                    <ColorButton
                      id="mainActionButton"
                      style={{
                        borderRadius: 35,
                        width: fullScreen ? "50%" : "100%",
                      }}
                      onClick={donate}
                    >
                      Dona Ahora
                    </ColorButton>
                  )}
                </Grid>

                {error !== "" && (
                  <Grid item lg={12} xs={12} mt={1}>
                    <div
                      style={{
                        background: "#000",
                        zIndex: "99",
                        fontSize: "1rem",
                        fontWeight: 600,
                        textAlign: "center",
                        color: "#fff",
                        padding: 5,
                        borderRadius: 5,
                      }}
                    >
                      <WarningAmberIcon
                        variant="h6"
                        sx={{ fontSize: "2rem", marginRight: 1 }}
                      />
                      {error}
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
            <CardContainer
              key={"card1"}
              data={bodyInfoTeleton1Mobile}
              config={{}}
              action={goHome}
            />
          </>
        ) : (
          <>
            <section
              className="home-slider position-relative"
              style={{ marginBottom: "15px" }}
            >
              <CardContainer key={"card1"} data={bodyInfo1} config={{}} />
              <div
                className="donation-form"
                style={{ bottom: "15%", left: "15%", top: "auto" }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        mb: { xs: 4, md: 3, lg: 2 },
                        mt: { xs: 4, md: 3, lg: 2 },
                      }}
                      variant={fullScreen ? "h3" : "h4"}
                      style={{
                        textAlign: "center",
                        color: fullScreen ? "#009ce0" : "#fff",
                      }}
                    >
                      Selecciona tu donación mensual
                    </Typography>
                  </Grid>

                  <Grid item lg={3} xs={3}>
                    <ColorButton
                      className={
                        amount === 120
                          ? "donate-button-active"
                          : "donate-button"
                      }
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                        border: "2px solid #fff",
                        backgroundColor:
                          amount === 120 ? "#009ce0" : "transparent",
                      }}
                      onClick={() => {
                        setError("");
                        setAmount(120);
                        setMonto("");
                        setShowAmount(false);
                      }}
                      variant="contained"
                    >
                      Bs 120
                    </ColorButton>
                  </Grid>

                  <Grid item lg={3} xs={3}>
                    <ColorButton
                      className={
                        amount === 90 ? "donate-button-active" : "donate-button"
                      }
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                        border: "2px solid #fff",
                        backgroundColor:
                          amount === 90 ? "#009ce0" : "transparent",
                      }}
                      onClick={() => {
                        setError("");
                        setAmount(90);
                        setMonto("");
                        setShowAmount(false);
                      }}
                      variant="contained"
                    >
                      Bs 90
                    </ColorButton>
                  </Grid>
                  <Grid item lg={3} xs={3}>
                    <ColorButton
                      className={
                        amount === 60 ? "donate-button-active" : "donate-button"
                      }
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                        border: "2px solid #fff",
                        backgroundColor:
                          amount === 60 ? "#009ce0" : "transparent",
                      }}
                      onClick={() => {
                        setError("");
                        setAmount(60);
                        setMonto("");
                        setShowAmount(false);
                      }}
                      variant="contained"
                    >
                      Bs 60
                    </ColorButton>
                  </Grid>
                  <Grid item lg={3} xs={3}>
                    <ColorButton
                      className={
                        amount !== 120 && amount !== 90 && amount !== 60
                          ? "donate-button-active"
                          : "donate-button"
                      }
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                        border: "2px solid #fff",
                        backgroundColor:
                          amount !== 120 && amount !== 90 && amount !== 60
                            ? "#009ce0"
                            : "transparent",
                      }}
                      onClick={() => {
                        setAmount("");
                        setShowAmount(true);
                        setMonto("texto");
                      }}
                      variant="contained"
                    >
                      Otro
                    </ColorButton>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    sx={{
                      mb: { xs: 4, md: 3, lg: 2 },
                      mt: { xs: 4, md: 2, lg: 1 },
                    }}
                  >
                    <div>
                      <h6 style={{ padding: 4 }}>
                        *** No olvides activar tu tarjeta para compras por
                        internet
                      </h6>
                    </div>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    xs={12}
                    mt={1}
                    style={{ textAlign: "center" }}
                  >
                    {showAmount ? (
                      <ButtonGroup
                        style={{ width: "100%" }}
                        disableElevation
                        variant="contained"
                        aria-label="Disabled elevation buttons"
                      >
                        <TextField
                          type="number"
                          style={{ width: "50%" }}
                          className="amount-text"
                          inputProps={{ pattern: "[0-9]*" }}
                          value={amount}
                          onChange={handleChange}
                          onKeyDown={handleChange}
                        />

                        <ColorButton
                          id="mainActionButton"
                          onClick={donate}
                          style={{ width: "50%" }}
                        >
                          Dona Ahora
                        </ColorButton>
                      </ButtonGroup>
                    ) : (
                      <ColorButton
                        id="mainActionButton"
                        style={{
                          borderRadius: 35,
                          width: fullScreen ? "50%" : "100%",
                        }}
                        onClick={donate}
                      >
                        Dona Ahora
                      </ColorButton>
                    )}
                  </Grid>

                  {error !== "" && (
                    <Grid item lg={12} xs={12} mt={1}>
                      <div
                        style={{
                          background: "#000",
                          zIndex: "99",
                          fontSize: "1rem",
                          fontWeight: 600,
                          textAlign: "center",
                          color: "#fff",
                          padding: 5,
                          borderRadius: 5,
                        }}
                      >
                        <WarningAmberIcon
                          variant="h6"
                          sx={{ fontSize: "2rem", marginRight: 1 }}
                        />
                        {error}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            </section>
            {/*
            <CardContainer
              key={"card1"}
              data={bodyInfoTeleton1}
              config={{ style: { marginBottom: "15px" } }}
              action={goHome}
            />

            <img src={getImagePath(process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/Logo.png")}
                          style={{
                              position: "absolute",
                              width: "155px",
                              top: "-25px",
                              right: "142px",
                          }}/>*/}
            {/*
                    <div className="donation-legend" style={{
                        position: "absolute",
                        minHeight: 106,
                        padding: "23px 10px",
                        background: "#000",
                        textAlign: "center",
                        borderRadius: 5,
                        opacity: 0.5
                    }}>

                    </div>

                    <div className="donation-legend" style={{
                        zIndex: 999,
                        position: "absolute",
                        minHeight: 106,
                        padding: "23px 10px",
                    }}>

                        <div style={{}}>
                            <p style={{
                                fontSize: "2rem",
                                fontWeight: "bolder",
                                lineHeight: 1.1,
                                color: "#ffff00",
                                textAlign: "center"
                            }}>Tu donación MENSUAL</p>
                            <p style={{
                                fontSize: "1.25rem",
                                fontWeight: "bolder",
                                lineHeight: 1,
                                color: "#00b0f0",
                                textAlign: "center"
                            }}>Protege a miles de niñas y niños</p>
                        </div>

                    </div>
                    */}
            {/*
					<img  src={getImagePath(process.env.REACT_APP_URL_CONFIG+"/assets/imgs/unicef/Texto_web.png")}
						style={{
								position: "absolute",
								width: "680px",
								top: "35px",
							}} />
					*/}
          </>
        )}

        <CardContainer
          key={"card1"}
          data={bodyInfo1B}
          config={{
            middle: true,
            center: true,
            style: {
              height: 145,
              background: "#00b0f0",
              textAlign: "center",
              lineHeight: "1.85rem",
              marginTop: fullScreen ? "2rem" : "0px",
            },
          }}
          goHome={goHome}
        />

        <CardContainer
          key={"card1"}
          data={bodyInfo1C}
          config={{
            middle: true,
            center: true,
            style: {
              marginTop: "4rem",
            },
          }}
          goHome={goHome}
          action={handleClickOpen}
        />

        {/*<CardContainer
          key={"card2"}
          data={bodyInfo2}
          config={{
            middle: true,
            center: true,
            style: {
              marginTop: "4rem",
              marginBottom: "3rem",
            },
          }}
          goHome={goHome}
        />*/}

        <CardContainer
          key={"card2c"}
          data={bodyInfo2B}
          config={{
            middle: true,
            center: true,
            style: {
              background: "#80bd41",
              textAlign: "center",
              lineHeight: "1.85rem",
              marginTop: "2.5rem",
              marginBottom: "4.25rem",
              padding: "2rem 0rem",
            },
          }}
          goHome={goHome}
        />

        <section
          className="home-slider position-relative "
          style={{ height: fullScreen ? customH : 470 }}
        >
          <div className="">
            <SimpleSlider
              key={"slider2"}
              name={"slider2"}
              data={slider2}
              config={{ cols: fullScreen ? 1 : 2, space: fullScreen ? 0 : 1 }}
            />
          </div>
        </section>

        {/*<CardContainer
          key={"card2"}
          data={bodyInfo3a}
          config={{
            style: {
              marginTop: "1.5rem",
              marginBottom: "-1rem",
              padding: "2rem 0rem",
            },
          }}
          goHome={goHome}
        />*/}

        <CardContainer
          key={"card2c"}
          data={bodyInfo3c}
          config={{
            middle: true,
            center: true,
            style: {
              background: "rgb(98, 65, 189)",
              textAlign: "center",
              lineHeight: "1.85rem",
              marginTop: fullScreen ? "1.5rem" : "2rem",
              marginBottom: fullScreen ? "1rem" : "0px",
              padding: fullScreen ? "1rem 0rem" : "2rem 0rem",
            },
          }}
          goHome={goHome}
        />

        <section
          className="home-slider position-relative"
          style={{ marginTop: "4.5rem" }}
        >
          <SimpleSlider
            key={"slider1"}
            name={"slider1"}
            data={slider1}
            config={{}}
          />
        </section>



        {/*<CardContainer
          key={"card2"}
          data={bodyInfo3}
          config={{
            middle: true,
            center: true,
            style: {
              marginTop: fullScreen ? "0rem" : "4rem",
              marginBottom: "3rem",
            },
          }}
          goHome={goHome}
        />*/}
      </div>
      <footer
        hidden={!isFooterVisible}
        style={{
          position: "sticky",
          bottom: "0",
          width: "100%",
          backgroundColor: "#ffff00",
          display: "flex",
          justifyContent: "center",
          height: fullScreen ? "120px" : "80px",
          alignItems: "center",
          zIndex: "1",
        }}
      >
        <div
          style={{
            width: fullScreen ? "95%" : "80%",
            display: "flex",
            flexFlow: fullScreen ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "black",
              fontSize: fullScreen ? "16px" : "20px",
              fontWeight: "600",
              margin: "unset",
              height: "fit-content",
            }}
          >
            Ayuda a miles de ni&ntilde;os y participa del sorteo para renovar tu
            hogar
          </p>
          <p
            style={{
              width: "auto",
              color: "black",
              fontSize: fullScreen ? "20px" : "22px",
              fontWeight: "600",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              border: "2px",
              borderStyle: "solid",
              borderColor: "black",
              cursor: "pointer",
              borderRadius: "5px",
            }}
            onClick={goHome}
          >
            ¡DONA AHORA!
          </p>
        </div>
      </footer>
    </>
  );
};

Home.propTypes = {};

export default Home;
