import ApiClient from "../api/ApiClient";
import actionTypes from "../redux/action.types";
import * as Actions from "../redux/actions/user.actions";
import EndPoint from "../api/Endpoints";

export const Login = async (dispatch, data) => {
    try {
        const response = await ApiClient.doPost(EndPoint.login, data);
        if (response.token) {
            dispatch(Actions.loginSuccess(response));
        } else {
            dispatch(Actions.loginFailed({error: response.message}));
        }
    } catch (error) {
        console.log(">>>>", error)
        dispatch(Actions.loginFailed({error: "error" + error}));

    }
};
export const CreateUser = async (dispatch, data) => {
    try {
        const response = await ApiClient.doPost(EndPoint.CreateUser, data);
        if (response.success) {
            return {
                type: actionTypes.LOGIN_SUCCESS,
                payload: response,
            };
        } else {
            return {
                type: actionTypes.LOGIN_FAILED,
                payload: {error: response.message},
            };
        }
    } catch (error) {
        console.log(">>>>", error)
        return {
            type: actionTypes.LOGIN_FAILED,
            payload: {error: "error" + error},
        };
    }
};

export const RecoveryPass = async (dispatch, data) => {
    try {
        const response = await ApiClient.doPost(EndPoint.RecoreyPass, data);
        if (response.success) {
            return {
                type: actionTypes.LOGIN_SUCCESS,
                payload: response,
            };
        } else {
            return {
                type: actionTypes.LOGIN_FAILED,
                payload: {error: response.message},
            };
        }
    } catch (error) {
        console.log(">>>>", error)
        return {
            type: actionTypes.LOGIN_FAILED,
            payload: {error: "error" + error},
        };
    }
};


export const updatePassword = async (dispatch, data) => {
    try {
        const response = await ApiClient.doPut(EndPoint.updatePassword, data);

        if (response.status) {
            dispatch({type: actionTypes.PROCESS_SUCCESS, payload: response});
        } else {
            dispatch({type: actionTypes.PROCESS_FAILED, payload: response});
        }

    } catch (error) {
        console.log(">>>>", error)
        dispatch({type: actionTypes.PROCESS_ERROR, payload: {error: "ERROR:" + error}});
    }
};
