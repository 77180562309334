import React, {useState} from "react";
import Header from "../components/header/Header";
import HeaderAdmin from "../components/header/HeaderAdmin";
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import Box from '@mui/material/Box';
import Footer from "../components/footer/Footer";
import {useSelector} from "react-redux";
import Utils from "../utils/Utils";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MainLayout = ({openPanel, setOpenPanel}) => {
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
    const {usertoken} = useSelector(
        (state) => state.Login
    );
    const navigate = useNavigate();
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fullScreen2 = useMediaQuery(theme.breakpoints.down('md'));

    const wW = window.innerWidth

    var mainWidth = Math.ceil(window.innerWidth * 0.82)

    React.useEffect(() => {


        for (let i in Utils.includeSideBarAdmin) {

            if (location.pathname === Utils.includeSideBarAdmin[i]) {
                setIsAdmin(true)
                if (!usertoken) {
                    navigate(process.env.REACT_APP_ADMMIN_PATH + "/login");
                }
            }
        }
        if (fullScreen2) {
            setIsAdmin(false)
        }

    }, [usertoken])


    return (<>

            {location.pathname.indexOf(process.env.REACT_APP_ADMMIN_PATH) >= 0 || (Utils.excludeHeaderWeb.indexOf(location.pathname) >= 0 && !fullScreen) ? "" :
                <Header/>}
            <Box sx={{display: 'flex'}}>
                {location.pathname.indexOf("login") >= 0 ? "" : <HeaderAdmin setOpenPanel={setOpenPanel}/>}

                <Box component="main" sx={{
                    width: isAdmin ? mainWidth : '100%',
                    minHeight: windowHeight - 300 /*height: 768, overflowY: "scroll"*/
                }}>
                    <Outlet/>

                </Box>
            </Box>
            {location.pathname.indexOf("manage") < 0 && location.pathname.indexOf("comprobantes") < 0 &&
                <Footer/>
            }

        </>
    );
};

MainLayout.propTypes = {};

export default MainLayout;
