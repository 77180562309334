import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Document, Image, Page, PDFViewer, StyleSheet, Text, View} from '@react-pdf/renderer';
import EndPoint from "../api/Endpoints";
import {simplePostData} from '../utils/Functions';
//import BrandSlider from "./../components/sliders/Brand";
import {useNavigate, useParams} from 'react-router-dom';
import Loading from "../components/Loading";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

var wH = window.innerHeight


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        width: 600,
        textAlign: "center",
    },
    section: {
        position: "absolute",
        left: 50,
        top: 10
    },
    section2: {

        position: "absolute",
        left: 50,
        fontSize: "11px",
        lineHeight: "2",
        top: 170,
        paddingLeft: "26px",
        paddingRight: "26px",
    },

    section3: {

        position: "absolute",
        left: 50,
        top: 490
    },

    sectionT1: {
        position: "absolute",
        left: 50,
        top: 135,
        color: "#000",

        textAlign: "center"
    },
    sectionT2: {
        position: "absolute",
        left: 50,
        top: 385,
        lineHeight: "2",
        paddingLeft: "26px",
        paddingRight: "26px",
        color: "#000",
        fontSize: "11px",
        textAlign: "center"
    },
});


const Home = (props) => {

    let {token} = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showAmount, setShowAmount] = useState(false);
    const [name, setName] = useState("");
    const [card, setCard] = useState("");
    const [trx, setTrx] = useState("");
    const [date, setDate] = useState("");
    const [amount, setAmount] = useState("");
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        console.log(wH)

        loadForm();

    }, [])

    const loadForm = async (field, value, postAction) => {

        var response = await simplePostData(EndPoint.getOrderDetails, {token: token});
        console.log(response)
        if (response.status) {
            var inputDate = new Date(response.result.INSERT_DATE)


            let date = inputDate.getDate();
            let month = inputDate.getMonth() + 1;
            let year = inputDate.getFullYear();

            date = date
                .toString()
                .padStart(2, '0');

            month = month
                .toString()
                .padStart(2, '0');

            let finalDate = `${date}/${month}/${year}`

            setName((response.result.FIRSTNAME + " " + response.result.LASTNAME).toUpperCase())
            setCard(response.result.CARDNUMBER)
            setTrx(response.result.FIRSTTRANSACTIONID)
            setDate(finalDate)
            setAmount(response.result.AMOUNT)

        }
        setLoading(false)

    };

    const {open, onClose} = props;


    const dispatch = useDispatch();

    const {defConfig, params, defLang, activeForm} = useSelector((state) => state.Login);

    const {
        componentConfig,
        processSuccess,
        processError,
        requesting,
        processMessage,
        formFields,
        initialValues,
        validationSchema
    } = useSelector(
        (state) => state.Process
    );

    const deleteItem = (event) => {
        console.log("delete")
    };

    return (loading ? <Loading/>
            :
            <div style={{width: "100%"}}>
                <PDFViewer style={{width: "100%", height: "100vh"}}>
                    <Document>
                        <Page size="A4" style={styles.page}>


                            <View style={styles.section}>
                                <Image style={{width: "501px", height: "120px"}}
                                       src={process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/mail_header.jpg"}
                                       cache={false}/>
                            </View>

                            <View style={styles.sectionT1}>
                                <Text
                                    style={{color: "#ffc813", width: "501px", textAlign: "center"}}>Hola {name} </Text>

                            </View>

                            <View style={styles.section2}>

                                <Text style={{width: "440px", textAlign: "justify"}}>

                                    A partir de ahora eres un HÉROE/HEROÍNA de la niñez porque decidiste actuar al
                                    llamado de miles de niñas, niños y adolescentes de Bolivia.
                                    Recuerda que tu poder es proteger a la niñez y desde HOY empiezas a cambiar miles de
                                    vidas. GRACIAS a tu aporte mensual ayudarás a llevar programas de salud, educación y
                                    protección a los lugares más alejados del país, dándoles la oportunidad a muchas
                                    niñas, niños y adolescentes de vivir una infancia segura y feliz.
                                    A lo largo de nuestra amistad compartiremos contigo historias, así como los
                                    resultados del trabajo que realizamos, gracias a personas como tú, que hacen la
                                    DIFERENCIA EN ESTE MUNDO.

                                </Text>
                            </View>


                            <View style={styles.sectionT2}>
                                <Text style={{width: "440px", textAlign: "justify"}}>

                                    UNICEF ha debitado de tu tarjeta <Text
                                    style={{color: "#00adef", fontWeight: "bold"}}>{card}</Text> el importe de
                                    Bs.{amount} ,
                                    por concepto de donación mensual en fecha <Text
                                    style={{color: "#00adef", fontWeight: "bold"}}>{date}</Text>,
                                    con el código de transacción <Text
                                    style={{color: "#00adef", fontWeight: "bold"}}>{trx}</Text>.
                                    Recuerda que esta transacción también puede verse reflejada en tu extracto bancario
                                    bajo el nombre de<Text style={{color: "#00adef", fontWeight: "bold"}}> UNICEF</Text>.


                                </Text>
                            </View>


                            <View style={styles.section3}>
                                <Image style={{width: "501px", height: "248px"}}
                                       src={process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/mail_content2.jpg"}
                                       cache={false}/>
                            </View>

                        </Page>
                    </Document>
                </PDFViewer>
            </div>

    );
}

Home.propTypes = {};

export default Home;
