/* eslint-disable import/no-anonymous-default-export */
export default {

    includeSideBar: ["/", "/home", "/donar", "/donar/", "/donacion-realizada", "/error-donacion", "/terminos-del-concurso"],
    excludeFooter: ["/donacion-realizada", "/donacion-realizada", process.env.REACT_APP_ADMMIN_PATH + "/dashboard/",
        process.env.REACT_APP_ADMMIN_PATH + "/report", process.env.REACT_APP_ADMMIN_PATH + "/dashboard",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/", process.env.REACT_APP_ADMMIN_PATH + "/", process.env.REACT_APP_ADMMIN_PATH,
        process.env.REACT_APP_ADMMIN_PATH + "/manage/usergroups",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/users",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/donantes",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/transacciones",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/recurrentes"
    ],
    excludeHeaderWeb: ["/", "/terminos-del-concurso", "/test_pagos"],
    includeSideBarAdmin: [process.env.REACT_APP_ADMMIN_PATH + "/report", process.env.REACT_APP_ADMMIN_PATH + "/dashboard",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/", process.env.REACT_APP_ADMMIN_PATH + "/", process.env.REACT_APP_ADMMIN_PATH,
        process.env.REACT_APP_ADMMIN_PATH + "/manage/usergroups",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/users",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/donantes",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/transacciones",
        process.env.REACT_APP_ADMMIN_PATH + "/manage/recurrentes",
    ],

    datos_personales:
        {
            "data": [
                [
                    {
                        "id": 1,
                        "name": "firstname",
                        "type": "text",
                        "label": "Nombres",
                        "validations": {
                            "required": {
                                "status": true,
                                "message": "Este campo es obligatorio"
                            },
                            "string": {
                                "status": true,
                                "message": "campo tipo texto"
                            },
                            "number": {
                                "status": false,
                                "message": ""
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": false,
                                "message": "",
                                "min": ""
                            },
                            "max": {
                                "status": false,
                                "message": "",
                                "max": ""
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "12",
                            "lg": "6"
                        }
                    },
                    {
                        "id": 2,
                        "name": "lastname",
                        "type": "text",
                        "label": "Apellidos",
                        "validations": {
                            "required": {
                                "status": true,
                                "message": "Este campo es obligatorio"
                            },
                            "string": {
                                "status": true,
                                "message": "campo tipo texto"
                            },
                            "number": {
                                "status": false,
                                "message": ""
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": false,
                                "message": "",
                                "min": ""
                            },
                            "max": {
                                "status": false,
                                "message": "",
                                "max": ""
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "12",
                            "lg": "6"
                        }
                    },
                    {
                        "id": 5,
                        "name": "city",
                        "type": "select",
                        "label": "Ciudad",
                        "validations": {
                            "required": {
                                "status": true,
                                "message": ""
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": false,
                                "message": ""
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": false,
                                "message": "",
                                "min": ""
                            },
                            "max": {
                                "status": false,
                                "message": "",
                                "max": ""
                            }
                        },
                        "value": "La Paz",
                        "values": [
                            {
                                "value": "La Paz",
                                "text": "La Paz",
                                "id": 1
                            },
                            {
                                "value": "Santa Cruz",
                                "text": "Santa Cruz",
                                "id": 2
                            },
                            {
                                "value": "Cochabamba",
                                "text": "Cochabamba",
                                "id": 3
                            },

                            {
                                "value": "Chuquisaca",
                                "text": "Chuquisaca",
                                "id": 4
                            },
                            {
                                "value": "Potosí",
                                "text": "Potosí",
                                "id": 5
                            },
                            {
                                "value": "Oruro",
                                "text": "Oruro",
                                "id": 6
                            },
                            {
                                "value": "Tarija",
                                "text": "Tarija",
                                "id": 7
                            },
                            {
                                "value": "Beni",
                                "text": "Beni",
                                "id": 8
                            },
                            {
                                "value": "Pando",
                                "text": "Pando",
                                "id": 9
                            }


                        ],
                        "variant": "h5",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "12",
                            "lg": "6"
                        }
                    },
                    {
                        "id": 9,
                        "name": "dni",
                        "type": "number",
                        "label": "Carnet de Identidad (sin extensión)",
                        "validations": {
                            "required": {
                                "status": true,
                                "message": "Este campo es nesesario"
                            },
                            "string": {
                                "status": true,
                                "message": ""
                            },
                            "number": {
                                "status": false,
                                "message": "Este campo es nesesario"
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": false,
                                "message": "",
                                "min": ""
                            },
                            "max": {
                                "status": false,
                                "message": "",
                                "max": ""
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "12",
                            "lg": "6"
                        }
                    },
                    {
                        "id": 8,
                        "name": "birthday",
                        "type": "date",
                        "label": "Fecha de Nacimiento",
                        "validations": {
                            "required": {
                                "status": true,
                                "message": "Este campo es nesesario"
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": false,
                                "message": ""
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": false,
                                "message": "",
                                "min": ""
                            },
                            "max": {
                                "status": false,
                                "message": "",
                                "max": ""
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "/public/params/getDataByDomainLanguage",
                        "apimethod": "POST",
                        "apidefdata": "domainid=17,value=CODEDETAILDOMAIN,label=STRING_VALUE",
                        "apitoken": "",
                        "size": {
                            "xs": "12",
                            "lg": "6"
                        }
                    },
                    {
                        "id": 3,
                        "name": "codenumber",
                        "type": "text",
                        "disabled": true,
                        "label": "Codigo Pais",
                        "validations": {
                            "required": {
                                "status": false,
                                "message": ""
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": false,
                                "message": ""
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": false,
                                "message": "Codigo de Teléfono Celular inválido",
                                "min": "60000000"
                            },
                            "max": {
                                "status": false,
                                "message": "Codigo de Teléfono Celular inválido",
                                "max": "79999999"
                            }
                        },
                        "value": "(+591)",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "3",
                            "lg": "2"
                        }
                    },
                    {
                        "id": 3,
                        "name": "phonenumber",
                        "type": "tel",
                        "label": "Teléfono Celular",
                        "validations": {
                            "required": {
                                "status": true,
                                "message": "Número de Teléfono Celular inválido"
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": true,
                                "message": "Número de Teléfono Celular inválido"
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": true,
                                "message": "Número de Teléfono Celular inválido",
                                "min": "60000000"
                            },
                            "max": {
                                "status": true,
                                "message": "Número de Teléfono Celular inválido",
                                "max": "79999999"
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "9",
                            "lg": "4"
                        }
                    },

                    {
                        "id": 4,
                        "name": "email",
                        "type": "text",
                        "label": "Correo electrónico",
                        "validations": {
                            "required": {
                                "status": true,
                                "message": "Este campo es necesario"
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": false,
                                "message": ""
                            },
                            "email": {
                                "status": true,
                                "message": "Formato de email incorrecto"
                            },
                            "min": {
                                "status": false,
                                "message": "",
                                "min": ""
                            },
                            "max": {
                                "status": false,
                                "message": "",
                                "max": ""
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "12",
                            "lg": "6"
                        }
                    }

                ]
            ],
            "datatitle": "Datos del Cliente",
            "datasubtitle": "Por favor complete los siguientes campos",
            "datapostload": "",
            "action": "NO_ACTION"
        },
    datos_pago:
        {
            "data": [
                [
                    {
                        "id": 0,
                        "name": "paymentmethodtitle",
                        "type": "title",
                        "label": "Datos de la Tarjeta",
                        "action": "",
                        "validations": {
                            "required": {
                                "status": false,
                                "message": ""
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": false,
                                "message": "Número de Tarjeta inválida"
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": false,
                                "message": "Número de Tarjeta inválida",
                                "min": "1000000000000000"
                            },
                            "max": {
                                "status": false,
                                "message": "Número de Tarjeta inválida",
                                "max": "4999999999999999"
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "h5",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "12",
                            "lg": "12"
                        }
                    },
                    {
                        "id": 1,
                        "name": "cardnumber",
                        "type": "text",
                        "mask": "#### #### #### ####",
                        "placeholder": "1234 1234 1234 1234",
                        "label": "Número de Tarjeta",
                        "validations": {
                            "required": {
                                "status": true,
                                "message": "Tarjeta inválida"
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": true,
                                "message": ""
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": true,
                                "message": "Tarjeta inválida",
                                "min": "1000000000000000"
                            },
                            "max": {
                                "status": true,
                                "message": "Tarjeta inválida",
                                "max": "4999999999999999"
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "12",
                            "lg": "6"
                        }
                    },
                    {
                        "id": 2,
                        "name": "expiremonth",
                        "placeholder": "Ej. 04",
                        "type": "number",
                        "mask": "##",
                        "label": "Mes ",
                        "validations": {
                            "required": {
                                "status": false,
                                "message": ""
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": false,
                                "message": ""
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": false,
                                "message": "",
                                "min": ""
                            },
                            "max": {
                                "status": false,
                                "message": "",
                                "max": ""
                            }
                        },
                        "value": "",
                        "values": [
                            {
                                "id": 1,
                                "value": "01",
                                "text": "Enero"
                            },
                            {
                                "id": 2,
                                "value": "02",
                                "text": "Febrero"
                            },
                            {
                                "id": 3,
                                "value": "03",
                                "text": "Marzo"
                            },
                            {
                                "id": 4,
                                "value": "04",
                                "text": "Abril"
                            },
                            {
                                "id": 5,
                                "value": "05",
                                "text": "Mayo"
                            },
                            {
                                "id": 6,
                                "value": "06",
                                "text": "Junio"
                            },
                            {
                                "id": 7,
                                "value": "07",
                                "text": "Julio"
                            },
                            {
                                "id": 8,
                                "value": "08",
                                "text": "Agosto"
                            },
                            {
                                "id": 9,
                                "value": "09",
                                "text": "Septiembre"
                            },
                            {
                                "id": 10,
                                "value": "10",
                                "text": "Octubre"
                            },
                            {
                                "id": 11,
                                "value": "11",
                                "text": "Noviembre"
                            },
                            {
                                "id": 12,
                                "value": "12",
                                "text": "Diciembre"
                            }
                        ],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "4",
                            "lg": "2"
                        }
                    },
                    {
                        "id": 3,
                        "name": "expireyear",
                        "placeholder": "Ej. 2028",
                        "type": "number",
                        "label": "Año",
                        "mask": "####",
                        "validations": {
                            "required": {
                                "status": false,
                                "message": ""
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": false,
                                "message": ""
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": false,
                                "message": "",
                                "min": ""
                            },
                            "max": {
                                "status": false,
                                "message": "",
                                "max": ""
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "4",
                            "lg": "2"
                        }
                    },
                    {
                        "id": 4,
                        "name": "cvv",
                        "type": "password",
                        "label": "cvv",
                        "validations": {
                            "required": {
                                "status": true,
                                "message": "cvv inválido"
                            },
                            "string": {
                                "status": false,
                                "message": ""
                            },
                            "number": {
                                "status": true,
                                "message": "cvv inválido"
                            },
                            "email": {
                                "status": false,
                                "message": ""
                            },
                            "min": {
                                "status": true,
                                "message": "cvv inválido",
                                "min": "100"
                            },
                            "max": {
                                "status": true,
                                "message": "cvv inválido",
                                "max": "999"
                            }
                        },
                        "value": "",
                        "values": [],
                        "variant": "",
                        "color": "primary",
                        "apiurl": "",
                        "apimethod": "POST",
                        "apidefdata": "",
                        "apitoken": "",
                        "size": {
                            "xs": "4",
                            "lg": "2"
                        }
                    }

                ]
            ],
            "datatitle": "Datos de la Tarjeta",
            "datasubtitle": "",
            "datapostload": "",
            "action": "NO_ACTION"
        },

    //ProfileMenus: [ "Perfil", "Administaci\u00F3n", "Salir"],

    countryList: [
        "Bolivia",
        "Afganist\u00E1n",
        "Albania",
        "Alemania",
        "Andorra",
        "Angola",
        "Anguila",
        "Ant\u00E1rtida",
        "Antigua y Barbuda",
        "Antillas holandesas",
        "Arabia Saud\u00ED",
        "Argelia",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaiy\u00E1n",
        "Bahamas",
        "Bahrein",
        "Bangladesh",
        "Barbados",
        "B\u00E9lgica",
        "Belice",
        "Ben\u00EDn",
        "Bermudas",
        "Bhut\u00E1n",
        "Bielorrusia",
        "Birmania",
        "Bosnia y Herzegovina",
        "Botsuana",
        "Brasil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Camboya",
        "Camer\u00FAn",
        "Canad\u00E1",
        "Chad",
        "Chile",
        "China",
        "Chipre",
        "Ciudad estado del Vaticano",
        "Colombia",
        "Comores",
        "Congo",
        "Corea",
        "Corea del Norte",
        "Costa del Marf\u00EDl",
        "Costa Rica",
        "Croacia",
        "Cuba",
        "Dinamarca",
        "Djibouri",
        "Dominica",
        "Ecuador",
        "Egipto",
        "El Salvador",
        "Emiratos Arabes Unidos",
        "Eritrea",
        "Eslovaquia",
        "Eslovenia",
        "España",
        "Estados Unidos",
        "Estonia",
        "c",
        "Ex-Rep\u00FAblica Yugoslava de Macedonia",
        "Filipinas",
        "Finlandia",
        "Francia",
        "Gab\u00F3n",
        "Gambia",
        "Georgia",
        "Georgia del Sur y las islas Sandwich del Sur",
        "Ghana",
        "Gibraltar",
        "Granada",
        "Grecia",
        "Groenlandia",
        "Guadalupe",
        "Guam",
        "Guatemala",
        "Guayana",
        "Guayana francesa",
        "Guinea",
        "Guinea Ecuatorial",
        "Guinea-Bissau",
        "Hait\u00ED",
        "Holanda",
        "Honduras",
        "Hong Kong R. A. E",
        "Hungr\u00EDa",
        "India",
        "Indonesia",
        "Irak",
        "Ir\u00E1n",
        "Irlanda",
        "Isla Bouvet",
        "Isla Christmas",
        "Isla Heard e Islas McDonald",
        "Islandia",
        "Islas Caim\u00E1n",
        "Islas Cook",
        "Islas de Cocos o Keeling",
        "Islas Faroe",
        "Islas Fiyi",
        "Islas Malvinas Islas Falkland",
        "Islas Marianas del norte",
        "Islas Marshall",
        "Islas menores de Estados Unidos",
        "Islas Palau",
        "Islas Salom\u00F3n",
        "Islas Tokelau",
        "Islas Turks y Caicos",
        "Islas V\u00EDrgenes EE.UU.",
        "Islas V\u00EDrgenes Reino Unido",
        "Israel",
        "Italia",
        "Jamaica",
        "Jap\u00F3n",
        "Jordania",
        "Kazajist\u00E1n",
        "Kenia",
        "Kirguizist\u00E1n",
        "Kiribati",
        "Kuwait",
        "Laos",
        "Lesoto",
        "Letonia",
        "L\u00EDbano",
        "Liberia",
        "Libia",
        "Liechtenstein",
        "Lituania",
        "Luxemburgo",
        "Macao R. A. E",
        "Madagascar",
        "Malasia",
        "Malawi",
        "Maldivas",
        "Mal\u00ED",
        "Malta",
        "Marruecos",
        "Martinica",
        "Mauricio",
        "Mauritania",
        "Mayotte",
        "M\u00E9xico",
        "Micronesia",
        "Moldavia",
        "M\u00F3naco",
        "Mongolia",
        "Montserrat",
        "Mozambique",
        "Namibia",
        "Nauru",
        "Nepal",
        "Nicaragua",
        "N\u00EDger",
        "Nigeria",
        "Niue",
        "Norfolk",
        "Noruega",
        "Nueva Caledonia",
        "Nueva Zelanda",
        "Om\u00E1n",
        "Panam\u00E1",
        "Papua Nueva Guinea",
        "Paquist\u00E1n",
        "Paraguay",
        "Per\u00FA",
        "Pitcairn",
        "Polinesia francesa",
        "Polonia",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reino Unido",
        "Rep\u00FAblica Centroafricana",
        "Rep\u00FAblica Checa",
        "Rep\u00FAblica de Sud\u00E1frica",
        "Rep\u00FAblica Democr\u00E1tica del Congo Zaire",
        "Rep\u00FAblica Dominicana",
        "Reuni\u00F3n",
        "Ruanda",
        "Rumania",
        "Rusia",
        "Samoa",
        "Samoa occidental",
        "San Kitts y Nevis",
        "San Marino",
        "San Pierre y Miquelon",
        "San Vicente e Islas Granadinas",
        "Santa Helena",
        "Santa Luc\u00EDa",
        "Santo Tom\u00E9 y Pr\u00EDncipe",
        "Senegal",
        "Serbia y Montenegro",
        "Sychelles",
        "Sierra Leona",
        "Singapur",
        "Siria",
        "Somalia",
        "Sri Lanka",
        "Suazilandia",
        "Sud\u00E1n",
        "Suecia",
        "Suiza",
        "Surinam",
        "Svalbard",
        "Tailandia",
        "Taiw\u00E1n",
        "Tanzania",
        "Tayikist\u00E1n",
        "Territorios brit\u00E1nicos del oc\u00E9ano Indico",
        "Territorios franceses del sur",
        "Timor Oriental",
        "Togo",
        "Tonga",
        "Trinidad y Tobago",
        "T\u00FAnez",
        "Turkmenist\u00E1n",
        "Turqu\u00EDa",
        "Tuvalu",
        "Ucrania",
        "Uganda",
        "Uruguay",
        "Uzbekist\u00E1n",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Wallis y Futuna",
        "Yemen",
        "Zambia",
        "Zimbabue"
    ]
};
