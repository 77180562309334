import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from 'react-router-dom';

//import BrandSlider from "./../components/sliders/Brand";

import CardContainer from "../components/elements/CardContainer";


import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

const Terminos = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const customH = window.innerHeight - 65

    const bodyInfoH_web = [


        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "col-12",
                    "title": "",

                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "imagestyle": {
                        height: customH,
                        width: "100%",
                        objectFit: "cover",
                        objectPosition: "0 -150px"
                    },
                    "link": "",
                    "image": "",
                    "type": "text",

                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/septiembre2023/privacidad.jpg"
                },

            ]
        },

        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "AVISO DE PRIVACIDAD",
                    titlestyle: {
                        color: "#fff",
                        fontSize: "3rem",
                        marginTop: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: fullScreen ? "9.5rem" : "7.5rem",
                        background: "#00b0f0",
                        lineHeight: 1.1,
                        textAlign: "center"

                    },

                    "updown": "JUSTTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

            ]
        },


    ]
    const bodyInfoH_mob = [


        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "col-12",
                    "title": "",

                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagestyle": {
                        height: customH - 200,
                        width: "100%",
                        objectFit: "cover",
                        objectPosition: "-318 bottom"
                    },
                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/septiembre2023/privacidad.jpg"
                },

            ]
        },

        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "AVISO DE PRIVACIDAD",
                    titlestyle: {
                        color: "#fff",
                        fontSize: "2rem",
                        marginTop: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: fullScreen ? "7.5rem" : "7.5rem",
                        background: "#00b0f0",
                        lineHeight: 1.1,
                        textAlign: "center"

                    },

                    "updown": "JUSTTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

            ]
        },

    ]
    const bodyInfo1 = [


        {
            "id": 2,

            "childs": [
                {
                    "id": 1,
                    "size": "",
                    "title": "AVISO DE PRIVACIDAD",
                    "titlestyle": {"textAlign": "center", "color": "#1cabe2", "marginTop": 25},
                    "updown": "JUSTTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                }
            ]
        },


    ]


    useEffect(() => {

    }, [])

    return (
        <>


            {!fullScreen ?
                <section className="home-slider position-relative ">
                    <CardContainer key={"card1"} data={bodyInfoH_web} config={{}}/>
                </section>

                :
                <>
                    <section className="home-slider position-relative">
                        <CardContainer key={"card1"} data={bodyInfoH_mob} config={{}}/>
                    </section>

                </>
            }


            {!fullScreen &&
                <div style={{
                    cursor: "pointer",
                    top: 0,
                    position: "fixed",
                    zIndex: 999,
                    height: 150,
                    width: 350,
                    background: "transparent"
                }} className="" onClick={() => {
                    navigate("/");
                }}>

                </div>
            }


            <div className="container container-terms mt-60">
                <div className="row mt-2">
                    <div className="col-12 col-md-12 col-sm-12 col-12  ">

                        <p className="text-justify mt-3">La privacidad del usuario de Internet es de vital importancia
                            para el Fondo de las Naciones Unidas para la Infancia (UNICEF). En esta declaraci&oacute;n
                            sobre la privacidad se describe la pol&iacute;tica de UNICEF con respecto a la
                            obtenci&oacute;n e intercambio de informaci&oacute;n de los visitantes al sitio web. Tenga
                            en cuenta que al visitar el sitio web de UNICEF usted se compromete a aceptar las
                            pr&aacute;cticas descritas en esta pol&iacute;tica.</p>

                        <p className="mt-3"><span
                            className="term-title"><strong>&iquest;QU&Eacute; TIPO DE INFORMACI&Oacute;N RECOPILA UNICEF?</strong></span>
                        </p>

                        <p className="mt-3"><span
                            className="term-sub"><strong>Uso ordinario del sitio en la web</strong></span></p>

                        <p className="mt-2">En general, el usuario puede navegar por el sitio de UNICEF sin necesidad de
                            revelar su identidad ni cualquier otro tipo de informaci&oacute;n personal.
                            Los &uacute;nicos datos que recopilamos durante una visita general es la de las anotaciones
                            est&aacute;ndar del servidor. Esto incluye la direcci&oacute;n del protocolo de Internet, el
                            nombre del dominio, el tipo de navegador, el sistema de operaci&oacute;n y otra
                            informaci&oacute;n como la que se refiere al sitio web que lo remiti&oacute; a nosotros, los
                            ficheros que descarga, las p&aacute;ginas que visita y las fechas y horas en que se
                            produjeron dichas visitas.</p>

                        <p className="mt-3"><strong><span
                            className="term-sub">Recopilaci&oacute;n de datos de car&aacute;cter personal</span></strong>
                        </p>

                        <p className="text-justify mt-2">Al momento de realizar una donaci&oacute;n, suscribirse para
                            recibir un bolet&iacute;n, acceder a determinados sitios de UNICEF, solicitar
                            informaci&oacute;n, solicitar un puesto de trabajo, inscribirse en un grupo de debate o en
                            una lista de correo electr&oacute;nico, se pedir&aacute; al usuario que facilite
                            informaci&oacute;n personal como el nombre, el correo electr&oacute;nico, la ciudad, el
                            tel&eacute;fono celular y en el caso de las donaciones los datos de la tarjeta de
                            cr&eacute;dito/d&eacute;bito y cualquier dato extra que se necesite para terminar el proceso
                            (como la cedula de identidad, fecha de vencimiento, etc). Esta informaci&oacute;n se
                            recopila solamente bajo su conocimiento y autorizaci&oacute;n y se mantiene resguardada en
                            bases de datos de UNICEF, esta informaci&oacute;n solo ser&aacute; usada por nosotros y
                            exclusivamente para el proceso para el cual fue brindada ya sea donaci&oacute;n,
                            suscripci&oacute;n o solicitud de informaci&oacute;n, la misma no se comparte ni transfiere
                            con nadie.</p>

                        <p className="mt-30"><span className="term-title"><strong>&iquest;QU&Eacute; HACE UNICEF CON LA INFORMACI&Oacute;N QUE RECOPILA?</strong></span>
                        </p>

                        <p className="mt-3"><span
                            className="term-sub"><strong>Uso ordinario del sitio web</strong></span></p>

                        <p className="text-justify mt-2">La informaci&oacute;n recopilada durante la navegaci&oacute;n
                            general del dominio &ldquo;unicef.org&rdquo; se utiliza para analizar las tendencias y el
                            uso del sitio de UNICEF y para mejorar su utilidad. No comporta ning&uacute;n dato personal.
                            Sin embargo, si el usuario se ha registrado con UNICEF para cualquier actividad del sitio de
                            UNICEF, la informaci&oacute;n que recopilemos sobre el uso normal de la web le
                            identificar&aacute;.</p>

                        <p><span
                            className="term-sub"><strong>Informaci&oacute;n de identificaci&oacute;n personal</strong></span>
                        </p>

                        <p className="mt-3 mb-2">UNICEF puede utilizar los datos personales que facilita el usuario
                            para:</p>

                        <ul>
                            <li className="text-justify">Ponerse en contacto con el interesado en relaci&oacute;n con
                                una pregunta o sugerencia, o enviarle boletines, documentos, publicaciones, etc., y
                                tambi&eacute;n podemos facilitar la informaci&oacute;n a los comit&eacute;s nacionales
                                en pro de UNICEF para que se puedan poner tambi&eacute;n en contacto con el usuario.
                            </li>
                            <li className="text-justify">Contactarlo a trav&eacute;s de correo electr&oacute;nico,
                                llamadas telef&oacute;nicas, mensajer&iacute;a instant&aacute;nea, whatsapp, redes
                                sociales o cualquier otro medio de comunicaci&oacute;n que podamos acceder a
                                trav&eacute;s de los datos dejados; ya sea para el env&iacute;o de informaci&oacute;n o
                                bien para otro tipo de comunicaciones.
                            </li>
                            <li className="text-justify">Tramitar su candidatura a un puesto de trabajo;</li>
                            <li className="text-justify">Confirmar sus compras e inscripciones en el sitio;</li>
                            <li className="text-justify">Cobrar las compras realizadas a trav&eacute;s del sitio
                                (mediante tarjeta de cr&eacute;dito/debito)
                            </li>
                            <li className="text-justify">&laquo;Recordar&raquo; su perfil en l&iacute;nea y sus
                                preferencias;
                            </li>
                            <li className="text-justify">Ayudarle a encontrar con rapidez informaci&oacute;n que le
                                interese y permitirnos que el contenido del sitio responsa a sus necesidades;
                            </li>
                            <li className="text-justify">Realizar an&aacute;lisis estad&iacute;sticos.</li>
                        </ul>

                        <p className="mt-3"><span className="term-sub "><strong>&iquest;Qu&eacute; ocurre si el usuario no quiere facilitar datos personales?</strong></span>
                        </p>

                        <p className="text-justify mt-2">Facilitar datos personales en el sitio de UNICEF es
                            facultativo. Si un usuario decide no hacerlo, podr&aacute; continuar navegando y utilizando
                            el sitio de UNICEF, pero no podr&aacute;, por ejemplo, comprar art&iacute;culos, realizar
                            donaciones, suscribirse a un bolet&iacute;n o postular a un empleo.</p>

                        <p className="mt-3"><span
                            className="term-sub"><strong>Exclusi&oacute;n voluntaria y modificaci&oacute;n de los datos</strong></span>
                        </p>

                        <p className="text-justify mt-2">En cualquier momento, el usuario puede suprimir o modificar los
                            datos facilitados volviendo a la p&aacute;gina web en la que los comunic&oacute; por primera
                            vez y dirigi&eacute;ndose al encargado de esa p&aacute;gina o al departamento pertinente de
                            UNICEF. Si en la p&aacute;gina no se indica ad&oacute;nde debe dirigirse, se puede escribir
                            a <a href="mailto:donaciones@unicef.org.bo">cont&aacute;ctenos </a>para obtener m&aacute;s
                            informaci&oacute;n.</p>

                        <p className="mt-3"><span className="term-sub"><strong>Seguridad</strong></span></p>

                        <p className="text-justify mt-2">UNICEF no vende a terceros, ni intercambia con ellos, ninguna
                            informaci&oacute;n de identificaci&oacute;n personal voluntariamente consignada en su sitio
                            web sin un consentimiento previo del usuario. Sin embargo, s&iacute; intercambiamos
                            informaci&oacute;n total y parcial del usuario con nuestros comit&eacute;s nacionales a fin
                            de que toda la familia de UNICEF pueda responder a sus necesidades. Toda informaci&oacute;n
                            que haya sido facilitada a UNICEF por los usuarios del sitio se conserva con el mayor
                            cuidado y seguridad posibles, y s&oacute;lo se utilizar&aacute; conforme a lo expresado en
                            esta pol&iacute;tica de privacidad, o en las pol&iacute;ticas propias de cada sitio, o a lo
                            que el usuario haya autorizado expresamente. UNICEF emplea una serie de tecnolog&iacute;as y
                            medidas de seguridad para proteger la informaci&oacute;n conservada en sus sistemas contra
                            p&eacute;rdidas, uso incorrecto, acceso o divulgaci&oacute;n no autorizados,
                            alteraci&oacute;n o destrucci&oacute;n.</p>

                        <p className="text-justify mt-2">Sin embargo, UNICEF no asume ninguna responsabilidad por la
                            seguridad de la informaci&oacute;n.</p>

                        <p className="text-justify mt-2">El sitio de UNICEF tiene enlaces a sitios ajenos al dominio
                            unicef.org. UNICEF no es responsable de las pr&aacute;cticas en materia de privacidad ni de
                            los contenidos de tales sitios.</p>

                        <p className="mt-3"><span
                            className="term-sub"><strong>Notificaci&oacute;n de cambios</strong></span></p>

                        <p>Toda modificaci&oacute;n de esta pol&iacute;tica de privacidad se publicar&aacute; en la
                            p&aacute;gina principal de UNICEF, en la direcci&oacute;n <a
                                href="https://www.unicef.org/bolivia">https://www.unicef.org/bolivia</a>.</p>

                        <p className="mt-30"><span className="term-title"><strong>PUNTO DE CONTACTO</strong></span></p>

                        <p className="text-justify mt-2">Para formular preguntas o consultas respecto de esta
                            pol&iacute;tica de privacidad, s&iacute;rvanse ponerse en contacto con nosotros en
                            cont&aacute;ctenos escribiendo la palabra &ldquo;privacidad&rdquo; en el tema.</p>

                        <p className="text-justify mt-2">*Cookies: un cookie es un n&uacute;mero reducido de datos que
                            son enviados de un servidor web al navegador del usuario. Generalmente se utiliza para
                            asignar una identificaci&oacute;n &uacute;nica a su ordenador y almacenar en condiciones de
                            seguridad datos tales como la identificaci&oacute;n del usuario, contrase&ntilde;as,
                            preferencias y perfiles en l&iacute;nea. Se almacena en el disco duro de su ordenador. Si
                            el</p>

                        <p className="text-justify mt-2 mb-30">usuario no desea que los sitios de UNICEF le
                            env&iacute;en cookies, puede cambiar la configuraci&oacute;n de su navegador. Otros sitios
                            web pueden enviar sus propios cookies a su ordenador. Para proteger la privacidad del
                            usuario, el navegador solo permite a un sitio web acceder a los cookies que ya le ha
                            enviado, no a los que le env&iacute;an otros sitios.</p>

                    </div>
                </div>


            </div>

        </>
    );
}

Terminos.propTypes = {};

export default Terminos;
