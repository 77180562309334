import React, { useEffect, useState } from "react";
//import Script from 'next/script'
import { useDispatch, useSelector } from "react-redux";

import "./Donar.css";
import * as Actions from "../redux/actions/process.actions";

import GenericForm from "../components/forms/GenericForm";
import {
  createValidationSchema,
  getCookie,
  mergeFormData,
  prepareForm,
  setCookie,
  simplePostData,
  simplePutData,
} from "../utils/Functions";
//import ScriptTag from 'react-script-tag';
import Utils from "../utils/Utils";
import { Container, Grid, TextField, Typography } from "@mui/material";

import Button from "@mui/material/Button";
//import { useRouter } from 'next/router'
import { useNavigate, useParams } from "react-router-dom";
import EndPoint from "../api/Endpoints";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

var orderid = 0;
var tryout = 0;

var wH = window.innerHeight;
var fieldType = "hidden";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#000"),
  fontSize: 24,
  fontWeight: "700",
  height: 65,
  borderRadius: 35,
  textTransform: "capitalize",
  /*
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),*/
  width: "100%",
  backgroundColor: "#ffff00",
  "&:hover": {
    backgroundColor: "#f26a21",
  },
}));

const CheckOut = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { defConfig, defLang } = useSelector((state) => state.Login);
  const [personaldata, setPersonaldata] = React.useState({});
  const [paymentdata, setPaymentdata] = React.useState({});

  const [personaldataSucc, setPersonaldataSucc] = React.useState(false);
  const [noButton, setNoButton] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [showCyberFrame, setShowCyberFrame] = React.useState(false);
  const [paymentToken, setPaymentToken] = React.useState({});
  const [showCoupon, setShowCoupon] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const [loading, setLoading] = useState(false);
  const [showAmount, setShowAmount] = useState(false);
  const [amount, setAmount] = useState(120);
  const [checkedP, setCheckedP] = useState(true);

  const handleChangeP = (event) => {
    //console.log("ch")
    setCheckedP(event.target.checked);
  };

  const [finalData, setFinalData] = useState({
    access_key: "1",
  });

  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");

  var date = new Date();
  var defDate = date.setMonth(date.getMonth() - 12 * 18);

  const confirmPersonalData = (values) => {
    if (personaldataSucc) {
      setPersonaldataSucc(true);
      setTimeout(() => {
        setPersonaldataSucc(false);
      }, 1);
    } else {
      setPersonaldataSucc(true);
      let _formData = formData;
      Object.assign(_formData, values);
      setFormData(_formData);

      let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source");
      let stepValues = {
        amount,
        step: "personal-info",
        utm_source: utm_source,
        birthday: values.birthday,
        city: values.city,
        dni: values.dni,
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
        phonenumber: values.phonenumber,
        sessionid: tmpOrder,
      };
      //step2
      dispatch(Actions.saveDonationPersonalData(stepValues));
      simplePutData(EndPoint.setStep, stepValues);
    }
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen2 = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const confirmPayment = async (values) => {
    let _formData = formData;
    Object.assign(_formData, values);
    setFormData(_formData);
    setLoading(true);
    setError("");

    _formData["amount"] = amount;

    if (_formData["birthday"] == "") {
      //	console.log("defDate",defDate)
      _formData["birthday"] = new Date(defDate);
    }
    setErrMessage("");
    let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source");
    let utm_id = getCookie(process.env.REACT_APP_NAME + "utm_id");
    let utm_content = getCookie(process.env.REACT_APP_NAME + "utm_content");
    let utm_medium = getCookie(process.env.REACT_APP_NAME + "utm_medium");
    let utm_campaign = getCookie(process.env.REACT_APP_NAME + "utm_campaign");
    let utm_term = getCookie(process.env.REACT_APP_NAME + "utm_term");

    _formData["utm_source"] = utm_source;
    _formData["utm_id"] = utm_id;
    _formData["utm_content"] = utm_content;
    _formData["utm_medium"] = utm_medium;
    _formData["utm_campaign"] = utm_campaign;
    _formData["utm_term"] = utm_term;

    _formData["cardnumber"] = _formData["cardnumber"]
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .replaceAll("_", "")
      .trim();
    _formData["firstname"] = _formData["firstname"]
      .replaceAll("-", " ")
      .replaceAll("_", " ")
      .trim();
    _formData["lastname"] = _formData["lastname"]
      .replaceAll("-", " ")
      .replaceAll("_", " ")
      .trim();

    let stepValues = {
      amount,
      step: "checkout-order-step1",
      utm_source: utm_source,
      dni: _formData.dni,
      email: _formData.email,
      firstname: _formData.firstname,
      lastname: _formData.lastname,
      phonenumber: _formData.phonenumber,
      cardnumber: _formData.cardnumber,
      month: _formData.expiremonth,
      year: _formData.expireyear,
      cvv: _formData.cvv,
      sessionid: tmpOrder,
    };
    //step3
    simplePutData(EndPoint.setStep, stepValues);

    var response = await simplePutData(EndPoint.createOrder, _formData);

    if (response.status) {
      tryout = 0;
      orderid = response.id;
      var data = {
        ci: _formData.dni,
        phone: _formData.phonenumber,
        nit: _formData.dni,
        amount: amount,
        referenceNumber: response.id,
        email: _formData.email,
        card_name: _formData.firstname,
        card_lastname: _formData.lastname,
        card_number: _formData.cardnumber,
        month: _formData.expiremonth,
        year: _formData.expireyear,
        cvv: _formData.cvv,
        device_fingerprint_id: tmpOrder,
      };
      response = await simplePostData(EndPoint.generateSignature, data);

      if (response.status) {
        setFinalData(response.data);
        setSignature(response.signature);

        setTimeout(async () => {
          let stepValues = {
            amount,
            step: "checkout-cyber-step-2",
            orderid: response.id,
            utm_source: utm_source,
            sessionid: tmpOrder,
          };
          //step4
          await simplePutData(EndPoint.setStep, stepValues);
          document.getElementById("formCyber").submit();
        }, 200);
      } else {
        setLoading(false);
        setError("Hubo un problema por favor intentelo nuevamente");
      }
    } else {
      setLoading(false);
      if (response.userInfo) {
        setErrMessage(response.message);
        handleOpen(true);
      } else {
        if (response.message === "INVALID") {
          setError(
            "Revise los datos de Carnet de identidad, nombres y tel\u00E9fono"
          );
        } else {
          setError("Hubo un problema por favor intentelo nuevamente");
        }
      }
    }
  };

  const checkStatusOrder = async () => {
    if (tryout < 60) {
      tryout++;
      let response = await simplePostData(EndPoint.checkStatusOrder, {
        orderid: orderid,
      });

      if (response.status) {
        setCookie(
          process.env.REACT_APP_NAME + "_orderidSucc",
          response.data.CALLBACKDM,
          0.001
        );
        setCookie(
          process.env.REACT_APP_NAME + "_orderidMessage",
          response.data.CALLBACKRESPONSE,
          0.001
        );
        setCookie(
          process.env.REACT_APP_NAME + "_orderUser",
          response.data.FIRSTNAME,
          0.001
        );
        setCookie(
          process.env.REACT_APP_NAME + "_orderEmail",
          response.data.EMAIL,
          0.001
        );
        setCookie(process.env.REACT_APP_NAME + "_DFP_session_id", null, 0.001);
        tryout = 60;
        //router.push('donacion-realizada')
        if (response.data.CALLBACKDM === "ACCEPT") {
          navigate("/donacion-realizada#success");
        } else {
          navigate("/error-donacion#error");
        }

        clearInterval(intervalId);
      }
    } else {
      clearInterval(intervalId);
    }
  };

  const handleChange = (event) => {
    if (event.target.value === "") {
      setAmount(event.target.value);
    } else {
      if (/^[0-9]+$/.test(event.target.value)) {
        setAmount(event.target.value);
      }
    }
  };

  const callbackHandleChange = (field, value, postAction) => {
    if (field == "paymenttypecode") {
      if (value == "qr") {
        setNoButton(true);
      } else {
        setNoButton(false);
      }
    }
  };

  const loadData = async (field, value, postAction) => {
    let data = {
      languageid: "es-es",
    };

    var formpersonaldata;
    var formpaymentdata;
    /*
        var response  = await simplePostData(EndPoint.getPaymentForm, data);
        if (response.status){
            formpersonaldata = response.data[0].formpersonaldata
            formpaymentdata  = response.data[0].formpaymentdata
        }
        */

    formpersonaldata = Utils.datos_personales;
    formpaymentdata = Utils.datos_pago;

    data = {
      status: true,
      tmpOrderField: tmpOrder,
    };
    //setPaymentInfo(data);

    let initialFormData = {
      amount: 0,
      shippingcost: 0,
      discount: 0,
      customerid: -1,
      phonecode: "+591",
      addressinfo: "",
      insert_user: "guest",
      cityid: 0,
      products: [],
    };
    setFormData(initialFormData);

    let userInfo = {
      //birthday: defDate
    };

    if (process.env.REACT_APP_dev_yvs == "YES") {
      userInfo = {
        firstname: "yam",
        lastname: "vas",
        phonenumber: "62468400",
        email: "yambert.vasquez@gmail.com",
        invoicename: "yam",
        invoicenumber: "22222",
        dni: "5958217",
        cardnumber: "4111111111111111",
        expiremonth: "12",
        expireyear: "2024",
        cvv: "123",
        //birthday: defDate
      };
    }

    if (formpersonaldata) {
      const loadForm = async () => {
        let formData = await prepareForm(formpersonaldata);
        //preloadForm(formData)

        formData = mergeFormData(formData, userInfo);
        let validationSchema = createValidationSchema(formData);
        let initialValues = {};
        formData.map(function (key, item) {
          initialValues[key.name] = key.value;
        });

        setPersonaldata({
          data: formData,
          /*formValidation : validationSchema,*/ formValues: initialValues,
        });
      };
      loadForm();
    }

    if (formpaymentdata) {
      const loadForm2 = async () => {
        let formData = await prepareForm(formpaymentdata);

        formData = mergeFormData(formData, userInfo);

        let validationSchema = createValidationSchema(formData);
        let initialValues = {};
        formData.map(function (key, item) {
          initialValues[key.name] = key.value;
        });
        setPaymentdata({
          data: formData,
          /*formValidation : validationSchema,*/ formValues: initialValues,
        });
      };
      if (process.env.REACT_APP_URL_CONFIG === "https://www.unicef.org.bo") {
        window.fbq("track", "AddPaymentInfo");
        window.gtag("event", "add_payment_info", {
          currency: "BOB",
          value: amount,
          payment_type: "Credit Card",
          items: [
            {
              item_id: "SKU_DONACION",
              item_name: "Donación de " + amount + "Bs",
              price: amount,
              quantity: 1,
            },
          ],
        });
      } else {
        console.log("NO TAGS dev mode");
      }
      loadForm2();
    }
  };

  const [tmpOrder, setTmpOrder] = React.useState({});
  //router.push('/about')
  let oldAmount = 0;
  let { newamount } = useParams();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    if (amount && amount > 0 && amount !== oldAmount) {
      oldAmount = amount;
      if (process.env.REACT_APP_URL_CONFIG === "https://www.unicef.org.bo") {
        window.fbq("track", "InitiateCheckout");
        window.gtag("event", "generate_lead", {
          currency: "BOB",
          value: amount,
        });
      } else {
        console.log("NO TAGS dev mode", amount, oldAmount);
      }
    }
  }, [amount]);

  useEffect(() => {
    let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source");
    //step1
    let stepValues = {
      amount: parseInt(amount),
      step: "init-donation",
      utm_source: utm_source,
    };
    simplePutData(EndPoint.setStep, stepValues);
    if (newamount) {
      if (isNaN(newamount)) {
        navigate("/");
      } else {
        if (newamount < 60) {
          navigate("/");
        }
      }
      setAmount(newamount);
    }

    loadData();

    var _tmpOrder = getCookie(process.env.REACT_APP_NAME + "_DFP_session_id");
    if (!_tmpOrder) {
      try {
        window.reload();
      } catch (exc) {}
    }
    setTmpOrder(_tmpOrder);
  }, []);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleClose}>
          ATENCI&Oacute;N
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Estimado cliente:</Typography>
          <Typography gutterBottom>{errMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Entendido
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <section className="mt-10 mb-10">
        <div className="container">
          <div className="row flex-center">
            <div className="col-md-12 col-sm-12 col-lg-10 col-xl-8 mt-2">
              <div style={{ minHeight: wH - 150 }} className="order_review">
                <Typography
                  sx={{ ml: { xs: 0, lg: 4 } }}
                  mt={3}
                  mb={1}
                  style={{ fontSize: "1rem" }}
                >
                  HAR&Aacute;S UNA DONACI&Oacute;N{" "}
                  <span style={{ color: "#009ce0", fontWeight: "bold" }}>
                    MENSUAL
                  </span>{" "}
                  DE:{" "}
                  {newamount ? (
                    <span style={{ color: "#009ce0", fontWeight: "bold" }}>
                      {"Bs " + newamount}
                    </span>
                  ) : (
                    ""
                  )}
                </Typography>

                {showCyberFrame ? (
                  <iframe
                    width={370}
                    height={650}
                    style={{ borderStyle: "none" }}
                    src={
                      process.env.REACT_APP_API_CONFIG +
                      "/public/payments/getPaymentCyber/" +
                      paymentToken
                    }
                  ></iframe>
                ) : (
                  <>
                    {!newamount && (
                      <Container>
                        <Grid container spacing={1} mt={4} mb={6}>
                          <Grid item lg={3} xs={6}>
                            <ColorButton
                              style={{
                                textTransform: "capitalize",
                                backgroundColor:
                                  amount === 120 ? "#f26a21" : "#ffff00",
                              }}
                              onClick={() => {
                                setAmount(120);
                                setShowAmount(false);
                              }}
                              variant="contained"
                            >
                              Bs 120
                            </ColorButton>
                          </Grid>

                          <Grid item lg={3} xs={6}>
                            <ColorButton
                              style={{
                                textTransform: "capitalize",
                                backgroundColor:
                                  amount === 90 ? "#f26a21" : "#ffff00",
                              }}
                              onClick={() => {
                                setAmount(90);
                                setShowAmount(false);
                              }}
                              variant="contained"
                            >
                              Bs 90
                            </ColorButton>
                          </Grid>
                          <Grid item lg={3} xs={6}>
                            <ColorButton
                              style={{
                                textTransform: "capitalize",
                                backgroundColor:
                                  amount === 60 ? "#f26a21" : "#ffff00",
                              }}
                              onClick={() => {
                                setAmount(60);
                                setShowAmount(false);
                              }}
                              variant="contained"
                            >
                              Bs 60
                            </ColorButton>
                          </Grid>
                          <Grid item lg={3} xs={6}>
                            <ColorButton
                              style={{
                                textTransform: "capitalize",
                                backgroundColor:
                                  amount !== 120 &&
                                  amount !== 90 &&
                                  amount !== 60
                                    ? "#f26a21"
                                    : "#ffff00",
                              }}
                              onClick={() => {
                                setAmount(60);
                                setShowAmount(true);
                              }}
                              variant="contained"
                            >
                              Otro
                            </ColorButton>
                          </Grid>
                          {showAmount && (
                            <Grid item lg={12} xs={12} mt={4}>
                              <TextField
                                fullWidth
                                label={
                                  "Monto a Donar (Expresado en Bolivianos)"
                                }
                                value={amount}
                                onChange={handleChange}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Container>
                    )}
                    {personaldata.data?.length > 0 && (
                      <>
                        {personaldataSucc ? (
                          ""
                        ) : (
                          <GenericForm
                            action={confirmPersonalData}
                            action2={() => {
                              navigate("/");
                            }}
                            allDisabled={false}
                            buttonClassName="btn-success"
                            buttonLabel={"Siguiente"}
                            buttonLabel2={defConfig[defLang].backlabel}
                            componentName={"personalData"}
                            formData={personaldata}
                            id={1}
                            isPersonalDataForm
                            outlineBackButton
                          />
                        )}
                      </>
                    )}

                    {personaldataSucc && paymentdata.data?.length > 0 && (
                      <>
                        <GenericForm
                          loading={loading}
                          callbackHandleChange={callbackHandleChange}
                          buttonLabel={
                            noButton ? "NOBUTTON" : defConfig[defLang].paylabel
                          }
                          buttonLoadingLabel={
                            noButton
                              ? "NOBUTTON"
                              : defConfig[defLang].validatingpaymentlabel
                          }
                          action={confirmPayment}
                          buttonLabel2={defConfig[defLang].backlabel}
                          action2={() => {
                            setPersonaldataSucc(false);
                          }}
                          addAcceptTerms={true}
                          checkedP={checkedP}
                          handleChangeP={handleChangeP}
                          buttonwidth={fullScreen2 ? "100%" : "initial"}
                          buttonwidth2={fullScreen2 ? "100%" : "initial"}
                          formData={paymentdata}
                          inheritedformData={formData}
                          componentName={"checkout"}
                          id={1}
                          isVisaForm
                          outlineBackButton
                          isSecondForm
                        />
                      </>
                    )}
                    <span> {error} </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <form
        id="formCyber"
        action={process.env.REACT_APP_cyberPostUrl}
        method="post"
      >
        <input
          type={fieldType}
          name="access_key"
          value={finalData.access_key}
        />
        <input
          type={fieldType}
          name="profile_id"
          value={finalData.profile_id}
        />
        <input
          type={fieldType}
          name="transaction_uuid"
          value={finalData.transaction_uuid}
        />
        <input
          type={fieldType}
          name="signed_field_names"
          value={finalData.signed_field_names}
        />
        <input
          type={fieldType}
          name="unsigned_field_names"
          value={finalData.unsigned_field_names}
        />
        <input
          type={fieldType}
          name="signed_date_time"
          value={finalData.signed_date_time}
        />
        <input type={fieldType} name="locale" value={finalData.locale} />
        <input
          type={fieldType}
          name="merchant_defined_data1"
          value={finalData.merchant_defined_data1}
        />
        <input
          type={fieldType}
          name="merchant_defined_data2"
          value={finalData.merchant_defined_data2}
        />
        <input
          type={fieldType}
          name="merchant_defined_data6"
          value={finalData.merchant_defined_data6}
        />
        <input
          type={fieldType}
          name="merchant_defined_data7"
          value={finalData.merchant_defined_data7}
        />
        <input
          type={fieldType}
          name="merchant_defined_data9"
          value={finalData.merchant_defined_data9}
        />
        <input
          type={fieldType}
          name="merchant_defined_data10"
          value={finalData.merchant_defined_data10}
        />
        <input
          type={fieldType}
          name="merchant_defined_data11"
          value={finalData.merchant_defined_data11}
        />
        <input
          type={fieldType}
          name="merchant_defined_data12"
          value={finalData.merchant_defined_data12}
        />
        <input
          type={fieldType}
          name="merchant_defined_data14"
          value={finalData.merchant_defined_data14}
        />
        <input
          type={fieldType}
          name="merchant_defined_data15"
          value={finalData.merchant_defined_data15}
        />
        <input
          type={fieldType}
          name="merchant_defined_data17"
          value={finalData.merchant_defined_data17}
        />
        <input
          type={fieldType}
          name="merchant_defined_data18"
          value={finalData.merchant_defined_data18}
        />
        <input
          type={fieldType}
          name="merchant_defined_data19"
          value={finalData.merchant_defined_data19}
        />
        <input
          type={fieldType}
          name="merchant_defined_data87"
          value={finalData.merchant_defined_data87}
        />
        <input
          type={fieldType}
          name="merchant_defined_data88"
          value={finalData.merchant_defined_data88}
        />
        <input
          type={fieldType}
          name="merchant_defined_data90"
          value={finalData.merchant_defined_data90}
        />
        <input
          type={fieldType}
          name="merchant_defined_data91"
          value={finalData.merchant_defined_data91}
        />
        <input
          type={fieldType}
          name="device_fingerprint_id"
          value={finalData.device_fingerprint_id}
        />
        <input
          type={fieldType}
          name="transaction_type"
          value={finalData.transaction_type}
        />
        <input
          type={fieldType}
          name="reference_number"
          value={finalData.reference_number}
        />
        <input
          type={fieldType}
          name="amount"
          id="amount"
          value={finalData.amount}
        />
        <input
          type={fieldType}
          name="currency"
          id="currency"
          value={finalData.currency}
        />
        <input
          type={fieldType}
          name="payment_method"
          value={finalData.payment_method}
        />
        <input
          type={fieldType}
          name="bill_to_email"
          value={finalData.bill_to_email}
        />
        <input
          type={fieldType}
          name="bill_to_phone"
          value={finalData.bill_to_phone}
        />
        <input
          type={fieldType}
          name="bill_to_address_line1"
          value={finalData.bill_to_address_line1}
        />
        <input
          type={fieldType}
          name="bill_to_address_city"
          value={finalData.bill_to_address_city}
        />
        <input
          type={fieldType}
          name="bill_to_address_state"
          value={finalData.bill_to_address_state}
        />
        <input
          type={fieldType}
          name="bill_to_address_country"
          value={finalData.bill_to_address_country}
        />
        <input
          type={fieldType}
          name="bill_to_address_postal_code"
          value={finalData.bill_to_address_postal_code}
        />

        <input
          type={fieldType}
          name="override_custom_receipt_page"
          value={finalData.override_custom_receipt_page}
        />
        <input
          type={fieldType}
          name="override_backoffice_post_url"
          value={finalData.override_backoffice_post_url}
        />
        <input type={fieldType} name="card_type" value={finalData.card_type} />
        <input
          type={fieldType}
          name="bill_to_forename"
          value={finalData.bill_to_forename}
        />
        <input
          type={fieldType}
          name="bill_to_surname"
          value={finalData.bill_to_surname}
        />
        <input
          type={fieldType}
          name="card_number"
          value={formData.cardnumber}
        />
        <input
          type={fieldType}
          name="card_expiry_date"
          value={formData.expiremonth + "-" + formData.expireyear}
        />
        <input type={fieldType} name="card_cvn" value={formData.cvv} />
        <input type={fieldType} name="signature" value={signature} />
      </form>
    </>
  );
};

CheckOut.propTypes = {};

export default CheckOut;
