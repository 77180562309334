import React, {useEffect} from "react";
import CardContainer from "../components/elements/CardContainer";
import {useNavigate} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

const Nosotros = (props) => {


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const bodyInfo1 = [


        {
            "id": 0,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",

                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {position: "relative", top: "-137px", left: "75px", fontSize: "3rem"},
                    "link": "/donar",
                    "image": "",
                    "type": "text",

                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/nosotros/NFGGFDF.jpg "
                }
            ]
        },


        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "NUESTRO EQUIPO",
                    titlestyle: {
                        color: "#fff",
                        fontSize: fullScreen ? "2rem" : "3rem",
                        marginTop: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: fullScreen ? "7.5rem" : "7.5rem",
                        background: "#00b0f0",
                        lineHeight: 1.1,
                        textAlign: "center"

                    },

                    "updown": "JUSTTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

            ]
        },

        {
            "id": 2,
            "full": "true",
            "childs": [

                {
                    "id": 1,
                    "size": "",
                    "subtitle": "BUSCAMOS A HÉROES Y HEROÍNAS DE LA NIÑEZ",
                    "subtitlestyle": {

                        fontSize: "2rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "4rem",
                        marginBottom: "4rem",
                        lineHeight: 1.1,
                        textAlign: "center"
                    },

                    "updown": "JUSTSUBTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",

                    "link": "/donar",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                }
            ]
        },

        {
            "id": 3,
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "subtitle": "Tu poder es proteger a la niñez",
                    "subtitlestyle": {


                        color: "#fff",
                        fontSize: "1.75rem",
                        marginTop: "1px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: fullScreen ? "6rem" : "4rem",
                        borderRadius: "4px",
                        marginBottom: "4rem",
                        background: "#80bd41",
                        lineHeight: 1.1,
                        textAlign: "center"

                    },
                    "button": "",
                    "buttonlink": "",
                    "image": "",
                    "type": "text",
                    "imagepath": "",
                    "link": "",
                    "updown": "JUSTSUBTITLE",
                    "body": ""
                }
            ]
        },

        {
            "id": 4,
            "childs": [
                {
                    "id": 2,
                    "size": "",
                    "title": "",

                    "button": "",
                    "buttonlink": "",
                    "image": "",
                    "type": "text",
                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/nosotros/equipo1.png",
                    "subtitle": "",
                    "updown": "JUSTIMAGE", //JUSTVIDEO
                    "body": "",
                    "link": "/login",
                    "imagestyle": {marginBottom: 15, borderRadius: 15}
                },
                {
                    "id": 0,
                    "size": "",
                    "title": "",
                    titlestyle: {color: "#f26a21"},
                    buttonstyle: {},
                    "subtitle": "",
                    subtitlestyle: {color: "#f26a21"},
                    "button": "",
                    "buttonlink": "/",
                    "image": "",
                    "type": "text",
                    "imagepath": "",
                    "link": "/",
                    "updown": "JUSTBODY",
                    "style": {
                        display: "flex",
                        alignItems: "center",
                        textAlign: "justify",
                        paddingLeft: fullScreen ? 0 : 46,
                        justifyContent: "start"
                    },
                    "bodystyle": {fontSize: "1rem", textAlign: "justify"},
                    cwidth: fullScreen ? "100%" : "90%",
                    "body": "Todos tuvimos en algún momento de nuestras vidas un héroe o heroína al que admiramos, ya sea un personaje con armadura o alguien que tenía el poder de controlar las fuerzas de la naturaleza, pero te has preguntado ¿Qué características debe tener alguien para que sea considerado un héroe o heroína? ¿Qué tienen en común todos (as) ellos (as)? “Un (a) héroe/heroína es la persona que tiene la capacidad de tomar una decisión” (Stan Lee), la decisión de actuar cuando el mundo más los necesita. “Los héroes nos muestran que no necesitas ser perfecto para hacer lo correcto, no se trata de ser poderoso, sino de enfrentar la injusticia y encontrar tu llamado”. Y es justamente que bajo este concepto UNICEF Bolivia ha iniciado la búsqueda de héroes y heroínas de la niñez, personas que deciden actuar porque las niñas y niños de Bolivia los necesitan. "
                },


            ]
        },


        {
            "id": 5,
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "subtitle": "¿Te has encontrado con alguno de nuestros captadores o has recibido llamadas de UNICEF?",
                    "subtitlestyle": {


                        color: "#fff",
                        fontSize: "1.75rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: fullScreen ? "10rem" : "5rem",
                        borderRadius: "4px",
                        marginBottom: "4rem",
                        marginTop: "3rem",
                        background: "rgb(98, 65, 189)",
                        lineHeight: "1.25",
                        textAlign: "center"

                    },
                    "button": "",
                    "buttonlink": "",
                    "image": "",
                    "type": "text",
                    "imagepath": "",
                    "link": "",
                    "updown": "JUSTSUBTITLE",
                    "body": ""
                }
            ]
        },

        {
            "id": 6,
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",
                    titlestyle: {color: "#f26a21"},
                    buttonstyle: {},
                    "subtitle": "",
                    subtitlestyle: {color: "#f26a21"},
                    "button": "",
                    "buttonlink": "/",
                    "image": "",
                    "type": "text",
                    "imagepath": "",
                    "link": "/",
                    "updown": "JUSTBODY",
                    "style": {
                        display: "flex",
                        alignItems: "center",
                        textAlign: "justify",
                        paddingRight: fullScreen ? 0 : 46,
                        justifyContent: "end"
                    },
                    cwidth: fullScreen ? "100%" : "69%",
                    "bodystyle": {
                        fontSize: "1.15rem",
                        lineHeight: "1.75rem"
                    },
                    "body": "Aquí puedes saber si la persona que te abordó en la calle, en tu lugar de trabajo, o te contactó vía telefónica está autorizada por UNICEF para llevar a cabo esta labor, y tengas total tranquilad al darle tus datos para convertirte en un héroe o heroína de la niñez."
                },
                {
                    "id": 2,
                    "size": "",
                    "title": "",

                    "button": "",
                    "buttonlink": "",
                    "image": "",
                    "type": "text",
                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/nosotros/equipo2.jpg",
                    "subtitle": "",
                    "updown": "JUSTIMAGE", //JUSTVIDEO
                    "body": "",
                    "imagestyle": {borderRadius: 15},
                    "link": "/login",


                },

            ]
        },

        {
            "id": 7,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "Nuestro Equipo de Captadores",
                    titlestyle: {
                        background: "#f26a21",
                        color: "#fff", textAlign: "center",
                        fontSize: "2.5rem",
                        height: fullScreen ? "9.5rem" : "7.5rem",
                        lineHeight: "1.35",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "5rem",
                        marginBottom: "4rem",
                    },

                    "updown": "JUSTTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",


                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

            ]
        },

        {
            "id": 8,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "LA PAZ",
                    titlestyle: {

                        color: "#000", textAlign: "center",
                        fontSize: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "1rem",
                        marginBottom: "4rem",
                    },

                    "updown": "JUSTTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",


                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

            ]
        },
        {
            "id": 9, //LPZ
            "childs": []
        },
        {
            "id": 10,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",
                    titlestyle: {
                        color: "#000", textAlign: "center",
                        fontSize: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "4rem",
                        marginBottom: "4rem",
                    },

                    "updown": "JUSTTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",


                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

            ]
        },
        {
            "id": 11, //CBA

            "childs": []
        },
        {
            "id": 12,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "SANTA CRUZ",
                    titlestyle: {
                        color: "#000", textAlign: "center",
                        fontSize: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "4rem",
                        marginBottom: "4rem",
                    },

                    "updown": "JUSTTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",


                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

            ]
        },
        {
            "id": 13, //STC
            "childs": []
        },

        {
            "id": 14,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "NUESTROS OPERADORES",
                    titlestyle: {
                        background: "#f26a21",
                        color: "#fff", textAlign: "center",
                        fontSize: "2.5rem",
                        height: fullScreen ? "9.5rem" : "7.5rem",
                        lineHeight: "1.35",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "5rem",
                        marginBottom: "4rem",
                    },

                    "updown": "JUSTTITLE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",


                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

            ]
        },


        {
            "id": 15, //OTROS
            "childs": []
        },

        {
            "id": 16,
            "full": "true",
            "childs": [
                {
                    "id": "donaahora",
                    "size": "",
                    "title": "",
                    buttonstyle: {textAlign: "center", position: "relative", marginTop: "2.5rem"},
                    "style": {textAlign: "center"},
                    "button": "DONA AHORA",
                    "buttonlink": "#donation-form",
                    "image": "",
                    "type": "text",
                    "imagepath": "",
                    "link": "#donation-form",
                    "subtitle": "",
                    "updown": "IMAGEDOWN",
                    "body": ""
                }
            ]
        },

    ]

    const LPZ = [
        {name: "Carol Lescano Camacho", img: "Carlos Lescano Camacho ci 48403557.png", ci: "CI 48403557"},
        {name: "Leonardo De Las Casas Alosilla", img: "LEONARDO DE LAS CASAS ALOSILLA    ci 74876665.png", ci: "CI 74876665"},
        {name: "Marta Luisa Lopez Valero", img: "MARTA LUISA LOPEZ VALERO  CI 811826.png", ci: "CI 811826"},
        {name: "Piero Oviedo Rojas", img: "PIERO OVIEDO ROJAS  ci 72692617.png", ci: "CI 72692617"},
    ]

    const CBA = [


        /*{name: "Carolina Arteaga Ayala", img: "Carolina Arteaga Ayala.jpg", ci: "CI 4401407 CB"},
        {name: "Eliana Raquel Veliz Vidrio", img: "Eliana Veliz Vidrio.jpg", ci: "CI 5928982 CB"},
        {name: "Hibling Elena Osinaga Camacho", img: "Hibling Osinaga Camacho.jpg", ci: "CI 3726245 CB"},
        {name: "Patricia Gumucio Quiroga", img: "Patricia Gumucio Quiroga.jpg", ci: "CI 4430284 CB"},

        {name: "Soledad Valenzuela Loma", img: "Soledad  Valenzuela Loma.jpg", ci: "CI 4467305 CB"},
        {name: "Luis Rada Ramirez", img: "Luis Rada.jpeg", ci: "CI 6857326"},
        {name: "Patricia Araoz Aguirre", img: "Patricia Araoz.jpeg", ci: "CI 5059205"},
        {name: "Pamela Muñoz", img: "Pamela Muñoz.jpeg", ci: "CI 5283748"},

        {name: "Maria Alejandra Caso", img: "Maria Alejandra Caso.jpeg", ci: "CI 1871025"},*/

    ]

    const STC = [

        {name: "Claudia Lopez", img: "Claudia Lopez.png", ci: "CI 6167687"},
        {name: "Eliana Raquel Veliz Cossio", img: "Eliana Raquel Veliz Cossio.png", ci: "CI 5928982"},
        {name: "Elizabeth Mosciaro", img: "Elizabeth Mosciaro.png", ci: "CI 3881280"},
        {name: "Marcela Cruz Tellez", img: "Marcela Cruz Tellez.png", ci: "CI 12656534"},

        {name: "Mariela Vargas", img: "Mariela Vargas.png", ci: "CI 10804651"},
        {name: "María Nela Paredes Salvatierra", img: "María Nela Paredes Salvatierra.png", ci: "CI 9744110"},
        {name: "Milenca Rubi Villa Zurita", img: "Milenca Rubi Villa Zurita.png", ci: "CI 4635993"},
        {name: "Nataly Johana Delgadillo Reyes", img: "Nataly Johana Delgadillo Reyes.png", ci: ""},

        {name: "Oscar Daniel Paredes Salvatierra", img: "Oscar Daniel Paredes Salvatierra.png", ci: "CI 8165565"},
        {name: "Vania Raquel Arze Cuellar", img: "Vania Raquel Arze Cuellar.png", ci: "CI 9832628"},
        {name: "Vivian Reneé Zeballos Chávez", img: "Vivian Reneé Zeballos Chávez.png", ci: "CI 3699011"},
        {name: "Zulema Vidaurre Orozco", img: "Zulema Vidaurre Orozco.png", ci: "CI 3820680"},


    ]

    const OPR = [
        {name: "Beatriz Hurtado", img: "BEATRIZ HURTADO.jpeg", ci: "8185733 SC"},
        {name: "Nathaly Giorgetty Pittary", img: "Nathaly Giorgetty Pittary CI 6258856 SC.jpeg", ci: "CI 6258856 SC"},
        {name: "Natalia Carvalho", img: "NATALIA CARVALHO.jpeg", ci: "CI 11383885 SC"},
        {name: "Thalia Arauco Morales", img: "THALIA ARAUCO.jpeg", ci: "CI 11335418 SC"},
        {name: "Claudia Ligia Dorado Velasquez", img: "Claudia Ligia Dorado Velasquez.png", ci: "CI 9727277"},
        {name: "Gustavo Romero", img: "GUSTAVOS.png", ci: "CI 9903520"},
        {name: "Katterin Daniela Flores Mercado", img: "Katterin Daniela Flores Mercado.png", ci: "CI 11384363"},
        {name: "Leonardo Arauz Pinto", img: "Leonardo Arauz Pinto.png", ci: "CI 8252713"},
        {name: "Mariela Alejandra Para", img: "Mariela Alejandra Para.png", ci: "CI 7679779"},
        {name: "Sara Cabrera", img: "SARA CABRERA.png", ci: "CI 9903520"},
        {name: "Vania Lorena Cuellar Moreno", img: "Vania Lorena Cuellar Moreno.png", ci: "CI 5896884"},
        {name: "Ángela Carrasco", img: "ÁNGELA CARRASCO.png", ci: "CI 8363364"},

    ]


    const navigate = useNavigate();
    const goHome = (event) => {
        navigate("/")
        window.scrollTo(0, 0)
    };


    for (let i in LPZ) {
        let data = LPZ[i]
        bodyInfo1[9].childs.push(
            {
                "id": 0,
                "size": " col-md-12 col-sm-12 col-12 col-lg-3 ",
                "updown": "IMAGEUP",
                "body": data.name,
                bodystyle: {marginTop: 0, textAlign: "center"},
                "button": "",
                "buttonlink": "",
                "buttonstyle": {},
                "link": "",
                "image": "",
                "type": "text",
                muted: data.ci,
                mutedstyle: {color: "#f26a21", fontSize: 15, fontWeight: "bold", textAlign: "center"},
                "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/nosotros/LPZ/" + data.img,
            }
        )
    }

    for (let i in CBA) {
        let data = CBA[i]
        bodyInfo1[11].childs.push(
            {
                "id": 0,
                "size": " col-md-12 col-sm-12 col-12 col-lg-3 ",
                "updown": "IMAGEUP",
                "body": data.name,
                bodystyle: {marginTop: 0, textAlign: "center"},
                "button": "",
                "buttonlink": "",
                "buttonstyle": {},
                "link": "",
                "image": "",
                "type": "text",
                muted: data.ci,
                mutedstyle: {color: "#f26a21", fontSize: 15, fontWeight: "bold", textAlign: "center"},
                "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/nosotros/CBA/" + data.img,
            }
        )
    }

    for (let i in STC) {
        let data = STC[i]
        bodyInfo1[13].childs.push(
            {
                "id": 0,
                "size": " col-md-12 col-sm-12 col-12 col-lg-3 ",
                "updown": "IMAGEUP",
                "body": data.name,
                bodystyle: {marginTop: 0, textAlign: "center"},
                "button": "",
                "buttonlink": "",
                "buttonstyle": {},
                "link": "",
                "image": "",
                "type": "text",
                muted: data.ci,
                mutedstyle: {color: "#f26a21", fontSize: 15, fontWeight: "bold", textAlign: "center"},
                "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/nosotros/STC/" + data.img,
            }
        )
    }

    for (let i in OPR) {
        let data = OPR[i]
        bodyInfo1[15].childs.push(
            {
                "id": 0,
                "size": " col-md-12 col-sm-12 col-12 col-lg-3 ",
                "updown": "IMAGEUP",
                "body": data.name,
                bodystyle: {marginTop: 0, textAlign: "center"},
                "button": "",
                "buttonlink": "",
                "buttonstyle": {},
                "link": "",
                "image": "",
                "type": "text",
                muted: data.ci,
                mutedstyle: {color: "#f26a21", fontSize: 15, fontWeight: "bold", textAlign: "center"},
                "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/nosotros/OPR/" + data.img,
            }
        )
    }

    useEffect(() => {


    }, [])

    return (
        <>

            <section className="home-slider position-relative pb-60" style={{background: "#f9f9f9"}}>
                <CardContainer key={"card1"} data={bodyInfo1} config={{}} goHome={goHome}/>
            </section>


        </>
    );
}
Nosotros.propTypes = {};

export default Nosotros;
