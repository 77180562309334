import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import {Link} from "react-router-dom";
import {Grid, Typography} from '@mui/material';
import {getImagePath} from "../../utils/Functions";
import {useSelector} from "react-redux";

const SimpleSlider = ({name, data, config}) => {

    const {defConfig, params, defLang,} = useSelector((state) => state.Login);
    return (data.length == 0 ? "" :
            <>
                <Swiper
                    modules={[Navigation]}

                    slidesPerView={config?.cols ? config.cols : 1}
                    spaceBetween={0}
                    loop={true}

                    pagination={{clickable: false}}
                    scrollbar={{draggable: false}}


                    navigation={{
                        prevEl: ".custom_prev_i1" + name,
                        nextEl: ".custom_next_i1" + name,
                    }}

                    className="hero-slider-1 dot-style-1 dot-style-1-position-1"
                >
                    {data.map((item, key) => {
                        return (
                            <SwiperSlide key={"ss1" + key}>
                                <div key={"ss2" + key} className="single-hero-slider single-animation-wrap">
                                    <div key={"ss3" + key} className="container">
                                        <div key={"ss3" + key}
                                             className="row align-items-center slider-animated-1 flex-center">
                                            {item.childs.map((item2, key2) => {
                                                if (item2.type == "multiple") {
                                                    return (
                                                        <>
                                                            <div key={"ss4" + key + "_10" + key2}
                                                                 className={"111 col-12 col-sm-12 " + (item2.customcol ? "col-lg-8" : "col-lg-8")}>
                                                                <div key={"ss4" + key + "_11" + key2}
                                                                     className="   single-slider-img single-slider-img-1"
                                                                     style={{
                                                                         height: item2.customcol ? 350 : 550
                                                                     }}>

                                                                    <img
                                                                        key={"ss4" + key + "_11" + key2}
                                                                        className="animated slider-1-1"
                                                                        src={getImagePath(item2.imagepath)}
                                                                        style={{
                                                                            objectPosition: item2.objectPosition ? item2.objectPosition : "center",
                                                                            height: item2.customcol ? 350 : 550
                                                                        }}/>

                                                                </div>
                                                            </div>

                                                            <div key={"ss4" + key + "_1" + key2} style={{
                                                                textAlign: item2.customcol ? "left" : "right",

                                                            }}
                                                                 className={"col-12 col-sm-12 " + (item2.customcol ? "col-lg-8" : "col-lg-4-c")}>
                                                                <div key={"ss4" + key + "_2" + key2}
                                                                     className="hero-slider-content-2">
                                                                    {item2.title != "" &&
                                                                        <Typography
                                                                            style={{textAlign: item2.customcol ? "center" : "auto"}}
                                                                            key={"ss4" + key + "_3" + key2}
                                                                            variant={item2.customcol ? "h2" : "h6"}>
                                                                            {item2.title}
                                                                        </Typography>
                                                                    }
                                                                    {item2.subtitle != "" &&
                                                                        <Typography
                                                                            style={item2.subtitlestyle ? item2.subtitlestyle : {}}
                                                                            className=" font-700"
                                                                            key={"ss4" + key + "_4" + key2}
                                                                            variant="h1">
                                                                            {item2.subtitle}
                                                                        </Typography>
                                                                    }
                                                                    {item2.body != "" &&
                                                                        <Typography
                                                                            style={{textAlign: item2.customcol ? "center" : "auto"}}
                                                                            key={"ss4" + key + "_5" + key2}
                                                                            variant={item2.customcol ? "h4" : "body"}>
                                                                            {item2.body}
                                                                        </Typography>
                                                                    }
                                                                    {item2.button != "" &&
                                                                        <Grid key={"ss4" + key + "_6" + key2} item sx={{
                                                                            position: "absolute",
                                                                            bottom: {xs: 39, lg: 49}
                                                                        }}>
                                                                            <Link className="animated"
                                                                                  key={"ss4" + key + "_7" + key2}
                                                                                  href={item.link ? item.link : "#"}>

                                                                                <Typography
                                                                                    key={"ss4" + key + "_9" + key2}
                                                                                    style={{
                                                                                        background: item2.background ? item2.background : defConfig?.primary?.background,
                                                                                        color: item2.color ? item2.color : defConfig?.primary?.color,
                                                                                        padding: item2.padding ? item2.padding : 10,
                                                                                        borderRadius: item2.borderradius ? item2.borderradius : 6,
                                                                                        minWidth: 100,
                                                                                        textAlign: "center",
                                                                                    }} variant="button">
                                                                                    {item2.button}
                                                                                </Typography>

                                                                            </Link>
                                                                        </Grid>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                } else if (item2.type == "text") {
                                                    return (
                                                        <div key={"ss4" + key + "_1" + key2}
                                                             className={"222 col-" + 12 / item.childs.length}
                                                             md={12 / item.childs.length} lg={12 / item.childs.length}>
                                                            <div key={"ss4" + key + "_2" + key2}
                                                                 className="hero-slider-content-2">
                                                                {item2.title != "" &&
                                                                    <Typography key={"ss4" + key + "_3" + key2}
                                                                                variant="h3">
                                                                        {item2.title}
                                                                    </Typography>
                                                                }
                                                                {item2.subtitle != "" &&
                                                                    <Typography key={"ss4" + key + "_4" + key2}
                                                                                variant="h4">
                                                                        {item2.subtitle}
                                                                    </Typography>
                                                                }
                                                                {item2.body != "" &&
                                                                    <Typography key={"ss4" + key + "_5" + key2}
                                                                                variant="body">
                                                                        {item2.body}
                                                                    </Typography>
                                                                }
                                                                {item2.button != "" &&
                                                                    <Grid key={"ss4" + key + "_6" + key2} item sx={{
                                                                        position: "absolute",
                                                                        bottom: {xs: 39, lg: 49}
                                                                    }}>
                                                                        <Link className="animated"
                                                                              key={"ss4" + key + "_7" + key2}
                                                                              href={item.link ? item.link : "#"}>

                                                                            <Typography
                                                                                key={"ss4" + key + "_9" + key2}
                                                                                style={{
                                                                                    background: item2.background ? item2.background : defConfig?.primary?.background,
                                                                                    color: item2.color ? item2.color : defConfig?.primary?.color,
                                                                                    padding: item2.padding ? item2.padding : 10,
                                                                                    borderRadius: item2.borderradius ? item2.borderradius : 6,
                                                                                    minWidth: 100,
                                                                                    textAlign: "center"
                                                                                }} variant="button">
                                                                                {item2.button}
                                                                            </Typography>

                                                                        </Link>
                                                                    </Grid>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div key={"ss4" + key + "_10" + key2}
                                                             className={"333 col-" + 12 / item.childs.length} xs={12}
                                                             md={12 / item.childs.length} lg={12 / item.childs.length}>
                                                            <div key={"ss4" + key + "_11" + key2}
                                                                 className="auto-zoom-hover  single-slider-img single-slider-img-1">
                                                                <img
                                                                    key={"ss4" + key + "_11" + key2}
                                                                    className="animated slider-1-1"
                                                                    src={getImagePath(item2.imagepath)}
                                                                    style={item2.imagestyle}
                                                                    alt={""}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })}

                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <div className="slider-arrow hero-slider-1-arrow">
      <span className={"slider-btn slider-prev slick-arrow custom_prev_i1" + name}>
        	<i className="fi-rs-angle-left"></i>
        </span>
                    <span className={"slider-btn slider-next slick-arrow custom_next_i1" + name}>
          <i className="fi-rs-angle-right"></i>
        </span>
                </div>
            </>
    );
};
SimpleSlider.propTypes = {};

export default SimpleSlider;
