/* eslint-disable import/no-anonymous-default-export */
export default {
    login: '/auth/login',
    getDataByCodeLanguage: "/params/getDataByCodeLanguage",
    getDataByDomainLanguage: "/params/getDataByDomainLanguage",
    getInitialConf: "/auth/getInitialConf",
    getFiles: "/files/getfilesS3",
    createFileFolder: "/files/create-folderS3",
    uploadFile: "/files/uploadS3",
    deleteFile: "/files/deleteS3",
    getPaymentForm: "/public/components/getPaymentForm",
    generateSimpleToken: "/public/payments/generateSimpleToken",
    createOrder: "/public/orders/createOrder",
    createOrderOneShot: "/public/ordersOneShot/createOrderOneShot",
    checkStatusOrder: "/public/orders/checkStatusOrder",
    getOrderInfo: "/public/orders/getOrderInfo",
    generateSignature: "/public/payments/generateSignature",
    getOrderDetails: "/public/orders/getOrderDetails",
    updatePassword: '/users/update-password',
    setStep: "/public/frontusers/setStep",
    getQR: "/public/pagoQR/getQR",
    verificarQR: "/public/pagoQR/verificarQR",
}
