import React from "react";
/*import ReactDOM from 'react-dom';*/
import {createRoot} from 'react-dom/client'; //new
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/fonts/univers/UniversBold.ttf"
import "./assets/fonts/univers/UniversCnBold.ttf"
import "./assets/fonts/univers/UniversCnRg.ttf"
import "./assets/fonts/univers/UniversLight.ttf"
import "./assets/fonts/univers/UniversRegular.ttf"


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);

/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
