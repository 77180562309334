import React from "react";
import PropTypes from "prop-types";
import {Alert, AlertTitle, Button, Container, Dialog, Grid, Typography} from "@mui/material";

import * as Actions from "../../redux/actions/process.actions";
import {useDispatch, useSelector} from "react-redux";
import {styled} from '@mui/material/styles';

const FixedButton = styled(Button)(({theme}) => ({
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 10
}));

const DeleteItemDialog = (props) => {
    const {open, onClose} = props;
    const dispatch = useDispatch();

    const {defConfig, params, defLang, activeForm} = useSelector((state) => state.Login);

    const {
        componentConfig,
        processSuccess,
        processError,
        requesting,
        processMessage,
        formFields,
        initialValues,
        validationSchema
    } = useSelector(
        (state) => state.Process
    );

    const deleteItem = (event) => {
        console.log("delete")
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs">
            <Container>
                <Grid container sx={{mt: 1, mb: 2}} spacing={1}>
                    <Grid item lg={12} xs={12}>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle color="error" variant="h6"> Advertencia </AlertTitle>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: "bold",
                                    marginTop: 20,
                                    marginBottom: 20
                                }}>
                                {defConfig[defLang].deteledisclaimer}
                            </Typography>
                            <Grid container sx={{mt: 1}} spacing={1}>
                                <Grid item xs={6} md={6} lg={6}
                                      sx={{mt: 1, mb: 1, ml: {xs: 0, md: 0}, mr: {xs: 0, md: 0}}}>
                                    <FixedButton
                                        type="button"
                                        fullWidth
                                        color="error"
                                        variant="outlined"
                                        onClick={() => {
                                            onClose()
                                        }}
                                        sx={{
                                            textTransform: "initial",
                                            fontWeight: {xs: "bold", md: "normal"},
                                            fontSize: {xs: "1rem", md: "0.9rem"}
                                        }}
                                    >
                                        {defConfig[defLang].cancellabel}
                                    </FixedButton>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6}
                                      sx={{mt: 1, mb: 1, ml: {xs: 0, md: 0}, mr: {xs: 0, md: 0}}}>
                                    <FixedButton
                                        type="button"
                                        color="error"
                                        fullWidth
                                        onClick={() => {
                                            dispatch(Actions.processData(props.componentName, "delete", {id: props.id}));
                                            onClose();
                                        }}
                                        variant="contained"
                                        sx={{
                                            textTransform: "initial",
                                            fontWeight: {xs: "bold", md: "normal"},
                                            fontSize: {xs: "1rem", md: "0.9rem"}
                                        }}

                                    >
                                        {defConfig[defLang].deletelabel}
                                    </FixedButton>
                                </Grid>
                            </Grid>
                        </Alert>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
};

DeleteItemDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DeleteItemDialog;
