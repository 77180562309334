import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

//import { useRouter } from 'next/router'
import {getCookie, simplePutData} from "../utils/Functions";
import EndPoint from "../api/Endpoints";
import Preloader from "../components/elements/Preloader";
import {useNavigate, useSearchParams} from 'react-router-dom';

const Payment = (props) => {

    const navigate = useNavigate();

    const [orderInfo, setOrderInfo] = useState("ERROR");
    const [orderMessage, setOrderMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();

    //const router= useRouter()
    const {defConfig, params, defLang} = useSelector((state) => state.Login);
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100);
        var _tmpOrder = getCookie(process.env.REACT_APP_NAME + "_DFP_session_id")
        let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source")
        let stepValues = {
            step: "checkout-finish-NOK",
            utm_source: utm_source,
            sessionid: _tmpOrder
        }
        simplePutData(EndPoint.setStep, stepValues);
        /*


                    let responseOrder = getCookie( process.env.REACT_APP_NAME+"_orderidSucc" )
                    let responseMessage = getCookie( process.env.REACT_APP_NAME+"_orderidMessage" )
                if (!responseOrder){
                    navigate("/");
                }else{
                    setOrderInfo(responseOrder)
                    if (  responseMessage==="Request parameters are invalid or missing"){
                        setOrderMessage("Los datos de la Tarjeta est&aacute;n err&oacute;neos, por favor revise e intente nuevamente.")
                    }else{
                        setOrderMessage(responseMessage)
                    }

                }
        */

    }, []);
    //noBreadcrumb="d-none"
    return (
        loading ?
            <Preloader loadinglabel={defConfig[defLang].finalizingpaymentlabel}/>
            :
            <>

                <section className=" mb-20" style={{marginTop: 250}} id="error">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {orderInfo === "ACCEPT" ?
                                    <div className="single-header mb-10 mt-50">
                                        <h1 className="font-xxl text-center text-brand">
                                            Gracias por tu donaci&oacute;n
                                        </h1>
                                        <h2 className="font-xl single-header mt-50 text-center">
                                            Suscripción realizada con éxito
                                        </h2>
                                    </div>
                                    :
                                    <div className="single-header mb-10 mt-20">
                                        <h3 className="font-xl text-center text-brand">
                                            Hubo un problema procesando tu donaci&oacute;n
                                        </h3>
                                        <p className="font-lg single-header mt-10 text-center">
                                            La respuesta del banco fue: <strong> {searchParams.get("error")} </strong>
                                        </p>

                                        {searchParams.get("oneshot") ? (
                                            <p className="font-md single-header mt-10 text-center">
                                                Puede volver a intentarlo haciendo <a href="/donacion-unica"> <span
                                                style={{textDecoration: "underline"}}> click aqu&iacute;</span></a>
                                            </p>
                                        ): (
                                            <p className="font-md single-header mt-10 text-center">
                                                Puede volver a intentarlo haciendo <a href="/"> <span
                                                style={{textDecoration: "underline"}}> click aqu&iacute;</span></a>
                                            </p>
                                        )}

                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </section>

            </>

    );
};

Payment.propTypes = {};

export default Payment;
/*
const mapStateToProps = (state) => ({
    activeCart       : state.counter,
    defConfig        : state.process.defConfig,
    defLang          : state.process.defLang,
    currency         : state.cart.currency,
    shippingComplete : state.process.shippingComplete,
    paymentInfo      : state.process.paymentInfo,
});

const mapDispatchToProps = {
    closeCart,
    increaseQuantity,
    decreaseQuantity,
    deleteFromCart,
    openCart,
    clearCart,
    setPaymentInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
*/
