import * as React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Card, Dialog, Slide} from "@mui/material";
import {styled} from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const FixedDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 15
    },

}));
const FixedCard = styled(Card)(({theme}) => ({
    borderRadius: 10,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)"
}));


const Dashboard = (props) => {
    const navigate = useNavigate();
    const {defConfig, params, defLang, activeForm} = useSelector((state) => state.Login);
    const [hideDialog, setHideDialog] = React.useState(false)
    const [estimatedequityValue, setEstimatedequityValue] = React.useState(65)
    const [estimatedequityLabel, setEstimatedequityLabel] = React.useState("$435K")


    React.useEffect(() => {
        setHideDialog(false)
    }, [])

    return (
        <></>


    );
}

Dashboard.propTypes = {
    window: PropTypes.func,
};

export default Dashboard;
