import actionTypes from "../action.types";
import * as LoginService from "../../services/user.service";

export const activateForm = (data) => {

    return {
        type: actionTypes.ACTIVE_FORM,
        payload: data
    }
};

export const login = (data) => {
    return (dispatch) => {
        dispatch({type: actionTypes.LOGIN_REQUEST});
        LoginService.Login(dispatch, data);
    };
};

export const logout = (data) => {
    return (dispatch) => {
        dispatch({type: actionTypes.LOGOUT});
    };
};

export const CreateUser = (data) => {
    return (dispatch) => {
        dispatch({type: actionTypes.PROCESS_REQUEST});
        LoginService.CreateUser(dispatch, data);
    };
};

export const RecoveryPass = (data) => {
    return (dispatch) => {
        dispatch({type: actionTypes.LOGIN_REQUEST});
        LoginService.RecoveryPass(dispatch, data);
    };
};

export const updatePassword = (data) => {
    return (dispatch) => {
        dispatch({type: actionTypes.PROCESS_REQUEST, payload: data});
        LoginService.updatePassword(dispatch, data);
    };
};

export const loginSuccess = (data) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        payload: data,
    };
};

export const loginFailed = (data) => {
    return {
        type: actionTypes.LOGIN_FAILED,
        payload: data,
    };
};


