import React from "react";
import "./OneShot.css";

//Images
import CardContainer from "../../components/elements/CardContainer";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";


const DonacionUnicaRealizadaContenido = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const bodyInfo1 = [

        {
            "id": 4,

            "childs": [
                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",
                    "bodystyleCont": {
                        "transform": "skew(-12deg)",

                        "background": "rgb(2,175,242)",
                        "padding": "20px 20px 15px 20px",
                        "textAlign": "center",
                    },
                    "bodystyle": {
                        "transform": "skew(12deg)",
                        "color": "#fff",
                        "fontSize": "2rem"
                    },
                    "updown": "JUSTBODY",
                    "body": "Hola " + props.name,
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },
            ]
        }
    ]


    return (

        <div className="donation-legend" style={fullScreen?{"width": "100%"}:{"width": "630px"}}>
            <section className="home-slider position-relative mb-10">
                <CardContainer key={"card1"} data={bodyInfo1} config={{ }}  className={"font-face-UniversBold"}/>
            </section>
            <br/>
            <div>
                <p style={{
                    fontSize: "1.5rem",
                    color: fullScreen?"#000":"#ffffff",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    lineHeight: 1.1,
                    textAlign: "center"

                }}>Gracias por tu donaci&oacute;n, <span className={"font-face-UniversBold"}>HOY plantaste una semilla </span>que dar&aacute; como fruto de muchas ni&ntilde;as y ni&ntilde;os tengan salud, educaci&oacute;n y protecci&oacute;n</p>
                <br/>
                <p style={{
                    fontSize: "1.5rem",
                    color: fullScreen?"#000":"#ffffff",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    lineHeight: 1.1,
                    textAlign: "center"
                }}
                   className={"font-face-UniversRegular"}>Enviamos el comprobante de tu aporte &uacute;nico a tu correo:</p>
                <p style={{
                    fontSize: "1.5rem",
                    color: "#ffffff",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    lineHeight: 1.1,
                    textAlign: "center"
                }}
                   className={"font-face-UniversRegular"}><span style={{backgroundColor: "#80BA42"}}>{props.email}</span></p>
                <br/>
                <p style={{
                    fontSize: "1.5rem",
                    color: fullScreen?"#000":"#ffffff",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    lineHeight: 1.1,
                    textAlign: "center"
                }}
                   className={"font-face-UniversRegular"}>De parte de todas las ni&ntilde;as y ni&ntilde;os bolivianos a los que decidiste ayudar hoy, te damos las gracias.</p>
                <br/>
                <p style={{
                    fontSize: "1.5rem",
                    color: fullScreen?"#000":"#ffffff",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    lineHeight: 1.1,
                    textAlign: "center"
                }}
                   className={"font-face-UniversRegular"}>Recuerda que <a style={{color: "#ffffff", backgroundColor: "#80BA42"}} href="https://www.unicef.org.bo/">puedes convertirte en un H&eacute;roe o Hero&iacute;na de la Ni&ntilde;ez</a> cambiando tu donaci&oacute;n a una mensual para multiplicar el impacto de tu ayuda.</p>
                <br/>
                <p style={{
                    fontSize: "1.5rem",
                    color: "#ffffff",
                    lineHeight: 1.1,
                    background: "rgb(2,175,242)",
                    padding: "20px 20px 15px 20px",
                    textAlign: "center"
                }}
                   className={"font-face-UniversBold"}>Gracias por sembrar esperanza en la infancia.</p>
            </div>
        </div>
    )
}

DonacionUnicaRealizadaContenido.propTypes = {};

export default DonacionUnicaRealizadaContenido;
