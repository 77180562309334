import { combineReducers } from "redux";
import LoginReducer from "./user.reducer";
import ProcessReducer from "./process.reducer";
import DonationReducer from "./donations.reducer";

const appReducer = combineReducers({
  Login: LoginReducer,
  Process: ProcessReducer,
  Donations: DonationReducer,
});

export default (state, action) => appReducer(state, action);
