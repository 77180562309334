import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Grid, Typography} from "@mui/material";


import Filters from "../../components/table/filter";
import Table from "../../components/table/table";
import Loading from "../../components/Loading";
import * as Actions from "../../redux/actions/process.actions";

import {searchHandler} from '../../utils/Functions';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ListData = (props) => {
    const navigate = useNavigate();
    const {defConfig, params, defLang, activeForm} = useSelector((state) => state.Login);
    const {componentConfig} = useSelector((state) => state.Process);

    const dispatch = useDispatch();
    const [componentOptions, setComponentOptions] = React.useState([])
    const [addNewOption, setAddNewOption] = React.useState(false)
    const [showMore, setShowMore] = React.useState(false)
    const [hideFilter, setHideFilter] = React.useState(true)

    const [isLoading, setIsLoading] = React.useState(false)

    const [filterConfig, setFilterConfig] = React.useState({})
    const [filterValues, setFilterValues] = React.useState({})
    const [originalTableData, setOriginalTableData] = React.useState([])
    const [tableData, setTableData] = React.useState([])

    let {componentName} = useParams();
    React.useEffect(() => {

        if (componentConfig.initial) {
            //console.log("reload")
            dispatch(Actions.getComponentConfig(componentName))
        } else {

            setOriginalTableData(componentConfig.rows)
            setTableData(componentConfig.rows)
            let actions = []
            console.log("componentConfig.actions", componentConfig.actions)
            componentConfig.actions.map(function (key) {
                if (key.name == "create") {
                    setAddNewOption(true)
                }
                actions.push(key.name)
            });
            setComponentOptions(actions)

            let filters = {}
            if (Object.keys(componentConfig?.filters).length === 0) {

                setHideFilter(true)
            } else {
                //console.log(componentConfig)
                filters = componentConfig?.filters

                let _filterValues = {}
                Object.keys(componentConfig.filters).map(function (key) {
                    _filterValues[key] = componentConfig.filters[key].value ? componentConfig.filters[key].value : ""
                    //console.log(key)
                    if (key.indexOf("FROMDATE") >= 0) {
                        var fromDate = new Date()
                        fromDate.setDate(new Date().getDate() - 3 * 365);
                        filters[key]["value"] = fromDate
                        _filterValues[key] = fromDate
                    }

                    if (key.indexOf("TODATE") >= 0) {
                        console.log("sdiii")
                        var toDate = new Date()
                        filters[key]["value"] = toDate
                        _filterValues[key] = toDate
                    }
                });

                setFilterValues(_filterValues)
                setHideFilter(false)

            }

            setFilterConfig(componentConfig.filters)
        }


    }, [componentConfig])

    const _searchHandler = async (values) => {


        setTableData([])
        setIsLoading(true)


        if (values !== "refresh") {
            let resp = await searchHandler(componentConfig.getAllDataApiName, values, originalTableData);
            setTableData(resp.tableData)

        } else {

            setTableData(originalTableData)
            let filters = componentConfig?.filters
            let _filterValues = {}
            Object.keys(componentConfig.filters).map(function (key) {
                _filterValues[key] = componentConfig.filters[key].value ? componentConfig.filters[key].value : ""

                if (key.indexOf("FROMDATE") >= 0) {
                    var fromDate = new Date()
                    fromDate.setDate(new Date().getDate() - 7);
                    filters[key]["value"] = fromDate
                    _filterValues[key] = fromDate
                }

                if (key.indexOf("TODATE") >= 0) {
                    var toDate = new Date()
                    filters[key]["value"] = toDate
                    _filterValues[key] = toDate
                }
            });


            setFilterValues(_filterValues)

        }
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
    }

    const [showB, setShowB] = React.useState(false)

    const _toggleFilter = async (values) => {
        setHideFilter(!hideFilter)
        setShowB(!showB)
    }
    return (
        <>
            {isLoading && <Loading/>}

            <Grid className="fixed-container" container>


                <Grid container spacing={1} sx={{px: 2, mt: 1}}>

                    <Grid>
                        {componentConfig.name &&
                            <Typography variant="h4" ml={2} color="primary"
                                        style={{
                                            fontWeight: "bolder",
                                            textTransform: "capitalize",

                                        }}
                            > Administraci&oacute;n de {componentConfig?.name} </Typography>
                        }
                    </Grid>
                    <Grid sx={{mt: 0, mb: 2, ml: 2}} container justifyContent="flex-start">
                        {addNewOption && componentConfig.writable &&

                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                                onClick={() => {
                                    navigate(process.env.REACT_APP_ADMMIN_PATH + "/manage/" + componentName + "/create/new")
                                }}
                                sx={{textTransform: "initial", fontWeight: {xs: "bold", md: "normal"}}}
                            >{defConfig[defLang].createlabel}</Button>

                        }
                    </Grid>
                    {componentConfig.columns &&
                        <>

                            {!hideFilter &&
                                <Grid item lg={2} xs={12} md={3}>
                                    <Filters toggleFilter={_toggleFilter} filterConfig={filterConfig}
                                             initialValues={filterValues} showMore={showMore}
                                             searchHandler={_searchHandler}/>
                                </Grid>
                            }
                            <Grid item lg={hideFilter ? 12 : 10} xs={12} md={hideFilter ? 12 : 9}>

                                {tableData.length > 0 &&
                                    <div style={{width: "100%", textAlign: "left"}}>
                                        {showB &&
                                            <button type="button" onClick={_toggleFilter}
                                                    className="btn btn-secondary mb-2" name="set"> Mostrar
                                                Fitros</button>
                                        }
                                        <ExcelFile filename={"export-" + componentConfig?.name} element={
                                            <button type="button" className="btn btm-sm btn-export mb-2"
                                                    name="set"> Exportar</button>
                                        }>
                                            <ExcelSheet data={tableData} name="Hoja 1">

                                                {componentConfig.columns.map((column, indexCell) => {
                                                    return (
                                                        <ExcelColumn label={column.label} value={column.id}/>
                                                    )

                                                })}

                                            </ExcelSheet>
                                        </ExcelFile>

                                    </div>
                                }

                                <Table rows={tableData} writable={componentConfig.writable}
                                       columns={componentConfig.columns} componentName={componentName}
                                       componentOptions={componentOptions}/>

                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </>
    );
}

ListData.propTypes = {
//  window: ListData.func,
};

export default ListData;
