import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

//import { useRouter } from 'next/router'
import {getCookie, setCookie, simplePutData} from "../utils/Functions";
import EndPoint from "../api/Endpoints";
import Preloader from "../components/elements/Preloader";
import {useNavigate, useSearchParams} from 'react-router-dom';
import CardContainer from "../components/elements/CardContainer";

import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

const wH = window.innerHeight
const Payment = (props) => {

    if (process.env.REACT_APP_URL_CONFIG === "https://www.unicef.org.bo") {
        window.fbq('track', 'Purchase', {value: 0.00, currency: 'BOB'});
        //Create Unique Identifier
        const date = new Date();
        // ✅ Get timestamp in Milliseconds
        const timestamp = date.getTime();
        console.log(timestamp); //  1650931200000
        window.gtag("event", "purchase", {
            transaction_id: timestamp,
            value: 1.00,
            currency: "BOB",
            items: [{
                item_id: "SKU_DONACION",
                item_name: "Donación de X Bs",
                price: 1.00,
                quantity: 1
            }]
        });
    } else {
        console.log("NO TAGS dev mode")
    }

    const [searchParams] = useSearchParams();

    const bodyInfo1 = [

        {
            "id": 4,

            "childs": [
                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",
                    "bodystyleCont": {
                        "transform": "skew(-12deg)",
                        "width": "20%",
                        "marginTop": 35,
                        "background": "#ffc813",
                        "padding": "20px 0px 15px 0px",
                        "textAlign": "center",
                    },
                    "bodystyle": {
                        "transform": "skew(12deg)",
                        "fontWeight": "bold",
                        "color": "#fff",
                        "fontSize": "2rem"
                    },
                    "updown": "JUSTBODY",
                    "body": "Hola " + searchParams.get("name"),
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },
                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",
                    "bodystyleCont": {
                        "transform": "skew(-12deg)",
                        "width": "40%",
                        "marginTop": 25,
                        "background": "#009ce0",
                        "padding": "10px 21px",
                        "textAlign": "center",
                    },
                    "bodystyle": {"transform": "skew(12deg)", "color": "#fff", "fontSize": "1.125rem"},
                    "updown": "JUSTBODY",
                    "body": "¡Desde HOY eres un HÉROE/HEROÍNA de la Niñez, GRACIAS por actuar cuando más te necesitamos!",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },
            ]
        }
    ]

    const bodyInfo1Mob = [

        {
            "id": 4,

            "childs": [
                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",
                    "bodystyleCont": {
                        "transform": "skew(-12deg)",
                        "width": "40%",
                        "marginTop": 35,
                        "background": "#ffc813",
                        "padding": "20px 0px 15px 0px",
                        "textAlign": "center",
                    },
                    "bodystyle": {
                        "transform": "skew(12deg)",
                        "fontWeight": "bold",
                        "color": "#fff",
                        "fontSize": "1.15rem"
                    },
                    "updown": "JUSTBODY",
                    "body": "Hola " + searchParams.get("name"),
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },
                {
                    "id": 1,
                    "size": "col-12 flex items-center mt-10",
                    "title": " ",
                    "bodystyleCont": {
                        "transform": "skew(-12deg)",
                        "width": "90%",
                        "marginTop": 25,
                        "background": "#009ce0",
                        "padding": "10px 21px",
                        "textAlign": "center",
                    },
                    "bodystyle": {"transform": "skew(12deg)", "color": "#fff", "fontSize": "1rem"},
                    "updown": "JUSTBODY",
                    "body": "¡Desde HOY eres un HÉROE/HEROÍNA de la Niñez,GRACIAS por actuar cuando más te necesitamos!",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },
            ]
        }
    ]

    const bodyInfo2 = [

        {
            "id": 4,

            "childs": [
                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",

                    "bodystyleCont": {
                        "transform": "skew(-12deg)",
                        "width": "60%",
                        "marginTop": 25,
                        "background": "#f26a21",
                        "padding": "15px 21px",
                        "textAlign": "center",
                    },
                    "bodystyle": {
                        "transform": "skew(12deg)",
                        "fontWeight": "bold",
                        "color": "#fff",
                        "fontSize": "1.75rem"
                    },


                    "updown": "JUSTBODY",
                    "body": "¡De corazón GRACIAS por sumarte y hacer la DIFERENCIA en este mundo!",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",
                    "bodystyle": {
                        "textAlign": "center",
                        "marginTop": 25,
                        "color": "#009ce0",
                        "fontSize": "1.75rem",
                        "fontWeight": "bold"
                    },
                    "updown": "JUSTBODY",
                    "body": "¡MUCHAS GRACIAS!",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

                {
                    "id": 1,
                    "size": "col-12",
                    "title": " ",
                    "bodystyle": {"textAlign": "center", "marginTop": 15},
                    "updown": "JUSTBODY",
                    "body": "Con cariño:",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",
                    "bodystyle": {
                        "textAlign": "center",
                        "marginTop": 5,
                        "color": "#009ce0",
                        "fontWeight": "bold",
                        "width": "100%"
                    },
                    "updown": "JUSTBODY",
                    "body": "Equipo Unicef Bolivia #EsTiempoDeActuar",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },


            ]
        },

    ]

    const bodyInfo2Mob = [

        {
            "id": 4,

            "childs": [
                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",

                    "bodystyleCont": {
                        "transform": "skew(-12deg)",
                        "width": "100%",
                        "marginTop": 25,
                        "background": "#f26a21",
                        "padding": "15px 21px",
                        "textAlign": "center",
                    },
                    "bodystyle": {
                        "transform": "skew(12deg)",
                        "fontWeight": "bold",
                        "color": "#fff",
                        "fontSize": "1.5rem"
                    },


                    "updown": "JUSTBODY",
                    "body": "¡De corazón GRACIAS por sumarte y hacer la DIFERENCIA en este mundo!",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",
                    "bodystyle": {
                        "textAlign": "center",
                        "marginTop": 25,
                        "color": "#009ce0",
                        "fontSize": "1.75rem",
                        "fontWeight": "bold"
                    },
                    "updown": "JUSTBODY",
                    "body": "¡MUCHAS GRACIAS!",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

                {
                    "id": 1,
                    "size": "col-12",
                    "title": " ",
                    "bodystyle": {"textAlign": "center", "marginTop": 15},
                    "updown": "JUSTBODY",
                    "body": "Con cariño:",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },

                {
                    "id": 1,
                    "size": "col-12 flex items-center",
                    "title": " ",
                    "bodystyle": {
                        "textAlign": "center",
                        "width": "100%",
                        "marginTop": 5,
                        "color": "#009ce0",
                        "fontWeight": "bold"
                    },
                    "updown": "JUSTBODY",
                    "body": "Equipo Unicef Bolivia #EsTiempoDeActuar",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": ""
                },


            ]
        },

    ]

    const bodyFooter = [

        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",
                    "imagestyle": {"position": "relative", "bottom": -40},
                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "/donar",
                    "image": "",
                    "type": "text",

                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/footer-donacion-web.png"
                }
            ]
        },
    ]

    const bodyFooterMob = [

        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",
                    "imagestyle": {"position": "fixed", "bottom": 0},
                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "/donar",
                    "image": "",
                    "type": "text",

                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/footer-donacion-mobile.png"
                }
            ]
        },
    ]

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const {defConfig, params, defLang} = useSelector((state) => state.Login);

    const [orderInfo, setOrderInfo] = useState("ACCEPT");
    const [orderMessage, setOrderMessage] = useState([]);
    const [loading, setLoading] = useState(false);


    const [orderUser, setOrderUser] = useState([]);
    const [orderEmail, setOrderEmail] = useState([]);

    useEffect(() => {


        var _tmpOrder = getCookie(process.env.REACT_APP_NAME + "_DFP_session_id")
        let utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source")
        let stepValues = {
            step: "checkout-finish-OK",
            utm_source: utm_source,
            email: searchParams.get("email"),
            sessionid: _tmpOrder
        }
        simplePutData(EndPoint.setStep, stepValues);

        setTimeout(() => {
            window.scrollTo(0, 0)
            setCookie(process.env.REACT_APP_NAME + "utm_source", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_id", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_content", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_medium", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_campaign", "", 0.5)
            setCookie(process.env.REACT_APP_NAME + "utm_term", "", 0.5)
        }, 100);


        /*
            let responseOrder = getCookie( process.env.REACT_APP_NAME+"_orderidSucc" )
            let responseMessage = getCookie( process.env.REACT_APP_NAME+"_orderidMessage" )

            let responseEmail = getCookie( process.env.REACT_APP_NAME+"_orderEmail" )
        if (!responseOrder){
            navigate("/");
        }else{
            setOrderInfo(responseOrder)
            setOrderMessage(responseMessage)
            setOrderUser(responseUser)
            setOrderEmail(responseEmail)
        }

        console.log(responseOrder)*/
    }, []);
    //noBreadcrumb="d-none"
    return (
        loading ?
            <Preloader loadinglabel={defConfig[defLang].finalizingpaymentlabel}/>
            :
            <>
                {orderInfo === "ACCEPT" ?
                    <div style={{
                        height: wH - 150,
                        backgroundColor: "red",
                        background: "url(" + process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/donacion_" + (fullScreen ? "mobile" : "web") + ".png)",
                        backgroundSize: "100% " + (wH) + "px"
                    }}>
                        {fullScreen ?
                            <section className="home-slider position-relative mb-10" style={{paddingTop: 66}}>
                                <CardContainer key={"card1"} data={bodyInfo1Mob} config={{}}/>
                            </section>
                            :
                            <section className="home-slider position-relative mb-10">
                                <CardContainer key={"card1"} data={bodyInfo1} config={{}}/>
                            </section>
                        }
                        <div className="container mt-20">
                            <div className="row">
                                <div className={"col-lg-12 col-md-12 col-sm-12 col-12 font-lg text-center"}>
                                    <span style={{color: "#009ce0"}}>Enviamos el comprobante de tu donación mensual a tu correo  </span>
                                    <span style={{
                                        color: "#312781",
                                        fontWeight: "normal"
                                    }}> {searchParams.get("email")} </span>
                                </div>
                            </div>
                        </div>
                        <div className="container mt-20">
                            <div className="row">
                                <div className={"col-lg-12 col-md-12 col-sm-12 col-12 font-lg text-center"}>
							<span style={{color: "#7d7d7d"}}> A partir de ahora empiezas a cambiar miles de vidas y gracias a
								<span style={{color: "#009ce0"}}> tu aporte mensual </span>podremos llegar con programas de salud, educación y
								protección a más niñas, niños y adolescentes de Bolivia. Tu donación nos
								<span style={{color: "#009ce0"}}> ayuda a trabajar para garantizar que se cumplan todos sus derechos </span>
								que les permite desarrollarse plenamente y vivir una infancia feliz.
							</span>
                                </div>
                            </div>
                        </div>
                        {fullScreen ?
                            <section className="home-slider position-relative mb-10">
                                <CardContainer key={"card1"} data={bodyInfo2Mob} config={{}}/>
                            </section>
                            :
                            <section className="home-slider position-relative mb-10">
                                <CardContainer key={"card1"} data={bodyInfo2} config={{}}/>
                            </section>
                        }


                        <div className="container mt-20">
                            <div className="row">
                                <div className={"col-lg-12 col-md-12 col-sm-12 col-12 font-lg text-center"}>
							<span style={{color: "#7d7d7d"}}>Si en algún momento necesitas más información o si tienes alguna duda comunícate con
								<span style={{color: "#009ce0"}}> UNICEF </span> llamando a la
								<span style={{color: "#009ce0"}}> línea gratuita 800 10 9333</span>
								, escribiendo a nuestro
								<span style={{color: "#009ce0"}}> WhatsApp 71543277 </span>
								o al
								<span style={{color: "#009ce0"}}> correo electrónico</span>
								<span style={{
                                    color: "#312781",
                                    fontWeight: "normal"
                                }}> donacionesbolivia@unicef.org </span>
							</span>
                                </div>
                            </div>
                        </div>

                        {fullScreen ?
                            <section className="home-slider  mb-10">
                                <CardContainer key={"card1"} data={bodyFooterMob} config={{}}/>
                            </section>
                            :
                            <section className="home-slider  mb-10">
                                <CardContainer key={"card1"} data={bodyFooter} config={{}}/>
                            </section>
                        }

                    </div>
                    :
                    <section className="mt-25 mb-20" id="success">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="single-header mb-10 mt-20">
                                        <h3 className="font-xl text-center text-brand">
                                            Hubo un problema procesando tu donaci&oacute;n
                                        </h3>
                                        <p className="font-lg single-header mt-10 text-center">
                                            La respuesta del banco fue: <strong> {orderMessage} </strong>
                                        </p>
                                        <p className="font-md single-header mt-10 text-center">
                                            Puede volver a intentarlo haciendo <a href="/"> click aqu&iacute; </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
    );
};

Payment.propTypes = {};

export default Payment;
