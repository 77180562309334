import React, { useCallback, useRef } from "react";
import {
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import * as Actions from "../../redux/actions/process.actions";
import { useFormik } from "formik";
import * as yup from "yup";
import DatePicker, { registerLocale } from "react-datepicker";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LoaderButton from "./LoaderButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled, useTheme } from "@mui/material/styles";
/**********************START ADMIN COMPONENTS***********************/
/*FORM COMPONENTS*/
import CompMultiAutocomplete from "./CompMultiAutocomplete";
import CompAutocomplete from "./CompAutocomplete";
import { IMaskInput } from "react-imask";

/*DIALOG COMPONENTS*/

/**********************END ADMIN COMPONENTS***********************/
/*********************EXTRA COMPONENTS*******************************/
/*DIALOG COMPONENTS*/

import useMediaQuery from "@mui/material/useMediaQuery";

/*********************END EXTRA COMPONENTS*******************************/
/*CUSTOM FUNCTIONS*/
import {
  completeForm,
  find,
  parseDataInfo,
  simpleGetData,
  simplePostData,
} from "../../utils/Functions";
import es from "date-fns/locale/es";

registerLocale("es", es);

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const MaskComponent = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  React.useImperativeHandle(ref, () => ({
    focus: () => {},
  }));

  return (
    <IMaskInput
      {...other}
      pattern="[0-9]*"
      mask={props.maskField}
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value: value.trim() } });
      }}
      overwrite
    />
  );
});

const GenericForm = (props) => {
  const dispatch = useDispatch();
  const {
    processSuccess,
    processError,
    requesting,
    processMessage,
    formFields,
    initialValues,
    validationSchema,
  } = useSelector((state) => state.Process);
  const { defLang, defConfig } = useSelector((state) => state.Login);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [cvvIndicator, setCvvIndicator] = React.useState(false);
  const [fieldName, setFieldName] = React.useState("");
  const [fieldValue, setFieldValue] = React.useState(null);
  const [fieldValueOI, setFieldValueOI] = React.useState(null);
  const [fieldValueF, setFieldValueF] = React.useState(null);
  const [multipleImages, setMultipleImages] = React.useState(false);
  const [refreshingData, setRefreshingData] = React.useState(false);
  const [showPasswords, setShowPasswords] = React.useState({});
  const [actionNumber, setActionNumber] = React.useState({});
  const [finalFormFields, setFinalFormFields] = React.useState([]);
  const currentYear = new Date().getFullYear();

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  let validationSchemaE;

  if (props.componentName === "personalData") {
    console.log("personalData");
    validationSchemaE = yup.object({
      firstname: yup
        .string("Nombres inv\u00E1lidos")
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "Nombres inv\u00E1lidos"
        )
        .min(3, "Debe completar al menos 3 caracteres")
        .required("Este campo es obligatorio"),
      lastname: yup
        .string("Apellidos inv\u00E1lidos")
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "Apellidos inv\u00E1lidos"
        )
        .min(3, "Debe completar al menos 3 caracteres")
        .required("Este campo es obligatorio"),

      dni: yup
        .string("Carnet de identidad inv\u00E1lidos")
        .min(5, "Debe completar al menos 5 caracteres")
        .required("Este campo es obligatorio"),
      email: yup
        .string("Formato de email incorrecto")
        .min(8, "Debe completar al menos 8 caracteres")
        .email("Formato de email incorrecto")
        .required("Este campo es obligatorio"),
      phonenumber: yup
        .number("N\u00FAmero de Celular inv\u00E1lido")
        .typeError("N\u00FAmero de Celular inv\u00E1lido")
        .min(60000000, "N\u00FAmero de Celular inv\u00E1lido")
        .max(79999999, "N\u00FAmero de Celular inv\u00E1lido")
        .required("Este campo es obligatorio"),
    });
  } else if (props.componentName === "checkout") {
    validationSchemaE = yup.object({
      cardnumber: yup

        .number("N\u00FAmero de Tarjeta inv\u00E1lido")
        .typeError("N\u00FAmero de Tarjeta inv\u00E1lido")
        .min(4000000000000000, "N\u00FAmero de Tarjeta inv\u00E1lido")
        .max(5999999999999999, "N\u00FAmero de Tarjeta inv\u00E1lido")
        .required("Este campo es obligatorio"),

      cvv: yup
        .number("cvv inv\u00E1lido")
        .typeError("cvv inv\u00E1lido")
        .min(100, "cvv inv\u00E1lido")
        .max(999, "cvv inv\u00E1lido")
        .required("Este campo es obligatorio"),

      expireyear: yup
        .number("Año inv\u00E1lido")
        .typeError("Año inv\u00E1lido")
        .required("Este campo es obligatorio")
        .min(
          new Date().getFullYear(),
          `Año inv\u00E1lido (Ejemplo: ${currentYear + 5})`
        )
        .max(
          new Date().getFullYear() + 10,
          `Año inv\u00E1lido  (Ejemplo: ${currentYear + 5})`
        )
        .when(
          "startDate",
          (currentYear, schema) => currentYear && schema.min(currentYear)
        ),

      expiremonth: yup
        .string("Mes inv\u00E1lido (Ejemplo: 04)")
        .required("Este campo es obligatorio")
        .typeError("Mes inv\u00E1lido (Ejemplo: 04)")
        .max(5, "Mes inv\u00E1lido (Ejemplo: 04")
        .matches(/([0-9]{2})/, "Mes inv\u00E1lido (Ejemplo: 04)")
        .test(
          "test-credit-card-expiration-date",
          "Mes inv\u00E1lido (Ejemplo: 04)",
          (expirationDate) => {
            if (!expirationDate) {
              return false;
            }

            const values = [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
            ];

            //const [expMonth, expYear] = expirationDate.split('/')

            if (values.indexOf(expirationDate) >= 0) {
              return true;
            } else {
              return false;
            }
          }
        ),
    });
  }

  const formik = useFormik({
    initialValues: props.formData?.formValues
      ? props.formData.formValues
      : initialValues,
    enableReinitialize: true,
    validateOnBlur: true,

    validationSchema: props.formData?.formValidation
      ? props.formData.formValidation
      : validationSchemaE
      ? validationSchemaE
      : validationSchema,
    //validationSchema: (props.componentName=="personalData"? validationSchema1: validationSchema2),
    onSubmit: (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
      values = completeForm(values, finalFormFields);
      values["id"] = props.id;
      //console.log( values)
      if (typeof props.action == "string") {
        if (actionNumber === 2) {
          dispatch(
            Actions.processData(props.componentName, props.action2, values)
          );
        } else {
          dispatch(
            Actions.processData(props.componentName, props.action, values)
          );
        }
      } else {
        if (actionNumber === 2) {
          props.action2(values);
        } else {
          props.action(values);
        }
      }
    },
  });
  var date = new Date();
  var defDate = date.setMonth(date.getMonth() - 12 * 18);

  const setFormDate = (field, date) => {
    let fields = finalFormFields;

    setRefreshingData(true);
    let pos = find(finalFormFields, field, "name");
    fields[pos].value = date;

    setTimeout(() => {
      setFinalFormFields(fields);
      setRefreshingData(false);
    }, 1);
  };

  const loadPreviewform = async (event) => {
    let fields = finalFormFields;
    setRefreshingData(true);
    let postloadscript = "";
    try {
      postloadscript = formik.values[event.target.name];
    } catch (Exc) {
      //console.log("no script")
    }

    for (let f in fields) {
      let field = fields[f];
      if (field.type == "componentformcontainer") {
        let data = {};
        if (fields[f]["loaded"]) {
          /*
                      data = field["formattedvalue"]
                      data =await preloadForm(data, postloadscript)
                      */
          data = field["value"];
          data = await parseDataInfo(data, postloadscript);
        } else {
          data = field["value"];
          data = await parseDataInfo(data, postloadscript);
        }
        fields[f]["formattedvalue"] = data;
        fields[f]["loaded"] = true;
      }
    }
    setTimeout(() => {
      setFinalFormFields(fields);
      setRefreshingData(false);
    }, 1);
  };

  const handleChange = (event) => {
    let pos = find(finalFormFields, event.target.name, "name");
    let fields = finalFormFields;
    if (Array.isArray(event.target.value)) {
      let newValue = [];
      event.target.value.map(function (key) {
        if (
          event.target.type == "component" ||
          event.target.type == "form" ||
          event.target.type == "orderitems"
        ) {
          newValue.push(key);
        } else {
          newValue.push(key.value.toString());
        }
      });
      if (event.target.type == "component") {
        fields[pos].value = newValue;
        setFieldValue(newValue);
      } else if (event.target.type == "form") {
        fields[pos].value = newValue;
        setFieldValueF(newValue);
      } else if (event.target.type == "orderitems") {
        fields[pos].value = newValue;
        setFieldValueF(newValue);
      } else {
        fields[pos].value = newValue;
      }
    } else {
      fields[pos].value = event.target.value;
    }
    setFinalFormFields(fields);

    if (event.target.type == "form") {
      setTimeout(async () => {
        await loadPreviewform();
      }, 1);
    }
  };

  const handleClickShowPassword = (field) => {
    setRefreshingData(true);

    setTimeout(() => {
      let _showPasswords = showPasswords;
      if (_showPasswords[field]) {
        _showPasswords[field] = false;
      } else {
        _showPasswords[field] = true;
      }
      setShowPasswords(_showPasswords);
      setRefreshingData(false);
    }, 1);
  };

  const handleMouseDownPassword = (event) => {
    console.log("pre");
    //event.preventDefault();
  };

  React.useEffect(() => {
    if (props.formData) {
      setFinalFormFields(props.formData.data);
    } else {
      if (formFields.length > 0) {
        setFinalFormFields(formFields);
      }
    }
    if (processSuccess) {
      dispatch(Actions.getComponentConfig(props.componentName));
    }
  }, [formFields, processSuccess]);

  const minHeight = window.innerHeight - 120;

  const handleOnChange = async (event) => {
    var action = "";
    var formData = finalFormFields;
    var field = {};
    try {
      const index = finalFormFields.findIndex(
        (item) => item.name === event.target.name
      );

      if (formData[index].action) {
        if (formData[index].action != "") {
          field = formData[index];
          field["value"] = event.target.value;
          console.log("formData", formData);
          console.log("field", field);
          action = formData[index].action;
          const { simpleGetData } = require("../../utils/Functions");
          const { simplePostData } = require("../../utils/Functions");

          if (action.indexOf("async") >= 0) {
            console.log("async");
            setRefreshingData(true);
            await eval(action);
            console.log("fin");
            setRefreshingData(false);
          } else {
            console.log("sync");
            eval(action);
          }

          setFinalFormFields(formData);
        }
      }
    } catch (Exc) {
      console.log(">>>>Err", Exc);
      if (action != "") {
        console.log("action", action);
      }
    }
  };

  const formRef = useRef();

  return (
    <Container>
      <form
        onSubmit={formik.handleSubmit}
        onChange={handleOnChange}
        ref={formRef}
        name={"formGeneric"}
      >
        <Grid container>
          <Grid item lg={12} xs={12}>
            {/*   <Card sx={{minHeight: minHeight }}> */}
            <Container style={{ marginTop: 10 }}>
              <Grid container spacing={1}>
                {finalFormFields.map((field, index) => {
                  return field.type == "hidden" ? (
                    <input
                      type="hidden"
                      key={"field" + index}
                      name={field.name}
                      value={formik.values[field.name]}
                    />
                  ) : (
                    <>
                      {!field.hide && (
                        <Grid
                          key={"grid" + index}
                          item
                          sx={{ mt: 1, textAlign: "left" }}
                          xs={field.size.xs}
                          lg={field.size.lg}
                        >
                          {field.type == "title" && (
                            <Typography
                              variant={field.variant}
                              color={field.color + ".main"}
                            >
                              {field.label}
                            </Typography>
                          )}

                          {(field.type == "text" ||
                            field.type == "number" ||
                            field.type == "tel") && (
                            <>
                              {field.mask ? (
                                <TextField
                                  key={"field" + index}
                                  fullWidth
                                  placeholder={
                                    field.placeholder ? field.placeholder : ""
                                  }
                                  InputProps={{
                                    inputComponent: MaskComponent,
                                    inputProps: {
                                      maskField: field.mask,
                                      typeField: field.type,
                                    },
                                  }}
                                  disabled={field.disabled || props.allDisabled}
                                  label={field.label}
                                  error={
                                    formik.touched[field.name] &&
                                    Boolean(formik.errors[field.name])
                                  }
                                  helperText={
                                    formik.touched[field.name] &&
                                    formik.errors[field.name]
                                  }
                                  name={field.name}
                                  value={formik.values[field.name]}
                                  id={field.name}
                                  onChange={formik.handleChange}
                                  type={
                                    field.type == "number"
                                      ? "number"
                                      : field.type == "tel"
                                      ? "number"
                                      : ""
                                  }
                                  inputmode={
                                    field.type == "number"
                                      ? "number"
                                      : field.type == "tel"
                                      ? "number"
                                      : "text"
                                  }
                                  inputProps={
                                    field.type == "number"
                                      ? { pattern: "[0-9]*" }
                                      : {}
                                  }
                                />
                              ) : (
                                <TextField
                                  key={"field" + index}
                                  fullWidth
                                  placeholder={
                                    field.placeholder ? field.placeholder : ""
                                  }
                                  disabled={field.disabled || props.allDisabled}
                                  label={field.label}
                                  error={
                                    formik.touched[field.name] &&
                                    Boolean(formik.errors[field.name])
                                  }
                                  helperText={
                                    formik.touched[field.name] &&
                                    formik.errors[field.name]
                                  }
                                  name={field.name}
                                  value={formik.values[field.name]}
                                  id={field.name}
                                  onChange={formik.handleChange}
                                  type={
                                    field.type == "number"
                                      ? "number"
                                      : field.type == "tel"
                                      ? "number"
                                      : ""
                                  }
                                  inputmode={
                                    field.type == "number"
                                      ? "number"
                                      : field.type == "tel"
                                      ? "number"
                                      : "text"
                                  }
                                  inputProps={
                                    field.type == "number"
                                      ? { pattern: "[0-9]*" }
                                      : {}
                                  }
                                />
                              )}
                            </>
                          )}

                          {field.type == "password" && (
                            <>
                              {refreshingData ? (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="password">
                                    {field.label}
                                  </InputLabel>
                                  <OutlinedInput
                                    sx={{
                                      backgroundColor: "forms.bgLoginTextField",
                                    }}
                                    key={"field" + index}
                                    error={
                                      formik.touched[field.name] &&
                                      Boolean(formik.errors[field.name])
                                    }
                                    helperText={
                                      formik.touched[field.name] &&
                                      formik.errors[field.name]
                                    }
                                    name={field.name}
                                    value={formik.values[field.name]}
                                    label={field.label}
                                    type="password"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          edge="end"
                                        >
                                          <LockIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              ) : (
                                <>
                                  {field.name === "cvv" ? (
                                    <>
                                      <TextField
                                        key={"field" + index}
                                        fullWidth
                                        type="password"
                                        placeholder={
                                          field.placeholder
                                            ? field.placeholder
                                            : ""
                                        }
                                        disabled={
                                          field.disabled || props.allDisabled
                                        }
                                        label={field.label}
                                        error={
                                          formik.touched[field.name] &&
                                          Boolean(formik.errors[field.name])
                                        }
                                        helperText={
                                          formik.touched[field.name] &&
                                          formik.errors[field.name]
                                        }
                                        name={field.name}
                                        value={formik.values[field.name]}
                                        id={field.name}
                                        inputProps={{ maxLength: 3 }}
                                        onChange={(event) => {
                                          if (
                                            event.target.value.match(/[^0-9]/)
                                          ) {
                                            event.preventDefault();
                                          } else {
                                            formik.handleChange(event);
                                          }
                                        }}
                                      />

                                      <IconButton
                                        onClick={() => {
                                          setCvvIndicator(!cvvIndicator);
                                        }}
                                        aria-label="question"
                                        color="secondary"
                                        style={{
                                          position: "absolute",
                                          marginLeft: -7,
                                          marginTop: 7,
                                        }}
                                      >
                                        <HelpOutlineIcon />
                                      </IconButton>

                                      <div
                                        style={{
                                          visibility: cvvIndicator
                                            ? "visible"
                                            : "hidden",
                                          position: "absolute",
                                          marginTop: -55,
                                          marginLeft: 2,
                                        }}
                                      >
                                        <img
                                          onClick={() => {
                                            setCvvIndicator(false);
                                          }}
                                          style={{ height: 60, width: 90 }}
                                          src={
                                            process.env.REACT_APP_URL_CONFIG +
                                            "/assets/imgs/unicef/cvv-tooltip.png"
                                          }
                                          alt={"cvv-tooltip"}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel htmlFor="password">
                                        {field.label}
                                      </InputLabel>
                                      <OutlinedInput
                                        sx={{
                                          backgroundColor:
                                            "forms.bgLoginTextField",
                                        }}
                                        key={"field" + index}
                                        error={
                                          formik.touched[field.name] &&
                                          Boolean(formik.errors[field.name])
                                        }
                                        helperText={
                                          formik.touched[field.name] &&
                                          formik.errors[field.name]
                                        }
                                        name={field.name}
                                        value={formik.values[field.name]}
                                        label={field.label}
                                        disabled={
                                          field.disabled || props.allDisabled
                                        }
                                        onChange={formik.handleChange}
                                        type={
                                          showPasswords[field.name]
                                            ? "text"
                                            : "password"
                                        }
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={() => {
                                                handleClickShowPassword(
                                                  field.name
                                                );
                                              }}
                                              edge="end"
                                            >
                                              {showPasswords[field.name] ? (
                                                <LockOpenIcon />
                                              ) : (
                                                <LockIcon />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                      />
                                    </FormControl>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {field.type == "date" && (
                            <>
                              {refreshingData ? (
                                <TextField
                                  key={"field" + index}
                                  fullWidth
                                  label={field.label}
                                />
                              ) : (
                                <div className="custom-container-datepicker">
                                  <label
                                    style={{ "max-width": "90%" }}
                                    className="custom-label"
                                  >
                                    <span> {field.label} </span>
                                  </label>
                                  <DatePicker
                                    showYearDropdown
                                    showIcon
                                    className="custom-datepicker"
                                    maxDate={defDate}
                                    yearDropdownItemNumber={50}
                                    locale="es"
                                    selected={
                                      field.value ? field.value : defDate
                                    }
                                    onChange={(date) => {
                                      if (!field.disabled) {
                                        setFormDate(field.name, date);
                                      }
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </div>
                              )}
                            </>
                          )}

                          {field.type == "radiobuttonh" && (
                            <FormControl key={"formcontrolrh" + index}>
                              <FormLabel key={"formcontrolrhl" + index}>
                                {field.label}
                              </FormLabel>
                              <RadioGroup
                                key={"field" + index}
                                error={
                                  formik.touched[field.name] &&
                                  Boolean(formik.errors[field.name])
                                }
                                helperText={
                                  formik.touched[field.name] &&
                                  formik.errors[field.name]
                                }
                                name={field.name}
                                value={formik.values[field.name]}
                                label={field.label}
                                disabled={field.disabled || props.allDisabled}
                                onChange={formik.handleChange}
                                row
                              >
                                {field.values &&
                                  field.values.map((value, index2) => {
                                    return (
                                      <FormControlLabel
                                        key={"formcontrolrh" + index + index2}
                                        value={value.value}
                                        control={<Radio />}
                                        label={value.text}
                                      />
                                    );
                                  })}
                              </RadioGroup>
                            </FormControl>
                          )}

                          {field.type == "radiobuttonv" && (
                            <FormControl key={"formcontrolrv" + index}>
                              <FormLabel key={"formcontrolrvl" + index}>
                                {field.label}
                              </FormLabel>
                              <RadioGroup
                                key={"field" + index}
                                error={
                                  formik.touched[field.name] &&
                                  Boolean(formik.errors[field.name])
                                }
                                helperText={
                                  formik.touched[field.name] &&
                                  formik.errors[field.name]
                                }
                                name={field.name}
                                value={formik.values[field.name]}
                                label={field.label}
                                disabled={field.disabled || props.allDisabled}
                                onChange={formik.handleChange}
                              >
                                {field.values &&
                                  field.values.map((value, index2) => {
                                    return (
                                      <FormControlLabel
                                        key={"formcontrolrv" + index + index2}
                                        value={value.value}
                                        control={<Radio />}
                                        label={value.text}
                                      />
                                    );
                                  })}
                              </RadioGroup>
                            </FormControl>
                          )}

                          {field.type == "textarea" && (
                            <TextField
                              key={"field" + index}
                              fullWidth
                              multiline
                              disabled={field.disabled || props.allDisabled}
                              rows={2}
                              label={field.label}
                              error={
                                formik.touched[field.name] &&
                                Boolean(formik.errors[field.name])
                              }
                              helperText={
                                formik.touched[field.name] &&
                                formik.errors[field.name]
                              }
                              name={field.name}
                              value={formik.values[field.name]}
                              id={field.name}
                              onChange={formik.handleChange}
                            />
                          )}
                          {field.type == "scriptarea" && (
                            <Card
                              sx={{
                                border: "1px solid",
                                borderColor: "warning.main",
                                padding: 1,
                              }}
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  <TextareaAutosize
                                    maxRows={20}
                                    minRows={3}
                                    style={{ width: "99%" }}
                                    key={"field" + index}
                                    fullWidth
                                    multiline
                                    disabled={
                                      field.disabled || props.allDisabled
                                    }
                                    label={field.label}
                                    error={
                                      formik.touched[field.name] &&
                                      Boolean(formik.errors[field.name])
                                    }
                                    helperText={
                                      formik.touched[field.name] &&
                                      formik.errors[field.name]
                                    }
                                    name={field.name}
                                    value={formik.values[field.name]}
                                    id={field.name}
                                    onChange={formik.handleChange}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  mt={1}
                                  sx={{ textAlign: "right" }}
                                >
                                  <Button
                                    type="button"
                                    onClick={loadPreviewform}
                                    name={field.name}
                                    variant="contained"
                                    disabled={
                                      field.disabled || props.allDisabled
                                    }
                                    color="warning"
                                    sx={{
                                      textTransform: "initial",
                                      fontWeight: { xs: "bold", md: "normal" },
                                    }}
                                  >
                                    {defConfig[defLang].testscriptlabel}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Card>
                          )}
                          {field.type == "autocomplete" && (
                            <>
                              {field.multiple ? (
                                <CompMultiAutocomplete
                                  disabled={
                                    field.disabled || props.allDisabled3
                                  }
                                  key={"field" + index}
                                  field={field}
                                  handleChange={handleChange}
                                />
                              ) : (
                                <CompAutocomplete
                                  disabled={field.disabled || props.allDisabled}
                                  key={"field" + index}
                                  field={field}
                                  handleChange={handleChange}
                                />
                              )}
                            </>
                          )}
                          {field.type == "multipleautocomplete" && (
                            <>
                              <CompMultiAutocomplete
                                disabled={field.disabled || props.allDisabled}
                                key={"field" + index}
                                field={field}
                                handleChange={handleChange}
                              />
                            </>
                          )}

                          {field.type == "select" && (
                            <FormControl fullWidth>
                              <InputLabel>{field.label}</InputLabel>
                              <Select
                                key={"field" + index}
                                error={
                                  formik.touched[field.name] &&
                                  Boolean(formik.errors[field.name])
                                }
                                helperText={
                                  formik.touched[field.name] &&
                                  formik.errors[field.name]
                                }
                                name={field.name}
                                value={formik.values[field.name]}
                                label={field.label}
                                disabled={field.disabled || props.allDisabled}
                                onChange={formik.handleChange}
                              >
                                {field.values &&
                                  field.values.map((value) => {
                                    return (
                                      <MenuItem value={value.value}>
                                        {value.text}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                      )}
                    </>
                  );
                })}
                {props.addAcceptTerms && (
                  <Grid item xs={12} lg={12}>
                    <FormGroup>
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            checked={props.checkedP}
                            onChange={props.handleChangeP}
                            required
                          />
                        }
                        label={
                          <>
                            {" "}
                            He leído y acepto{" "}
                            <a
                              className="link-policy"
                              href="/aviso-de-privacidad"
                              target="_blank"
                            >
                              {" "}
                              la política de privacidad
                            </a>
                          </>
                        }
                      />
                    </FormGroup>
                    {!props.checkedP && (
                      <FormHelperText error>
                        Debe Aceptar los T&eacute;rminos y Condiciones para
                        Continuar.
                      </FormHelperText>
                    )}
                  </Grid>
                )}
              </Grid>

              {fullScreen ? (
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid
                    xs={props.outlineBackButton ? 6 : 12}
                    lg={6}
                    sx={{ mt: 1 }}
                    textAlign="left"
                    style={
                      props.outlineBackButton
                        ? {
                            alignContent: "center",
                          }
                        : {}
                    }
                  >
                    {props.buttonLabel2 &&
                      !props.isVisaForm && ( // aqui
                        <LoaderButton
                          className={
                            " " +
                            (props.button2ClassName
                              ? props.button2ClassName
                              : "btn-outlined-secondary")
                          }
                          width={
                            props.buttonwidth2 ? props.buttonwidth2 : "initial"
                          }
                          onClick={() => {
                            if (props.isPersonalDataForm) {
                              props.action2();
                            } else if (document.forms["formGeneric"]) {
                              // when form is present
                              setActionNumber(2);
                              formik.handleSubmit();
                            }
                          }}
                          type="button"
                          label={
                            props.buttonLabel2
                              ? props.buttonLabel2
                              : defConfig[defLang].backlabel
                          }
                          outlineBackButton={props.outlineBackButton}
                        />
                      )}
                  </Grid>
                  <Grid
                    xs={
                      props.outlineBackButton
                        ? props.isSecondForm
                          ? 12
                          : 6
                        : 12
                    }
                    lg={props.isSecondForm ? 12 : 6}
                    sx={{ mt: 1 }}
                    textAlign="right"
                  >
                    {props.buttonLabel === "NOBUTTON" ? (
                      ""
                    ) : (
                      <LoaderButton
                        className={
                          " " +
                          (props.buttonClassName
                            ? props.buttonClassName
                            : "btn-success")
                        }
                        loading={props.loading}
                        width={
                          props.buttonwidth ? props.buttonwidth : "initial"
                        }
                        onClick={() => {
                          if (document.forms["formGeneric"]) {
                            // when form is present
                            setActionNumber(1);
                            formik.handleSubmit();
                          }
                        }}
                        label={
                          props.buttonLabel
                            ? props.buttonLabel
                            : defConfig[defLang].savedatalabel
                        }
                        loadingLabel={
                          props.buttonLoadingLabel
                            ? props.buttonLoadingLabel
                            : defConfig[defLang].processinglabel
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={1}
                  sx={{ mt: 2 }}
                  style={
                    props.outlineBackButton && props.isSecondForm
                      ? {
                          justifyContent: "center",
                        }
                      : {}
                  }
                >
                  {!(props.outlineBackButton && props.isSecondForm) && (
                    <Grid
                      xs={12}
                      lg={6}
                      sx={{ mt: 1 }}
                      textAlign={
                        props.button2Position ? props.button2Position : "left"
                      }
                      padding={props.button2Padding ? props.button2Padding : 0}
                      margin={props.button2Margin ? props.button2Margin : 0}
                    >
                      {props.buttonLabel2 &&
                        !props.isVisaForm && ( //aqui
                          <LoaderButton
                            className={
                              " " +
                              (props.button2ClassName
                                ? props.button2ClassName
                                : "btn-outlined-secondary")
                            }
                            width={
                              props.buttonwidth2
                                ? props.buttonwidth2
                                : "initial"
                            }
                            onClick={() => {
                              if (props.isPersonalDataForm) {
                                props.action2();
                              } else if (document.forms["formGeneric"]) {
                                // when form is present
                                setActionNumber(2);
                                formik.handleSubmit();
                              }
                            }}
                            type="button"
                            label={
                              props.buttonLabel2
                                ? props.buttonLabel2
                                : defConfig[defLang].backlabel
                            }
                            outlineBackButton={props.outlineBackButton}
                          />
                        )}
                    </Grid>
                  )}
                  <Grid
                    xs={props.outlineBackButton && props.isSecondForm ? 6 : 12}
                    lg={6}
                    sx={{ mt: 1 }}
                    textAlign={
                      props.outlineBackButton && props.isSecondForm
                        ? "center"
                        : props.buttonPosition
                        ? props.buttonPosition
                        : "right"
                    }
                    padding={props.buttonPadding ? props.buttonPadding : 0}
                    margin={props.buttonMargin ? props.buttonMargin : 0}
                  >
                    {props.buttonLabel === "NOBUTTON"
                      ? ""
                      : props.action !== "view" && (
                          <LoaderButton
                            className={
                              " " +
                              (props.buttonClassName
                                ? props.buttonClassName
                                : "btn-success")
                            }
                            loading={props.loading}
                            width={
                              props.buttonwidth ? props.buttonwidth : "initial"
                            }
                            onClick={() => {
                              if (document.forms["formGeneric"]) {
                                // when form is present
                                setActionNumber(1);
                                formik.handleSubmit();
                              }
                            }}
                            label={
                              props.buttonLabel
                                ? props.buttonLabel
                                : defConfig[defLang].savedatalabel
                            }
                            loadingLabel={
                              props.buttonLoadingLabel
                                ? props.buttonLoadingLabel
                                : defConfig[defLang].processinglabel
                            }
                          />
                        )}
                  </Grid>
                </Grid>
              )}
            </Container>
            {/*	</Card>*/}
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid xs={12} sx={{ mt: 2, mb: 2 }} container justifyContent="center">
          <img
            className="payment-method"
            src={
              process.env.REACT_APP_URL_CONFIG +
              "/assets/imgs/unicef/tarjetas-credito.png"
            }
            alt={"payment-method"}
          />
        </Grid>
      </Grid>
      {props.buttonLabel2 &&
        props.isVisaForm && ( //aqui
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            <LoaderButton
              className={
                " " +
                (props.button2ClassName
                  ? props.button2ClassName
                  : "btn-outlined-secondary")
              }
              width={props.buttonwidth2 ? props.buttonwidth2 : "initial"}
              onClick={() => {
                if (props.isVisaForm) {
                  props.action2();
                } else if (document.forms["formGeneric"]) {
                  // when form is present
                  setActionNumber(2);
                  formik.handleSubmit();
                }
              }}
              type="button"
              label={
                props.buttonLabel2
                  ? props.buttonLabel2
                  : defConfig[defLang].backlabel
              }
              outlineBackButton={props.outlineBackButton}
            />
          </div>
        )}
    </Container>
  );
};

GenericForm.propTypes = {};

export default GenericForm;
