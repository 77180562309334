import "./assets/css/App.css";
import "react-datepicker/dist/react-datepicker.css";


import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {Provider} from "react-redux";
import store from "../src/redux/store";
import Route from "./Route";
import "swiper/css";
import "swiper/css/navigation";
import "./assets/css/main.css";

export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
}

function App() {

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        var resp = ""
        for (let i = 0; i < ca.length; i++) {
            let _c = ca[i].trim()
            let c = _c.split("=")
            let val = c[0] + "=";

            if (val.indexOf(nameEQ) === 0) {
                return _c.substring(nameEQ.length, _c.length);
            }
        }
        if (resp === "") {

            let value = Math.floor(Math.random() * 99999);
            let formatNumber = ""
            for (let i = 0; i < 6; i++) {
                formatNumber += "0"
            }
            let finalValue = (value + "").padStart(6, formatNumber)

            var date = new Date();
            date.setTime(date.getTime() + (1 * 60 * 60 * 1000));
            let expires = "; expires=" + date.toGMTString();
            document.cookie = process.env.REACT_APP_NAME + "_DFP_session_id=" + finalValue + expires + "; path=/";

            console.log("nueva llave ", finalValue)
            resp = finalValue
        }
        console.log("al final final es", resp)
        return resp;
    }

    return (

        <div className="App">
            {AddLibrary('https://h.online-metrix.net/fp/tags.js?org_id=' + process.env.REACT_APP_cyberOrgId + '&session_id=' + process.env.REACT_APP_cyberOwner + "_" + process.env.REACT_APP_cyberMID + getCookie(process.env.REACT_APP_NAME + "_DFP_session_id"))}
            <Provider store={store}>
                <Route/>
            </Provider>
        </div>
    );
}

export default App;
