import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { Button, CircularProgress, IconButton } from "@mui/material";

const LoaderButton = ({
  onClick,
  type,
  loading,
  label,
  loadingLabel,
  className,
  width,
  outlineBackButton,
}) => {
  var variant = "contained";
  var color = "primary";
  var extraClass = "";
  if (className.indexOf("outlined") >= 0) {
    variant = "outlined";
  }

  if (className.indexOf("btn-block") >= 0) {
    extraClass += "btn-block ";
  }

  if (className.indexOf("secondary") >= 0) {
    color = "secondary";
  } else if (className.indexOf("success") >= 0) {
    color = "success";
  } else if (className.indexOf("danger") >= 0) {
    color = "danger";
  } else if (className.indexOf("error") >= 0) {
    color = "error";
  }

  console.log("outlineBackButton", outlineBackButton);

  return outlineBackButton ? (
    <IconButton
      onClick={onClick}
      aria-label="move"
      disabled={loading}
      style={{
        color: "#333333",
        textDecoration: "underline",
      }}
    >
      <ArrowBackIosIcon />
      {label}
    </IconButton>
  ) : (
    <Button
      type={type ? type : "button"}
      className={" " + extraClass}
      variant={variant}
      color={color}
      style={{
        paddingTop: 18,
        paddingBottom: 18,
        minWidth: 150,
        fontSize: 16,
        fontWeight: 600,
        width: width ? width : "initial",
        borderRadius: 25,
        lineHeight: 1,
      }}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? (
        <>
          {" "}
          <CircularProgress size={14} /> {loadingLabel ? loadingLabel : label}{" "}
        </>
      ) : (
        label
      )}
    </Button>
  );
};

export default LoaderButton;
