// eslint-disable-next-line import/no-anonymous-default-export
export default {

    //default labels
    defConfig: {

        organizationname: "UNICEF",
        organizationid: 1,
        logoimage: (process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/logo-ncf.png"),
        logoimagefooter: process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/UNICEF.webp",
        logominiimage: (process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/logo-ncf.png"),
        imageheaderwidth: "430px",
        imageheaderwidthMob: "90%",
        imagefooterwidth: "128px",
        headerbackground1: "#009ce0",
        headerbackground2: "#009ce0",
        headercolor1: "#fff",
        headercolor2: "#fff",
        footercolor1: "#1B262C",
        backgroundfooter: "#2d2926",
        shippingfirst: false,
        bodybackground1: "#fff",
        bodybackground2: "#b6ebf9",
        bodycolor1: "#fff",
        bodycolor2: "#b6ebf9",
        primary: {
            background: "#000",
            color: "#fff"
        },
        linkcolor1: "#fff",
        linkhovercolor1: "#000",
        linkactivecolor1: "#000",

        menubordercolor: "#cdcdcd",
        menubordersize: 1,

        excludesidemenu: ["/", "/index", "/test_pagos", "/shop-cart", "/login", "/shop-cart", "/shop-checkout", "/shop-payment-successful"],
        sidebarwidth: 260,
        shopcartconfig: {icon: "shopping-cart", text: "", color: "#000"},

        "es-es": {
            currency: "Bs.",
            joinwaitlist: "Unirse a la lista de Espera",
            mob_joinwaitlist: "lista de Espera",
            homelabel: "Inicio",
            company: "Compa\u00F1\u00EDa",
            contact: "Contacto",
            welcomeh1: "Bienvenido",
            signup: "Registrarse",
            signupmessage: "Sign up to create an account and explore many things",
            agreeTermsRegister: "I agree to the Privacy Policy and Terms of Use",
            signin: "Ingresar",
            signin2: "Ingresar",
            or: "O",
            formfullname: "Nombres",
            formphonenumber: "Numero de Telefono",
            formemail: "Correo electr\u00F3nico",
            formlogingoogle: "Ingrese con Google",
            formloginfacebook: "Ingrese con Facebook",
            formpassword: "Password",
            formpassword2: "Repita el password",
            createaccount: "No tienes una cuenta?",
            gootologin: "Tienes una cuenta?",
            forgotpassword: "Olvido su password",
            myaccountlabel: "Mi cuenta",
            logoutlabel: "Salir",
            editlabel: "Editar Registro",
            createlabel: "Crear Nuevo",
            filterlabel: "Filtros",
            actionslabel: "Acciones",
            deleteItemlabel: "Eliminar Registro",
            deletelabel: "Eliminar",
            cancellabel: "Cancelar",
            previewlabel: "Vista Previa",
            viewlabel: "Ver Detalles",
            refreshlabel: "Refrescar",
            filterlabelsmessage: "Filtro por etiqueta",
            novaluesmessage: "No hay datos",
            deteledisclaimer: "Esta seguro de eliminar este registro?",
            notloggedmessage1: "Want to know more about your property?",
            notloggedmessage2: "Sign-up today to learn more.",
            savedatalabel: "Guardar Datos",
            chooseimagelabel: "Elegir Imagen",
            changeimagelabel: "Cambiar Imagen",
            createfieldslabel: "Crear Items",
            editfieldslabel: "Administrar Items",
            createlistlabel: "Crear Lista",
            editlistlabel: "Editar Lista",
            createitemslabel: "Crear Elementos",
            edititemslabel: "Editar Elementos",
            addfieldlabel: "Agregar Campo",
            addcolumnlabel: "Agregar Columna",
            savelabel: "Aceptar",
            fieldnamelabel: "Nombre del Campo",
            fieldlabellabel: "Etiqueta del Campo",
            fieldtypelabel: "Tipo de Campo",
            colsizemobilelabel: "Tama\u00F1o Mobile",
            colsizeweblabel: "Tama\u00F1o Web",
            stylelabel: "Estilo",
            validationsabel: "Validaciones",
            fontsizelabel: "Tama\u00F1o del Texto",
            fontcolorlabel: "Color",
            alertmessagelabel: "Mensaje de Alerta",
            fieldrequiredlabel: "Campo Requerido",
            justtextlabel: "Solo Texto",
            justnumberlabel: "Solo N\u00FAmero",
            justemaillabel: "Solo email",
            minvaluelabel: "Valor m\u00EDnimo",
            maxvaluelabel: "Valor m\u00E1ximo",
            staticdatalabel: "Datos Estaticos",
            dynamiclabel: "Datos din\u00E1micos",
            maxsizemobilemessage: "En la versi\u00F3 movil solo se podra dividir hasta en 2 por fila",
            multimediamanagerlabel: "Gestor Multimedia",
            pathlabel: "Ruta",
            searchinputlabel: "Buscar Archivo",
            searchlabel: "Buscar",
            backlabel: "Atrás",
            previouslabel: "Anterior",
            nextlabel: "Siguiente",
            testscriptlabel: "Probar Script",
            loadformlabel: "Carga Formulario",
            refreshingformlabel: "Refrescando",
            optionslabel: "Opciones",
            urlapilabel: "url de la API",
            methodlabel: "Metodo",
            datatosendlabel: "Datos a enviar",
            tokenlabel: "Token",
            labellabel: "Etiqueta",
            valuelabel: "Valor",
            fieldactionlabel: "Accion",
            confirmlabel: "Confirmar Datos",
            modifydatalabel: "Modificar Datos",
            paylabel: "Donar Ahora",
            organizationlabel: "Tienda",
            productlabel: "Producto",
            validatingpaymentlabel: "Procesando tu donaci\u00F3n",
            changepasslabel: "Cambiar Contrase\u00F1a",
            changelabel: "Cambiar",
        },

        "en-us": {
            currency: "$",
            joinwaitlist: "Join Waitlist",
            mob_joinwaitlist: "Join Waitlist",
            homelabel: "Home",
            company: "Company",
            contact: "Contact",
            welcomeh1: "Welcome",
            signup: "Sign Up",
            signupmessage: "Sign up to create an account and explore many things",
            agreeTermsRegister: "I agree to the Privacy Policy and Terms of Use",
            signin: "Sign-in",
            signin2: "Sign-in",
            or: "OR",
            formfullname: "Full Name",
            formphonenumber: "Phone Number",
            formemail: "Email Address",
            formlogingoogle: "Sign up with Google",
            formloginfacebook: "Sign up with Facebook",
            formpassword: "Password",
            formpassword2: "Re-type password",
            createaccount: "Don't you have an account?",
            gootologin: "Do you already have an account?",
            forgotpassword: "Forgot password?",
            myaccountlabel: "My Account",
            logoutlabel: "Logout",
            editlabel: "Edit Item",
            createlabel: "Create new",
            filterlabel: "Filters",
            actionslabel: "Actions",
            deleteItemlabel: "Delete Item",
            deletelabel: "Delete",
            cancellabel: "Cancel",
            backlabel: "Back",
            previewlabel: "Preview",
            viewlabel: "View Details",
            refreshlabel: "Refresh",
            filterlabelsmessage: "Filter labels",
            novaluesmessage: "No labels",
            deteledisclaimer: "Are you sure to delete this item",
            notloggedmessage1: "Want to know more about your property?",
            notloggedmessage2: "Sign-up today to learn more.",
            savedatalabel: "Save Data",
            chooseimagelabel: "Choose Image",
            changeimagelabel: "Change Image",
            createfieldslabel: "Create Items",
            editfieldslabel: "Manage Items",
            createlistlabel: "Create List",
            editlistlabel: "Update List",
            createitemslabel: "Create Items",
            edititemslabel: "Update Items",
            addfieldlabel: "Add Field",
            addcolumnlabel: "Add Column",
            savelabel: "Save",
            fieldnamelabel: "Field Name",
            fieldlabellabel: "Label Name",
            fieldtypelabel: "Field Type",
            colsizemobilelabel: "Mobile Size",
            colsizeweblabel: "Web Size",
            stylelabel: "Style",
            validationsabel: "Validations",
            fontsizelabel: "Font Size",
            fontcolorlabel: "Color",
            alertmessagelabel: "Alert Message",
            fieldrequiredlabel: "Field Required",
            justtextlabel: "Just Text",
            justnumberlabel: "Just Number",
            justemaillabel: "Just email",
            minvaluelabel: "Min value",
            maxvaluelabel: "Max value",
            staticdatalabel: "Static Data",
            dynamiclabel: "Dynamic Data",
            maxsizemobilemessage: "Mobile Version only accepts max 2 fields per Column",
            multimediamanagerlabel: "Multimedia Manager",
            pathlabel: "Path",
            searchinputlabel: "Search File",
            searchlabel: "Search",
            previouslabel: "Previous",
            nextlabel: "Next",
            testscriptlabel: "Test Script",
            loadformlabel: "Load Form",
            refreshingformlabel: "Refresing...",
            optionslabel: "Options",
            urlapilabel: "API url",
            methodlabel: "Method",
            datatosendlabel: "Data to send",
            tokenlabel: "Token",
            labellabel: "Label",
            valuelabel: "value",
            fieldactionlabel: "Action",
            confirmlabel: "Confirm",
            organizationlabel: "Store",
            productlabel: "Product",
            changepasslabel: "Change Password",
            changelabel: "Change",
        },
    },
    conpanyInfo: process.env.REACT_APP_COMPANYNAME,
    //reference emal
    RefEmail: "mail@mail.com",

    //home header link items
    MenuLinks: {
        "en-us": [
            {mainLink: "Home", subLink: [], path: '/'},/*
			{ mainLink: "FAQs", subLink: [], path: '/faqs' },
	    { mainLink: "Blog", subLink: [], path: '/blogs' },    */
        ],
        "es-es": [
            {mainLink: "Inicio", subLink: [], path: '/'},/*
			{ mainLink: "FAQs", subLink: [], path: '/faqs' },
	    { mainLink: "Blog", subLink: [], path: '/blogs' },  */
        ],
    },

    SideBarLinks: {
        "en-us": [
            {label: "Dashboard", subLink: [], path: '/dashboard'},
            {label: "Project Planner", subLink: [], path: '/project-planer'},
            {label: "Equity", subLink: [], path: '/blogs'},
            {label: "Renovation", subLink: [], path: '/blogs'},
            {label: "Resources", subLink: [], path: '/blogs'},
            {label: "Account Settings", subLink: [], path: '/blogs'},
            {label: "Log-off", subLink: [], path: '/blogs'},
        ],
        "es-es": [
            {label: "Dashboard", subLink: [], path: '/dashboard'},
            {label: "Project Planner", subLink: [], path: '/project-planer'},
            {label: "Equity", subLink: [], path: '/blogs'},
            {label: "Renovation", subLink: [], path: '/blogs'},
            {label: "Resources", subLink: [], path: '/blogs'},
            {label: "Account Settings", subLink: [], path: '/blogs'},
            {label: "Log-off", subLink: [], path: '/blogs'},
        ],
    },

    //home header link items
    footerLinks: {
        "en-us": [
            {mainLink: "Terms Of Service", path: '/home'},
            {mainLink: "Privacy Policy", path: '/faqs'},
        ],
        "es-es": [
            {mainLink: "Terminos y Condiciones", path: '/home'},
            {mainLink: "Politica de Privacidad", path: '/faqs'},
        ],
    },

    //home header steps
    footerSteps: {
        "en-us": [
            "1. The Chloe HELOC is a variable rate product. The annual percentage rate (APR) is based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal \"Money Rates\" table (the \"Index\"). The Index as of 03/21/2022 is 3.50%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account.",
            "2. Some transactions may require in-person signatures, including loans secured by properties located in counties that do not permit recording of e-signatures or that otherwise require an in-person closing.",
        ],
        "es-es": [
            "1. The Chloe HELOC is a variable rate product. The annual percentage rate (APR) is based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal \"Money Rates\" table (the \"Index\"). The Index as of 03/21/2022 is 3.50%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account.",
            "2. Some transactions may require in-person signatures, including loans secured by properties located in counties that do not permit recording of e-signatures or that otherwise require an in-person closing.",

        ],
    },

    //home - list of services
    Services: {
        "en-us": [
            {
                icon: "./images/img1.PNG",
                title: "Renovate my home",
                button: "Discover More",
                link: "/doc/bdp_lab.pdf",
                description:
                    "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
            },
            {
                icon: "./images/img2.PNG",
                title: "Consolidate my debt",
                link: "/doc/programa_aceleracion.pdf",
                button: "Start Now",
                description:
                    "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
            },
            {
                icon: "./images/img3.PNG",
                title: "Valuate my home",
                link: "/doc/asistencia_tecnica.pdf",
                button: "Start Today",
                description:
                    "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
            },


        ],
        "es-es": [
            {
                icon: "./images/img1.PNG",
                title: "Renovate my home",
                button: "Discover More",
                link: "/doc/bdp_lab.pdf",
                description:
                    "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
            },
            {
                icon: "./images/img2.PNG",
                title: "Consolidate my debt",
                link: "/doc/programa_aceleracion.pdf",
                button: "Start Now",
                description:
                    "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
            },
            {
                icon: "./images/img3.PNG",
                title: "Valuate my home",
                link: "/doc/asistencia_tecnica.pdf",
                button: "Start Today",
                description:
                    "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
            },


        ]
    }

};
