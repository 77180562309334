import React from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Typography,
} from "@mui/material";

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import {styled} from '@mui/material/styles';
import {useDispatch, useSelector} from "react-redux";
import * as UserActions from "../../redux/actions/user.actions";

const FBButton = styled(Button)(({theme}) => ({
    color: "#fff",
    backgroundColor: theme.palette.googleFacebookColor,
    '&:hover': {
        backgroundColor: theme.palette.googleFacebookColor,
    },
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 10
}));

const FixedButton = styled(Button)(({theme}) => ({
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 10
}));

const label = {inputProps: {'aria-label': 'Checkbox agree'}};

const RegisterForm = props => {
    const dispatch = useDispatch();
    const {defConfig, params, defLang, activeForm} = useSelector((state) => state.Login);
    const [showPassword, setShowPassword] = React.useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };


    return (
        <Box
            sx={{
                mt: {xs: 0, md: 0, lg: 2},
                ml: {xs: -2, md: 0, lg: 2},
                mr: {xs: -2, md: 0, lg: 2},
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Grid spacing={1} container component="form" noValidate onSubmit={handleSubmit}>

                <Grid item xs={12} sx={{pl: 0, display: {xs: "block", md: "none", lg: "none"}}}>
                    <img style={{width: "100%"}} src="/images/m-logo-top-panel.png"/>
                </Grid>

                <Grid item xs={12} sx={{mt: 1, ml: {xs: 3, md: 0}, mr: {xs: 5, md: 0}}}>
                    <Typography variant="h3" sx={{mb: 2}} fontWeight="bolder" color="primary">
                        {defConfig[defLang].signup}
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, mb: 4}} color="secondary">
                        {defConfig[defLang].signupmessage}
                    </Typography>

                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="fullname">{defConfig[defLang].formfullname}</InputLabel>
                        <OutlinedInput
                            sx={{backgroundColor: "forms.bgLoginTextField"}}
                            id="fullname"
                            name="fullname"
                            type="text"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                    >
                                        <DnsRoundedIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={defConfig[defLang].formfullname}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{mt: 1, ml: {xs: 3, md: 0}, mr: {xs: 5, md: 0}}}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="password">{defConfig[defLang].formemail}</InputLabel>
                        <OutlinedInput
                            sx={{backgroundColor: "forms.bgLoginTextField"}}
                            id="email"
                            name="email"
                            type="text"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                    >
                                        <MailOutlineIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={defConfig[defLang].formemail}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{mt: 1, ml: {xs: 3, md: 0}, mr: {xs: 5, md: 0}}}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="password">{defConfig[defLang].formphonenumber}</InputLabel>
                        <OutlinedInput
                            sx={{backgroundColor: "forms.bgLoginTextField"}}
                            id="phonenumber"
                            name="phonenumber"
                            type="text"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                    >
                                        <LocalPhoneRoundedIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={defConfig[defLang].formphonenumber}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6} sx={{mt: 1, ml: {xs: 3, md: 0}, mr: {xs: 5, md: 0}}}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="password">{defConfig[defLang].formpassword}</InputLabel>
                        <OutlinedInput
                            sx={{backgroundColor: "forms.bgLoginTextField"}}
                            id="password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <LockOpenIcon/> : <LockIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={defConfig[defLang].formpassword}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{mt: 1, ml: {xs: 3, md: 0}, mr: {xs: 5, md: 0}}}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="password2">{defConfig[defLang].formpassword2}</InputLabel>
                        <OutlinedInput
                            sx={{backgroundColor: "forms.bgLoginTextField"}}
                            id="password2"
                            name="password2"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <LockOpenIcon/> : <LockIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={defConfig[defLang].formpassword2}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{mt: 1, ml: {xs: 3, md: 0}, mr: {xs: 5, md: 0}}}>
                    <FormControlLabel
                        control={<Checkbox

                            defaultChecked
                            sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}

                            value="agree" color="primary"/>}
                        label={defConfig[defLang].agreeTermsRegister}
                    />
                </Grid>
                <Grid item xs={12} sx={{mt: 1, ml: {xs: 3, md: 0}, mr: {xs: 5, md: 0}}}>
                    <FixedButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 2,
                            mb: 2,
                            textTransform: "initial",
                            fontWeight: {xs: "bold", md: "normal"},
                            fontSize: {xs: "0.9rem", md: "0.685rem"}
                        }}
                    >
                        {defConfig[defLang].signup}
                    </FixedButton>
                    <Divider>
                        <Typography variant="caption">
                            {defConfig[defLang].or}
                        </Typography>
                    </Divider>
                </Grid>


            </Grid>
            <Grid container sx={{mt: 1, mb: 1}}>
                <Grid item xs sx={{mt: 1, ml: {xs: 3, md: 0}, mr: {xs: 5, md: 0}}}>
                    <Link href="#" variant="body2" onClick={() => {
                        dispatch(UserActions.activateForm("forgot"));
                    }}>
                        {defConfig[defLang].forgotpassword}
                    </Link>
                </Grid>
                <Grid item sx={{mt: 1, ml: {xs: 3, md: 0}, mr: {xs: 5, md: 0}}}>
                    <Link href="#" variant="body2" onClick={() => {
                        dispatch(UserActions.activateForm("login"));
                    }}>
                        {defConfig[defLang].gootologin}
                    </Link>
                </Grid>
            </Grid>
        </Box>


    )
};

export default RegisterForm;
