export default {
  INIT_APP: "INIT_APP",
  ACTIVE_FORM: "ACTIVE_FORM",
  USER_LOADED: "USER_LOADED",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  SHOW_DASHBOARD: "SHOW_DASHBOARD",
  HIDE_DASHBOARD: "HIDE_DASHBOARD",
  LOGOUT: "LOGOUT",
  PROCESS_REQUEST: "PROCESS_REQUEST",
  PROCESS_SUCCESS: "PROCESS_SUCCESS",
  PROCESS_FAILED: "PROCESS_FAILED",
  PROCESS_ERROR: "PROCESS_ERROR",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",
  PROCESS_RESET: "PROCESS_RESET",
  FORM_LOADED: "FORM_LOADED",
  FORM_ERROR: "FORM_ERROR",
  CLEAR_FORM: "CLEAR_FORM",
  COMPONENT_LOADED: "COMPONENT_LOADED",
  COMPONENT_ERROR: "COMPONENT_ERROR",
  SAVE_DONATION_PERSONAL_DATA: "SAVE_DONATION_PERSONAL_DATA",
};
