import ApiClient from "../api/ApiClient";
import EndPoint from "../api/Endpoints";
import axios from "axios";
import decode from 'jwt-decode';
import {createFileFolder, deleteFile, FetchFiles, uploadFile} from '../services/process.service';
import * as yup from "yup";
import {toast} from "react-toastify";

const BASE_URL = process.env.REACT_APP_API_CONFIG;

const api = axios.create({
    baseURL: process.env.REACT_APP_API_CONFIG
    // timeout: 1000,
});

export function createValidationSchema(fields) {

    const ObjectSchema = fields.reduce((schema, field) => {
        if (field?.validations?.length) {
            if (field.validations.length > 0) {
                schema[field.name] = field.validations.reduce(
                    (yup, type) => {
                        if (field.params[type]) {
                            const params = Array.isArray(field.params[type])
                                ? field.params[type]
                                : [field.params[type]];

                            yup = yup[type](...params);
                        } else {
                            yup = yup[type]();
                        }

                        return yup;
                    },
                    {...yup}
                );
            } else {
                console.log("nova la val")
            }
        }

        return schema;
    }, {});

    return yup.object().shape({...ObjectSchema});
}

const parse_res = api => api.then(data => data.data);


export async function searchHandler(url, fieldsToEval, _originalTableData) {

    var newRows = []
    console.log("buscando", url, fieldsToEval)

    let formData = {}

    for (let i in fieldsToEval) {
        let field = fieldsToEval[i]
        formData[field.field] = field.value
    }

    let response = await ApiClient.doPost(url, formData);


    response.result.map(function (key, index) {
        key["id"] = index + 1

    });

//	console.log("response",response.result)

    return {
        tableData: response.result
    }

    /*
        if (fieldsToEval.length>0){
            _originalTableData.map(function (item) {
                //console.log("check", item)
                let isValid = true
                for (let key in fieldsToEval){
                    // eslint-disable-next-line no-loop-func
                    Object.keys(item).map(function (col) {

                        if (isValid){


                            let parseField = fieldsToEval[key].field.split("__")
                            let fieldName = parseField[0]
                            //console.log("check eval ", parseField)
                            if (fieldName == col){

                                if ((item[col])){


                                    if ( fieldsToEval[key].type =="TEXT"){

                                        if ( (item[col]+"" ).toUpperCase().indexOf( (fieldsToEval[key].value+"").toUpperCase() ) < 0)  {
                                            isValid=false

                                        }
                                    }else if  ( fieldsToEval[key].type =="NUMBER"){
                                        if ( item[col] != fieldsToEval[key].value )  {
                                            isValid=false

                                        }
                                    }else if  ( fieldsToEval[key].type =="SELECT"){
                                        if ( fieldsToEval[key].value!== "_ALL_" )  {
                                            if ( item[col] != fieldsToEval[key].value )  {
                                                isValid=false

                                            }
                                        }
                                    }else if  ( fieldsToEval[key].type =="DATE"){
                                        console.log("eval", col, item[col] , parseField[1] )
                                        let dateType = parseField[1]
                                        let currDate = new Date(item[col])
                                        if( dateType=="FROMDATE") {
                                            let from = new Date(fieldsToEval[key].value)
                                            if ( currDate < from )  {
                                                isValid=false
                                            }
                                        }

                                        if( dateType=="TODATE") {
                                            let to = new Date(fieldsToEval[key].value)
                                            if ( currDate > to )  {
                                                isValid=false
                                            }
                                        }

                                    }
                                }else{

                                    isValid=false
                                }

                            }
                        }
                    })
                }
                if (isValid){
                    //console.log("es valido", item)
                    newRows.push(item)
                }
            });
        }
    */


}


export function filterHandler(event, _filterConfig, _tableData) {
    console.log("hola")
    console.log(event.target.name)

    var filterConfig = _filterConfig

    if (_filterConfig[event.target.name]) {
        //console.log("existe")
        //console.log("val", event.target.value)
        filterConfig[event.target.name].value = event.target.value
        return {
            filterConfig: _filterConfig,
        }
    } else if (event.target.name == "search") {

        //console.log("actu", _tableData.rows.length)
        var tableData = {columns: _tableData.columns, rows: []}
        var newRows = []

        var fieldsToEval = []
        Object.keys(_filterConfig).map(function (key) {
            let item = _filterConfig[key]
            if (item.value != "") {
                fieldsToEval.push({field: key, value: item.value, type: item.type})
            }
        });

        if (fieldsToEval.length > 0) {
            _tableData.rows.map(function (item) {
                let isValid = true
                for (let key in fieldsToEval) {
                    Object.keys(item).map(function (col) {
                        if (isValid) {
                            if (fieldsToEval[key].field == col) {

                                if ((item[col])) {
                                    if (fieldsToEval[key].type == "TEXT") {
                                        if ((item[col]).toUpperCase().indexOf((fieldsToEval[key].value).toUpperCase()) < 0) {
                                            isValid = false
                                        }
                                    } else if (fieldsToEval[key].type == "NUMBER") {
                                        if (item[col] != fieldsToEval[key].value) {
                                            isValid = false
                                        }
                                    } else if (fieldsToEval[key].type == "SELECT") {
                                        if (item[col] != fieldsToEval[key].value) {
                                            isValid = false
                                        }
                                    }
                                } else {
                                    isValid = false
                                }

                            }
                        }
                    })
                }
                if (isValid) {
                    newRows.push(item)
                }
            });
            tableData["rows"] = newRows

        } else {
            tableData = {columns: _tableData.columns, rows: _tableData.rows}
        }
        return {
            tableData: tableData
        }
    } else if (event.target.name == "refresh") {

        Object.keys(_filterConfig).map(function (key) {
            let item = _filterConfig[key]
            item.value = ""
        });

        return {
            filterConfig: _filterConfig,
            tableData: _tableData
        }
    }
}

export async function callProtectedApi(api2, formData) {
    var resp
    var token = getCookie(process.env.REACT_APP_NAME + "_usertoken")
    await axios({
        url: BASE_URL + api2,
        method: 'post',
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer ' + token
        },
    }).then(data => {
        resp = data
    }).catch(function (error) {
        console.log(">>>>>>>>", error)
        resp = {success: false}
        return (resp)
    })
}

export function find(_json, _value, _key) {
    var pos = -1
    _json.map(function (key, item) {
        if (key[_key] == _value) {
            pos = item
        }
    });
    return pos
}

export function findObjName(_event) {

    var continueSerching = true
    var objName = "";
    var event = _event.target
    while (continueSerching) {
        if (event.name) {
            continueSerching = false
            objName = event.name ? event.name : event.attributes.name.nodeValue
        } else {
            event = event.parentNode
        }
    }
    return objName
}

export function getUserName() {
    return getCookie(process.env.REACT_APP_NAME + "_username")
}

export function getToken() {
    return getCookie(process.env.REACT_APP_NAME + "_usertoken")
}

export function getUserRol() {
    var role
    try {
        if (getToken() != undefined) {
            if (typeof (getToken()) != undefined) {
                let profile = decode(getToken(), process.env.REACT_APP_SECRET_KEY);
                role = profile.result.USERGROUPID
            }
        }
    } catch (exc) {
        //console.log("Exc >>>>>")
    }
    return role;
}

export function getUserInfo() {
    var info = {}
    try {
        if (getToken() != undefined) {
            if (typeof (getToken()) != undefined) {
                let profile = decode(getToken(), process.env.REACT_APP_SECRET_KEY);
                info = profile.result
            }
        }
    } catch (exc) {
        //console.log("Exc >>>>>", exc)
    }
    return info;
}

export function parseDate(f) {
    var fecha = ""
    try {
        var yy = f.getFullYear();
        var mm = (f.getMonth() + 1) < 10 ? "0" + (f.getMonth() + 1) : (f.getMonth() + 1);
        var dd = (f.getDate()) < 10 ? "0" + (f.getDate()) : (f.getDate());
        var hh = (f.getHours()) < 10 ? "0" + (f.getHours()) : (f.getHours());
        var mi = (f.getMinutes()) < 10 ? "0" + (f.getMinutes()) : (f.getMinutes());
        var se = (f.getSeconds()) < 10 ? "0" + (f.getSeconds()) : (f.getSeconds());

        var fecha = yy + "-" + mm + "-" + dd + " " + hh + ":" + mi + ":" + se;
    } catch (Excep) {
        fecha = ""
    } finally {
        return fecha;
    }
}

export function setCookie(name, value, hours) {
    try {
        var expires;
        if (hours) {
            var date = new Date();
            date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }


        document.cookie = name + "=" + value + expires + "; path=/";
    } catch (err) {
        console.log(">>>>>>> ERR SETTING COOKIE", err)

    }
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    var resp

    for (let i = 0; i < ca.length; i++) {
        let _c = ca[i].trim()
        let c = _c.split("=")
        let val = c[0] + "=";

        if (val.indexOf(nameEQ) == 0) {
            return _c.substring(nameEQ.length, _c.length);
        }
        /*while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);

            console.log("CHECK WITH:",c )

            if (c.indexOf(nameEQ) == 0) {
                console.log("ENCONTRADO", c)
                return c.substring(nameEQ.length, c.length);
            }
          }
          */
    }
    return resp;
}

export async function manageFile(event, _objFile, _files) {
    var objFile

    try {
        var action, data

        if (event.target) {
            var _data = findObjName(event)
            var data = _data.split("_")
            action = data[0]
        } else {
            var data = event.split("_")
            action = data[0]
        }
        //console.log(data, action)

        _objFile["selected"] = []
        _objFile["selectedName"] = null
        _objFile["selectedType"] = null
        if (action === "selectItem") {
            let item = _objFile.items [data[1]]
            if (item.type == "folder") {

                objFile = await listFiles(item.name, _objFile.objectName, [])
                objFile["route"] = item.name

            } else {
                objFile = _objFile
                objFile["selected"] = item.path
                objFile["selectedName"] = item.label
                _objFile["selectedType"] = item.type
            }
        } else if (action === "getParent") {
            //objFile = await listFiles ("",_objFile.objectName, _objFile.selected )
            //objFile["route"] = ""
            var routes = _objFile.route.split("/")
            console.log(routes)
            let newRoute = ""
            for (let i = 0; i < routes.length - 2; i++) {
                newRoute = newRoute + routes[i] + "/"
            }

            if (routes.length > 1) {
                objFile = await listFiles(newRoute, _objFile.objectName, _objFile.selected)
            } else {
                objFile = _objFile
            }
        } else if (action === "refresh") {

            var ContinuationToken
            if (_objFile.ContinuationToken) {
                if (_objFile.ContinuationToken != "") {
                    ContinuationToken = _objFile.ContinuationToken
                }
            }
            objFile = await listFiles(_objFile.route, _objFile.objectName, _objFile.selected, ContinuationToken)
        } else if (action === "showFolderName") {
            objFile = _objFile
            objFile.showFolderName = objFile.showFolderName ? false : true
            objFile.showUploadFile = false
            objFile.newFolderValue = ""

        } else if (action === "showUploadFile") {
            objFile = _objFile
            objFile.showUploadFile = objFile.showUploadFile ? false : true
            objFile.showFolderName = false
        } else if (action === "newFolderValue") {
            objFile = _objFile
            const pattern = new RegExp('^[0-9A-Z_-]+$', 'i');
            if (pattern.test(event.target.value)) {
                objFile.newFolderValue = event.target.value
            }
        } else if (action === "createFolder") {
            let folderName = ""
            if (_objFile.route === "") {
                folderName = _objFile.newFolderValue
            } else {
                folderName = _objFile.route + _objFile.newFolderValue
            }
            var createFolder = await createFileFolder({foldername: folderName + "/"});
            objFile = await listFiles(_objFile.route, _objFile.objectName, _objFile.selected)
            objFile.showFolderName = false
        } else if (action === "checkItem") {
            var objFile = _objFile

            if (objFile.originalValues[data[1]].checked) {

                let pos = _objFile.selected.indexOf(objFile.originalValues[data[1]].name)
                _objFile.selected.splice(pos, 1)
            } else {
                _objFile.selected.push(objFile.originalValues[data[1]].path)
            }
            //console.log(_objFile.selected)

            objFile.originalValues[data[1]].checked = objFile.originalValues[data[1]].checked ? false : true;

            /*
             }else if (action==="inputNewFile"){
                var objFile = _objFile
                objFile.inputNewFile= event.target.files[0]*/
        } else if (action === "uploadFile") {

            for (let f in _files) {
                //console.log(_files[f])
                const formData = new FormData();
                formData.append(
                    "file",
                    _files[f],
                    _files[f].name
                );

                if (_objFile.route) {
                    if (_objFile.route != "") {
                        formData.append("foldername", _objFile.route);
                    }
                }
                let respU = await uploadFile(formData, _files[f].name)
                //console.log(respU)
            }

            console.log(_objFile.route)
            objFile = await listFiles(_objFile.route, _objFile.objectName, _objFile.selected)
        } else if (action === "deleteItems") {
            var folder = ""

            if (_objFile.route) {
                _objFile["route"] = _objFile.route
                folder = _objFile.route
            }
            console.log(_objFile.items)
            var isValid = false
            for (let i in _objFile.items) {
                if (_objFile.items[i].checked) {
                    isValid = true
                    let resp = await deleteFile({
                        archivo_id: _objFile.items[i].archivo_id,
                        path: _objFile.items[i].path
                    })
                }
            }
            if (isValid) {
                objFile = await listFiles(folder, _objFile.objectName, _objFile.selected)
                toast.error("Item(s) eliminados");
            } else {
                objFile = _objFile
                toast.error("No items");
            }
        } else if (action === "selectMultiple") {
            /*	var objSelected= []
                for (let i in _objFile.items ){
                     if(_objFile.items[i].checked){
                         objSelected.push(_objFile.items[i].name)
                     }
                 }
                 objFile = _objFile
                 objFile["selected"] = objSelected*/
            //console.log(	_objFile["selected"])
            objFile = _objFile
        } else if (action === "manageInput") {
            objFile = _objFile
            objFile["objectName"] = data[1]
            objFile["selected"] = [(event.target.value)]
        } else if (action === "ContinuationToken") {
            var PreviousToken
            var currHistory = _objFile.history
            if (currHistory) {
                if (currHistory.length) {
                    let pos = find(currHistory, _objFile.ContinuationToken, "nextToken")
                    if (pos >= 0) {
                        PreviousToken = currHistory[pos].prevToken
                    }
                }
            }
            objFile = await listFiles(_objFile.route, _objFile.objectName, _objFile.selected, PreviousToken)
            objFile["history"] = currHistory

        } else if (action === "NextContinuationToken") {

            let history = _objFile.history ? _objFile.history : []
            if (history.length == 0) {
                history.push({prevToken: "", nextToken: _objFile.NextContinuationToken})
            }
            objFile = await listFiles(_objFile.route, _objFile.objectName, _objFile.selected, _objFile.NextContinuationToken)
            var isValid = true
            for (let h in history) {
                if (history[h].prevToken == objFile.ContinuationToken && history[h].nextToken == objFile.NextContinuationToken) {
                    isValid = false
                }
            }
            if (isValid) {
                history.push({prevToken: objFile.ContinuationToken, nextToken: objFile.NextContinuationToken})
            }
            objFile["history"] = history
        } else if (action === "filterValue") {
            objFile = _objFile
            objFile["filterValue"] = (event.target.value).toUpperCase()
            if (!objFile["originalValues"]) {
                objFile["originalValues"] = objFile["items"]
            }

            if (event.target.value.length >= 3) {
                let searchValue = (event.target.value + "").toUpperCase()
                let newList = []
                for (let i in objFile["originalValues"]) {
                    let item = objFile["originalValues"][i].label
                    if (item.toUpperCase().indexOf(searchValue + "") >= 0) {
                        newList.push(objFile["originalValues"][i])
                    }
                }
                objFile["items"] = newList
            } else {
                objFile["items"] = objFile["originalValues"]
            }

        } else {
            console.log(">>>>>>>>", "No valid option", action)
            objFile = _objFile
        }

    } catch (Excep) {
        console.log(">>>>>>>>", Excep)
        return false
    } finally {
        return objFile
    }
}

export async function listFiles(_foldername, _objectName, _selected, _ContinuationToken) {

    //console.log(_foldername)
    var items = []
    var item = 0

    //var resp = await  FetchFiles( { foldername: _foldername } );
    //console.log("_foldername",_foldername)
    /*
    if (_foldername==""){

        for (let f in resp.data ){
            let data = resp.data[f].route.split("/")
            let folder =  (data[ data.length-2 ])
            let pos = find (items, folder, "name")
            if  (pos<0){
                items.push ({ item:item,  name : folder, label: folder, type:"folder" } )
                item++
            }
        }
        items.sort(function (a, b){
            return ((b.name < a.name) ? -1 : ((b.name > a.name) ? 1 : 0));
        })
    }else{
        for (let f in resp.data ){
            let data = resp.data[f].route.split("/")
            let folder =  (data[ data.length-2 ])

            if (_foldername == folder){
                let file =  (data[ data.length-1 ])
                let type = "file"
                if (resp.data[f].file_type.indexOf ( "image")>=0 ){
                    type= "image"
                }
                items.push ({ item:item,  name : file, label: resp.data[f].filename, path:resp.data[f].route , type:type, archivo_id:resp.data[f].id } )
                item++
            }
        }
        items.sort(function (a, b){
            return ((b.name < a.name) ? -1 : ((b.name > a.name) ? 1 : 0));
        })
    }
    */


    var userInfo = getUserInfo()
    var forceCreate = false;
    var storepath
    if (userInfo.STORECODE) {
        storepath = "store_" + (userInfo.STORECODE.toUpperCase()) + "/"
    }
    if (_foldername == storepath) {
        forceCreate = true;
    }

    var resp = await FetchFiles({foldername: _foldername, MaxKeys: 180, forceCreate: forceCreate});
    for (let f in resp.folders) {
        if (resp.folders[f] != "/") {

            let label = resp.folders[f].split("/")[resp.folders[f].split("/").length - 2]
            items.push({item: item, name: resp.folders[f], label: label, type: "folder"})
            item++
        }
    }
    for (let f in resp.files) {
        let label = resp.files[f].filename.split("/")[(resp.files[f].filename.split("/").length - 1)]
        let isChecked = false
        if (_selected) {
            if (_selected.indexOf(resp.files[f].filepath) >= 0) {
                isChecked = true
            }
        }
        items.push({
            item: item, name: resp.files[f].filename, type: "file", label: label,
            checked: isChecked,
            path: resp.files[f].filepath
        })
        item++
    }
    items.sort(function (a, b) {
        return ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : ((b.name.toUpperCase() < a.name.toUpperCase()) ? 1 : 0));
    })

    if (items.length == 0 && forceCreate) {
        //console.log("creating store folder")
        var createFolder = await createFileFolder({foldername: _foldername});
    }

    let objFile = {
        items: items,
        originalValues: items,
        selected: _selected ? _selected : [],
        newFolderValue: "",
        route: _foldername,
        inputNewFile: null,
        filterValue: "",
        objectName: _objectName,
        ContinuationToken: resp.ContinuationToken,
        NextContinuationToken: resp.NextContinuationToken
    }
    return objFile;
}

export function hasChildren(item) {
    const {items: children} = item;

    if (children === undefined) {
        return false;
    }

    if (children.constructor !== Array) {
        return false;
    }

    if (children.length === 0) {
        return false;
    }

    return true;
}

export async function preloadForm(formData, postloadscript) {
    try {
        for (let key in formData) {
            let field = formData[key]
            if (field.type == "date") {
                field.value = new Date()
            }
            if (field.getDataDB) {

                var values = await ApiClient.getCustomData(field.getDataDB, field.apidata)
                if (values.length > 0) {
                    var fields = Object.keys(values[0])
                    values.map(function (key, item) {
                        key["value"] = key[fields[0]]
                        key["text"] = key[fields[1]]
                    });
                }
                field["values"] = values
                field["originalvalues"] = values
            }

            if (field.apiurl) {

                var apidata = {}
                var _apidata = field.apidefdata.split(",")
                for (let d in _apidata) {
                    let info = _apidata[d].split("=")
                    apidata[info[0]] = info[1]
                }
                var values = await ApiClient.getCustomData(field.apiurl, apidata)
                if (values.length > 0) {
                    var fields = Object.keys(values[0])
                    values.map(function (key, item) {
                        key["value"] = key[fields[0]]
                        key["text"] = key[fields[1]]
                    });
                }
                field["values"] = values
                field["originalvalues"] = values
            }
            if (postloadscript) {
                if ((postloadscript + "").length > 0) {
                    try {
                        await eval(postloadscript);
                    } catch (Exc) {
                        console.log("EXC:", Exc)
                        toast.error("ERROR" + Exc);
                    }
                }
            }
        }
    } catch (err) {
        console.log(">>>>>>>>>>", err)
    } finally {
        return formData
    }
}

export async function parseFormInfo(formInfo) {
    try {
        var formData = []
        var sizeWeb = formInfo.data.length;
        var maxSize = 0
        for (let i in formInfo.data) {
            let tmpSize = formInfo.data[i].length
            if (tmpSize > maxSize) {
                maxSize = tmpSize
            }
        }
        for (let j = 0; j < maxSize; j++) {
            for (let i in formInfo.data) {

                var xs = 12
                if (sizeWeb <= 2) {
                    xs = 12 / sizeWeb
                } else {
                    xs = 6
                }
                var lg = 12 / sizeWeb

                if (formInfo.data[i][j]) {
                    if (formInfo.data[i][j].size.xs != "auto") {
                        xs = formInfo.data[i][j].size.xs
                    }
                    if (formInfo.data[i][j].size.lg != "auto") {
                        lg = formInfo.data[i][j].size.lg
                    }
                    var values = []
                    var value = formInfo.data[i][j].value
                    try {
                        values = formInfo.data[i][j].values
                        if (formInfo.data[i][j].apiurl != "") {
                            //console.log("url", formInfo.data[i][j].apiurl)
                            var apidata = {}
                            var _apidata = formInfo.data[i][j].apidefdata.split(",")
                            for (let d in _apidata) {
                                let info = _apidata[d].split("=")
                                apidata[info[0]] = info[1]
                            }

                            values = await ApiClient.getCustomData(formInfo.data[i][j].apiurl, apidata)
                            if (values.length > 0) {
                                var fields = Object.keys(values[0])
                                values.map(function (key, item) {
                                    key["value"] = key[fields[0]]
                                    key["text"] = key[fields[1]]
                                });
                                if (formInfo.data[i][j].type.indexOf("autocomplete") < 0) {
                                    value = values[0][fields[0]]
                                }
                            }
                        }
                    } catch (Exc) {
                        console.log(">>> Exc getting data", formInfo.data[i][j].apiurl, formInfo.data[i][j].apidefdata)
                    }
                    var validations = []
                    var params = {}

                    var _validations = formInfo.data[i][j].validations
                    Object.entries(_validations).map(function (key, item) {
                        if (_validations[key[0]].status) {
                            if (key[0] !== "required") {
                                if (key[0] == "email") {
                                    validations.push("string")
                                    validations.push(key[0])
                                } else {
                                    validations.push(key[0])
                                }
                            }
                            if (key[0] === "min") {
                                params[key[0]] = [_validations[key[0]].min, _validations[key[0]].message]
                            } else if (key[0] === "max") {
                                params[key[0]] = [_validations[key[0]].max, _validations[key[0]].message]
                            } else {
                                params[key[0]] = _validations[key[0]].message
                            }
                        }
                    });

                    let finalField = {
                        name: formInfo.data[i][j].name,
                        label: formInfo.data[i][j].label,
                        mask: formInfo.data[i][j].mask,
                        placeholder: formInfo.data[i][j].placeholder ? formInfo.data[i][j].placeholder : formInfo.data[i][j].label,
                        type: formInfo.data[i][j].type,
                        size: {
                            xs: xs,
                            lg: lg
                        },
                        values: values,
                        originalvalues: values,
                        value: value,
                        variant: formInfo.data[i][j].variant,
                        color: formInfo.data[i][j].color,
                        action: formInfo.data[i][j].action,
                        disabled: formInfo.data[i][j].disabled,
                    }
                    if (validations.length > 0) {
                        validations.push("required")
                        finalField["validations"] = validations
                        finalField["params"] = params
                    }

                    formData.push(finalField)
                } else {
                    formData.push({
                        name: "space_" + i + "_" + j,
                        label: "",
                        type: "space",
                        size: {
                            xs: xs,
                            lg: lg
                        },
                    })
                }
            }
        }
    } catch (err) {
        console.log(">>>>>>>>>>", err)
    } finally {
        return formData
    }
}

export async function parseDataInfo(_data, postloadscript) {
    try {
        var formData = []
        var sizeWeb = _data.length;
        var maxSize = 0
        for (let i in _data) {
            let tmpSize = _data[i].length
            if (tmpSize > maxSize) {
                maxSize = tmpSize
            }
        }
        for (let j = 0; j < maxSize; j++) {
            for (let i in _data) {
                var xs = 12
                if (sizeWeb <= 2) {
                    xs = 12 / sizeWeb
                } else {
                    xs = 6
                }
                var lg = 12 / sizeWeb
                if (_data[i][j]) {
                    if (_data[i][j].size.xs != "auto") {
                        xs = _data[i][j].size.xs
                    }
                    if (_data[i][j].size.lg != "auto") {
                        lg = _data[i][j].size.lg
                    }
                    var values = []
                    var value = _data[i][j].value
                    try {
                        values = _data[i][j].values
                        if (_data[i][j].apiurl != "") {
                            //console.log("url", _data[i][j].apiurl)
                            var apidata = {}
                            var _apidata = _data[i][j].apidefdata.split(",")
                            for (let d in _apidata) {
                                let info = _apidata[d].split("=")
                                apidata[info[0]] = info[1]
                            }
                            //console.log("apidata", apidata)

                            values = await ApiClient.getCustomData(_data[i][j].apiurl, apidata)
                            if (values.length > 0) {
                                var fields = Object.keys(values[0])
                                values.map(function (key, item) {
                                    key["value"] = key[fields[0]]
                                    key["text"] = key[fields[1]]
                                });
                                if (_data[i][j].type.indexOf("autocomplete") < 0) {
                                    value = values[0][fields[0]]
                                }
                            }
                        }
                    } catch (Exc) {
                        console.log(">>> Exc getting data", _data[i][j].apiurl, _data[i][j].apidefdata)
                    }
                    var validations = []
                    var params = {}

                    var _validations = _data[i][j].validations
                    Object.entries(_validations).map(function (key, item) {
                        if (_validations[key[0]].status) {
                            if (key[0] !== "required") {
                                if (key[0] == "email") {
                                    validations.push("string")
                                    validations.push(key[0])
                                } else {
                                    validations.push(key[0])
                                }
                            }
                            if (key[0] === "min") {
                                params[key[0]] = [_validations[key[0]].min, _validations[key[0]].message]
                            } else if (key[0] === "max") {
                                params[key[0]] = [_validations[key[0]].max, _validations[key[0]].message]
                            } else {
                                params[key[0]] = _validations[key[0]].message
                            }
                        }
                    });

                    let finalField = {
                        name: _data[i][j].name,
                        mask: _data[i][j].mask,
                        label: _data[i][j].label,
                        placeholder: _data[i][j].placeholder ? _data[i][j].placeholder : _data[i][j].label,
                        type: _data[i][j].type,
                        size: {
                            xs: xs,
                            lg: lg
                        },
                        values: values,
                        originalvalues: values,
                        value: value,
                        variant: _data[i][j].variant,
                        color: _data[i][j].color,
                        apiurl: _data[i][j].apiurl,
                        apidefdata: _data[i][j].apidefdata,
                        action: _data[i][j].action,
                    }


                    if (validations.length > 0) {
                        validations.push("required")
                        finalField["validations"] = validations
                        finalField["params"] = params
                    }
                    formData.push(finalField)
                } else {
                    formData.push({
                        name: "space_" + i + "_" + j,
                        label: "",
                        type: "space",
                        size: {
                            xs: xs,
                            lg: lg
                        },
                    })
                }
            }
        }
        if (postloadscript) {
            if ((postloadscript + "").length > 0) {
                try {
                    await eval(postloadscript);
                } catch (Exc) {
                    console.log("EXC:", Exc)
                    toast.error("ERROR" + Exc);
                }
            }
        }
    } catch (err) {
        console.log(">>>>>>>>>>", err)
    } finally {
        return formData
    }
}

export function mergeFormData(formData, data) {
    var newformData = formData
    try {
        var EXTRADATA
        for (let key in newformData) {
            var field = newformData[key].name
            for (let key2 in Object.keys(data)) {
                let field2 = Object.keys(data)[key2]
                if (field2 == "DATA") {
                    EXTRADATA = data[field2]
                }
                if (field.toLowerCase() == field2.toLowerCase() && data[field2]) {

                    let fieldValue
                    if (Array.isArray(data[field2])) {
                        fieldValue = data[field2] ? data[field2] : (newformData[key].value)
                    } else {

                        fieldValue = data[field2].toString() ? data[field2].toString() : (newformData[key].value) + ""
                    }


                    if (newformData[key].type == "date") {
                        fieldValue = new Date(fieldValue)
                    }
                    if (newformData[key].type == "title") {
                        newformData[key].label += (": " + (fieldValue === null ? newformData[key].value : fieldValue));
                    }
                    newformData[key].value = fieldValue === null ? newformData[key].value : fieldValue;
                }
            }
        }
        if (EXTRADATA) {
            for (let key in newformData) {
                var field = newformData[key].name
                for (let key2 in Object.keys(EXTRADATA)) {
                    let field2 = Object.keys(EXTRADATA)[key2]
                    if (field.toLowerCase() == field2.toLowerCase()) {
                        let fieldValue = EXTRADATA[field2] ? EXTRADATA[field2] : (newformData[key].value)

                        if (newformData[key].type == "date") {
                            fieldValue = new Date(fieldValue)
                        }
                        newformData[key].value = fieldValue === null ? newformData[key].value : fieldValue;
                    }
                }
            }
        }
    } catch (err) {
        console.log(">>>>>>>>>>", err)
    } finally {
        return newformData
    }
}

export function completeForm(values, formData) {
    var newvalues = values
    try {
        for (let key in formData) {
            let field = formData[key]
            if (field.type == "autocomplete") {
                values[field.name] = field.value
            } else if (field.type == "image") {
                values[field.name] = field.value
            } else if (field.type == "multipleimages") {
                values[field.name] = field.value
            } else if (field.type == "componentsliderbanner") {
                values[field.name] = field.value
            } else if (field.type == "componentcardcontainer") {
                values[field.name] = field.value
            } else if (field.type == "date") {
                values[field.name] = field.value
            } else if (field.type == "componentformcontainer") {
                values[field.name] = field.value
            }
        }
    } catch (err) {
        console.log(">>>>>>>>>>", err)
    } finally {
        return newvalues
    }
}

export async function getInitialConf() {
    var response = {}
    try {
        response = await ApiClient.doPost(EndPoint["getInitialConf"]);
    } catch (err) {
        console.log(">>>>>>>>>>", err)
        response = {status: false, message: err}
    } finally {
        return response
    }
}

export async function prepareComponentConfig(componentName) {
    var formattedResponse = {status: false, message: "initial"};
    try {
        const response = await ApiClient.doPost(EndPoint["getDataByCodeLanguage"], {code: componentName, domainid: 4});
        if (response.status) {
            let field = response.data
            let rows = await ApiClient.doPost(field.CONFIG.getAllDataApiName, field.CONFIG.getAllDataApiData);


            field.CONFIG.columns.map(function (key) {
                key["field"] = key.id
                key["headerName"] = key.label
            });
            let id = 1
            rows.result.map(function (key, index) {
                key["id"] = index + 1

            });
            console.log("field.CONFIG.actions", field.CONFIG.actions)
            let componentConfig = {
                componentName: field.CODEDETAILDOMAIN,
                name: field.STRING_VALUE,
                columns: field.CONFIG.columns,
                filters: field.CONFIG.filters,
                getAllDataApiName: field.CONFIG.getAllDataApiName,
                getAllDataApiData: field.CONFIG.getAllDataApiData,
                getSingleDataApiName: field.CONFIG.getSingleDataApiName,
                actions: field.CONFIG.actions,
                writable: field.WRITE,
                rows: rows.result
            }
            formattedResponse = {
                status: true,
                componentConfig: componentConfig
            }
        } else {
            formattedResponse = {
                status: false,
                componentConfig: response,
                message: "NO DATA"
            }
        }

    } catch (err) {
        console.log(">>>>>>>>>>", err)
        formattedResponse = {status: false, message: "ERR:" + err};
    } finally {
        return formattedResponse
    }
}

export function getSyncComponentRows(getAllDataApiName) {
    var formattedResponse = {status: false, message: "initial"};
    try {
        ApiClient.doPost(getAllDataApiName, {})
            .then(response => {
                if (response.status) {
                    let rows = response.result

                    formattedResponse = {
                        status: true,
                        rows: rows
                    }
                    return formattedResponse
                } else {
                    formattedResponse = {
                        status: false
                    }
                    return formattedResponse
                }

            }).catch(function (error) {
            formattedResponse = {
                status: false
            }
            return formattedResponse
        })

    } catch (err) {
        console.log(">>>>>>>>>>", err)
        formattedResponse = {status: false, message: "ERR:" + err};
        return formattedResponse
    }
}

export function getRandomInt(max, length) {
    let value = Math.floor(Math.random() * max);
    let formatNumber = ""
    for (let i = 0; i < length; i++) {
        formatNumber += "0"
    }
    return (value + "").padStart(length, formatNumber)
}

export function getImagePath(ImagePath) {
    try {
        if (process.env.REACT_APP_PATH_IMAGES) {
            if (ImagePath.indexOf("http") >= 0) {
                return ImagePath
            } else if (ImagePath.indexOf("/static") >= 0) {

            } else if (ImagePath.length > 2000) {
                return `data:image/jpeg;base64,${ImagePath}`
            } else {
                return process.env.REACT_APP_PATH_IMAGES + "" + ImagePath
            }
        } else {
            return ImagePath
        }

    } catch (Excep) {
        return ""
    }
}

export async function simplePutData(url, data) {
    try {
        var instance
        var token = getCookie(process.env.REACT_APP_NAME + '_usertoken')
        const configOptions = {headers: {'Authorization': 'Bearer ' + token}};
        if (url.indexOf("http") < 0) {
            instance = axios.create({baseURL: process.env.REACT_APP_API_CONFIG});
        } else {
            instance = axios.create({});
        }

        const response = await instance.put(url, data, configOptions);
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.log("error>>>>", url, data, error)
        return null;
    }
}

export async function simplePostData(url, data) {
    try {
        var instance
        var token = getCookie(process.env.REACT_APP_NAME + '_usertoken')
        const configOptions = {headers: {'Authorization': 'Bearer ' + token}};
        if (url.indexOf("http") < 0) {
            instance = axios.create({baseURL: process.env.REACT_APP_API_CONFIG});
        } else {
            instance = axios.create({});
        }

        const response = await instance.post(url, data, configOptions);
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.log("error>>>>", url, data, error)
        return null;
    }
}

export async function simpleGetData(url, data) {
    try {

        var instance
        var token = getCookie(process.env.REACT_APP_NAME + '_usertoken')
        const configOptions = {headers: {'Authorization': 'Bearer ' + token}};
        if (url.indexOf("http") < 0) {
            instance = axios.create({baseURL: process.env.REACT_APP_API_CONFIG});
        } else {
            instance = axios.create({});
        }
        const response = await instance.get(url, data, configOptions);

        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.log("error>>>>", url, data, error)
        return null;
    }
}

export async function prepareForm(_formData, data) {
    var formData = {}
    try {

        if (Array.isArray(_formData)) {
            formData = _formData
            let resp = await preloadForm(formData)
        } else {
            formData = await parseFormInfo(_formData)
        }
        if (data) {
            if (data.id) {
                if (data.id != "new") {
                    let row = await ApiClient.doPost(data.code + "/" + "get-single", {id: data.id});
                    if (row.status) {
                        formData = mergeFormData(formData, row.data)
                    }
                }
            }
        }
        if (_formData.datapostload) {
            if ((_formData.datapostload + "").length > 0) {
                try {
                    //public apis
                    const {simpleGetData} = require("../utils/Functions");
                    const {simplePostData} = require("../utils/Functions");

                    await eval(_formData.datapostload);
                } catch (Exc) {
                    console.log("EXC:", Exc)
                    toast.error("<Error postload Action>" + Exc);
                }
            }
        }

    } catch (error) {
        console.log("error>>>>", _formData, error)
        return {};
    } finally {
        return formData

    }
}
