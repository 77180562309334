import React, {useState} from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {BrowserRouter} from "react-router-dom";
import Routes from "./routes/routes";
import {useSelector} from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import Loading from "./components/Loading";

const Route = (props) => {
    const {user, showDashboard, userLng, requestingLogin} = useSelector((state) => state.Login);
    const {requesting, processFailed, processMessage, processError, reloadPage} = useSelector((state) => state.Process);
    const [openPanel, setOpenPanel] = useState(false);

    const [isFatching, setIsFatching] = useState(true);

    const [theme, setTheme] = useState();

    React.useEffect(() => {

        const fetchFn = async () => {
            setIsFatching(true)
            const formStructure = []
            //var response = await getInitialConf();

            let palette = {
                primary: {main: '#009ce0', dark: '#009ce0', contrastText: '#fff'},
                success: {main: '#ffff00', dark: '#ffff00', light: '#ffff00', contrastText: '#000'},
                secondary: {main: '#E18335', light: '#E18335'},
                warning: {main: '#FA8B69', dark: '#FA8B69', light: '#FF3C00'},
                error: {main: '#ff454d', dark: '#ff454d', light: '#ff454d'},
                info: {
                    light: '#8EA7F7',
                    main: '#8EA7F7',
                    dark: '#8EA7F7',
                    contrastText: '#042656'
                },
                forms: {
                    bgLoginTextField: '#F6F7F9',
                    googleButtonColor: '#F6F7F9',
                    googleFacebookColor: '#1877F2'
                },
                neutral: {main: '#64748B', contrastText: '#fff'}
            };

            const _theme = createTheme({
                palette: palette,
                typography: {
                    // In Chinese and Japanese the characters are usually larger,
                    // so a smaller fontsize may be appropriate.
                    fontSize: 11,
                    fontFamily: `UniversLTStd, "Arial", Roboto, "Aleo"`,
                    /*  button: {
                        textTransform: "none"
                      }*/
                },
                overrides: {
                    MuiListItem: {
                        root: {
                            "&$selected": {
                                color: "#FFF",
                                backgroundColor: "red"
                            }
                        }
                    }
                },
            });
            setTheme(_theme)
            setIsFatching(false)
        }
        fetchFn();
        if (reloadPage) {
            window.location.reload();
        }
    }, [reloadPage])


    return (isFatching ? "" :
            <ThemeProvider theme={theme}>

                {requestingLogin && <Loading/>}
                {requesting && <Loading/>}
                <ToastContainer
                    closeButton={true}
                    autoClose={2500}
                    hideProgressBar={true}
                    position={"bottom-right"}
                />
                <BrowserRouter>
                    <Routes
                        userLng={userLng}
                        reloadPage={reloadPage}
                    />
                </BrowserRouter>
            </ThemeProvider>
    );
};

export default Route;
