import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, Container, Dialog, Grid, TextField, Typography} from "@mui/material";
import DatePicker from "react-datepicker";
import * as Actions from "../../redux/actions/process.actions";
import {useDispatch, useSelector} from "react-redux";
import {styled} from '@mui/material/styles';

const FixedButton = styled(Button)(({theme}) => ({
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 10
}));

const PauseDialog = (props) => {
    const {open, onClose} = props;
    const dispatch = useDispatch();


    const {defConfig, params, defLang, activeForm} = useSelector((state) => state.Login);

    const {
        componentConfig,
        processSuccess,
        processError,
        requesting,
        processMessage,
        formFields,
        initialValues,
        validationSchema
    } = useSelector(
        (state) => state.Process
    );
    var date = new Date();
    var defDate = date.setDate(date.getDate() + (7));
    var minDate = new Date();
    var curDate = new Date();
    const [formDate, setFormDate] = useState(defDate);
    const [formDateFrom, setFormDateFrom] = useState(minDate);
    const [amount, setAmount] = useState(null);


    const deleteItem = (event) => {
        console.log("delete")
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <Container>
                <Grid container sx={{mt: 1, mb: 2}} spacing={1}>
                    <Grid item lg={12} xs={12}>

                        <Typography
                            variant="h5"
                            style={{
                                fontWeight: "bold",
                                marginTop: 20,
                                marginBottom: 20
                            }}>
                            Selecciona la fecha final de la Pausa
                        </Typography>
                        <Grid container sx={{mt: 1,}} spacing={1}>


                            <Grid item lg={12} xs={12} sx={{mt: 1}}>
                                <div className="custom-container-datepicker">
                                    <label style={{"max-width": "90%"}} className="custom-label">
                                        <span> Fecha inicio de la pausa </span>
                                    </label>


                                    <DatePicker
                                        selected={formDateFrom}
                                        onChange={(date) => setFormDateFrom(date)}
                                        selectsStart
                                        className="custom-datepicker-2"
                                        minDate={curDate}
                                        startDate={formDateFrom}
                                        endDate={formDate}
                                        dateFormat="dd/MM/yyyy"
                                    />


                                </div>
                            </Grid>

                            <Grid item lg={12} xs={12} sx={{mt: 1}}>
                                <div className="custom-container-datepicker">
                                    <label style={{"max-width": "90%"}} className="custom-label">
                                        <span> Fecha fin de la pausa </span>
                                    </label>

                                    <DatePicker
                                        selected={formDate}
                                        onChange={(date) => setFormDate(date)}
                                        selectsEnd
                                        className="custom-datepicker-2"
                                        startDate={formDateFrom}
                                        endDate={formDate}
                                        minDate={formDateFrom}
                                        dateFormat="dd/MM/yyyy"
                                    />

                                </div>
                            </Grid>

                            <Grid item lg={12} xs={12} sx={{mt: 1}}>
                                <TextField
                                    fullWidth
                                    placeholder={"El campo no es obligatorio"}
                                    label={"Monto"}
                                    name={"amount"}
                                    value={amount}
                                    onChange={(event) => {
                                        setAmount(event.target.value)
                                    }}
                                />

                            </Grid>

                            <Grid item xs={6} md={6} lg={6}
                                  sx={{height: 280, mt: 1, mb: 1, ml: {xs: 0, md: 0}, mr: {xs: 0, md: 0}}}>
                                <FixedButton
                                    type="button"
                                    fullWidth
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        onClose()
                                    }}
                                    sx={{
                                        textTransform: "initial",
                                        fontWeight: {xs: "bold", md: "normal"},
                                        fontSize: {xs: "1rem", md: "0.9rem"}
                                    }}
                                >
                                    {defConfig[defLang].cancellabel}
                                </FixedButton>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}
                                  sx={{height: 280, mt: 1, mb: 1, ml: {xs: 0, md: 0}, mr: {xs: 0, md: 0}}}>
                                <FixedButton
                                    type="button"
                                    color="primary"
                                    fullWidth
                                    onClick={() => {
                                        dispatch(Actions.processData(props.componentName, "pause", {
                                            id: props.id,
                                            formDate: formDate,
                                            formDateFrom: formDateFrom,
                                            amount: amount
                                        }));
                                        onClose();
                                    }}
                                    variant="contained"
                                    sx={{
                                        textTransform: "initial",
                                        fontWeight: {xs: "bold", md: "normal"},
                                        fontSize: {xs: "1rem", md: "0.9rem"}
                                    }}

                                >
                                    Pausar
                                </FixedButton>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
};

PauseDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PauseDialog;
