import axios from "axios";
import {getCookie} from '../utils/Functions';


async function postData(url, data) {
    try {
        var instance = axios.create({baseURL: process.env.REACT_APP_API_CONFIG});
        var token = getCookie(process.env.REACT_APP_NAME + '_usertoken')
        const configOptions = {headers: {'Authorization': 'Bearer ' + token}};
        const response = await instance.post(url, data, configOptions);
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

class ApiClient {
    static BASE_URL = process.env.REACT_APP_API_CONFIG; //process.env.REACT_APP_API_CONFIG; "http://localhost:8080/api/v1";
    static instance = axios.create({baseURL: this.BASE_URL});

    static doGet = async (url, params = null, data = null) => {
        try {
            var token = getCookie(process.env.REACT_APP_NAME + '_usertoken')
            const configOptions = {headers: {'Authorization': 'Bearer ' + token}};
            const response = await this.instance.get(url, data, configOptions);

            if (response.status === 200) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        } catch (error) {
            throw error;
        }
    };

    static doPost = async (url, data, isMultipart = false) => {
        try {
            var token = getCookie(process.env.REACT_APP_NAME + '_usertoken')

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + token
                },
            };
            const configOptions = isMultipart ? config : {headers: {'Authorization': 'Bearer ' + token}};
            const response = await this.instance.post(url, data, configOptions);
            if (response.status === 200) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        } catch (error) {
            throw error;
        }
    };

    static doPut = async (url, data, isMultipart = false) => {
        try {
            var token = getCookie(process.env.REACT_APP_NAME + '_usertoken')

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + token
                },
            };
            const configOptions = isMultipart ? config : {headers: {'Authorization': 'Bearer ' + token}};
            const response = await this.instance.put(url, data, configOptions);
            if (response.status === 200) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        } catch (error) {
            throw error;
        }
    };
    static doDelete = async (url, params) => {
        try {
            const response = await this.instance.delete(url, {params: params});
            if (response.status === 200) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        } catch (error) {
            throw error;
        }
    };

    static getCustomData = async (apiName, data) => {
        try {
            var values = []
            var customScript = "(async () => {   const response = await postData ('" + apiName + "', " + `data` + " );  values = response.result })();"
            await eval(customScript)
        } catch (error) {
            console.log(">>>>>>>>", error)
        } finally {
            return values
        }
    };

}

export default ApiClient;


export const setApiToken = (token) => {
    ApiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
