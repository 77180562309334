import React from "react";
import {Navigate, useRoutes} from "react-router-dom";
import MainLayout from "../layout/MainLayout";

/* front pages*/
import Home from "../pages/Home";
import Home2 from "../pages/Home2";
import OneShot from "../pages/oneShot/OneShot"
import Donar from "../pages/Donar";
import Terminos from "../pages/Terminos";
import Comprobante from "../pages/Comprobante";
import Comprobante2 from "../pages/Comprobante2";
import DonacionRealizada from "../pages/DonacionRealizada";
import DonacionUnicaRealizada from "../pages/oneShot/DonacionUnicaRealizada";
import DonacionNoRealizada from "../pages/DonacionNoRealizada";
import NuestroEquipo from "../pages/NuestroEquipo";
import OneShotDonate from "../pages/oneShot/OneShotDonate"
import BasesConcurso from "../pages/BasesConcurso"
import CasaIdeas from "../pages/leads/CasaIdeas"
/* admin pages*/
import ListData from "../pages/admin/ListData";
import ManageComponent from "../pages/admin/ManageComponent";
import Login from "../pages/admin/login/Login";
import Dashboard from "../pages/admin/Dashboard";


const Routes = (props) => {

    const routes = [
        {
            path: "/",
            element: <MainLayout/>,
            children: [
                {
                    path: "/",
                    element: <Home2/>,
                },
                {
                    path: "/teletonB",
                    element: <Home2/>,
                },
                {
                    path: "/old_pagos",
                    element: <Home/>,
                },
                {
                    path: "/leads",
                    element: <CasaIdeas/>,
                },
                /*
                {
                  path: "/donar",
                  element: <Donar />,
                },
                */
                {
                    path: "/donar/:newamount",
                    element: <Donar/>,
                },
                {
                    path: "/donaciones/comprobantesXXXOld/:token",
                    element: <Comprobante/>,
                },
                {
                    path: "/donaciones/comprobantes/:token",
                    element: <Comprobante2/>,
                },
                {
                    path: "/aviso-de-privacidad",
                    element: <Terminos/>,
                },

                {
                  path: "/terminos-del-concurso",
                  element: <BasesConcurso />,
                },

                {
                    path: "/donacion-realizada",
                    element: <DonacionRealizada/>,
                },
                {
                    path: "/donacion-unica-realizada",
                    element: <DonacionUnicaRealizada/>,
                },
                {
                    path: "/error-donacion",
                    element: <DonacionNoRealizada/>,
                },

                {
                    path: "/nuestro-equipo",
                    element: <NuestroEquipo/>,
                },

                {
                    path: process.env.REACT_APP_ADMMIN_PATH + "/",
                    element: <Navigate to={process.env.REACT_APP_ADMMIN_PATH + "/dashboard/"}/>
                },


                {
                    path: process.env.REACT_APP_ADMMIN_PATH + "/dashboard",
                    element: <Dashboard/>,
                },
                {
                    path: process.env.REACT_APP_ADMMIN_PATH + "/login",
                    element: <Login/>,
                },

                {
                    path: process.env.REACT_APP_ADMMIN_PATH + "/manage/:componentName/:action/:id",
                    exact: true,
                    element: <ManageComponent/>,
                },
                {
                    path: process.env.REACT_APP_ADMMIN_PATH + "/manage/:componentName",
                    exact: true,
                    element: <ListData/>,
                },
                {
                    path: "/donacion-unica-completar/:newamount",
                    element: <OneShotDonate/>,
                },
            ],
        },
        {
            path: "/donacion-unica",
            element: <OneShot/>,
        },

    ];

    const router = useRoutes(routes);

    return <>{router}</>;
};

export default Routes;
