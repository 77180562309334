import * as React from 'react';
import PropTypes from 'prop-types';
import {styled, useTheme} from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import {useSelector} from "react-redux";
import {Card} from "@mui/material";
import {find} from '../../utils/Functions';

const StyledAutocompletePopper = styled('div')(({theme}) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${
                theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
            }`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
                {
                    backgroundColor: theme.palette.action.hover,
                },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

function PopperComponent(props) {
    const {disablePortal, anchorEl, open, ...other} = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({theme}) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${
        theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`,
    borderRadius: 6,
    width: 310,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({theme}) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
    }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${
                theme.palette.mode === 'light'
                    ? 'rgba(3, 102, 214, 0.3)'
                    : 'rgb(12, 45, 107)'
            }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
        },
    },
}));

const Button = styled(ButtonBase)(({theme}) => ({
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
    fontWeight: 600,
    '&:hover,&:focus': {
        color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
    },
    '& span': {
        width: '100%',
    },
    '& svg': {
        width: 16,
        height: 16,
    },
}));


const CompMultiAutocomplete = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState([]);
    const [pendingValue, setPendingValue] = React.useState([]);
    const theme = useTheme();

    const {defLang, defConfig} = useSelector((state) => state.Login);

    React.useEffect(() => {
        let _values = []
        if (Array.isArray(props.field.value)) {
            props.field.value.map(function (key) {
                let pos = find(props.field.originalvalues, key.toString(), "value")
                if (pos >= 0) {
                    _values.push(props.field.originalvalues[pos])
                }
            });
        } else {
            let pos = find(props.field.originalvalues, props.field.value.toString(), "value")
            if (pos >= 0) {
                _values.push(props.field.originalvalues[pos])
            }
        }
        setValue(_values)

    }, []);

    const handleClick = (event) => {
        //console.log(event)
        //console.log("value",value)
        setPendingValue(value);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {

        setValue(pendingValue);
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
        props.handleChange({target: {name: props.field.name, value: pendingValue}});
    };

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;
    const handleDelete = (event) => {
        let newValues = []

        value.map(function (key) {
            if (event.toString() != key.value.toString()) {
                newValues.push(key)
            }
        });
        props.handleChange({target: {name: props.field.name, value: newValues}});
        setValue(newValues)
    };
    return (
        <React.Fragment>
            <Card sx={{minHeight: '1.4em', px: 1, pt: 2, pb: 1}}>
                <Button disableRipple aria-describedby={id} onClick={handleClick}>
                    <span>{props.field.label}</span>
                    <SettingsIcon/>
                </Button>
                {value.map((label) => (
                    <Box
                        key={label.value}
                    >
                        <Chip color="success" style={{borderRadius: 5}} sx={{
                            fontSize: 13,
                            width: '95%',
                            mt: 1,
                            mb: 0,
                            textAlign: "left",
                            justifyContent: "left",
                            height: 35
                        }}
                              icon={<DeleteIcon/>}
                              onClick={() => handleDelete(label.value)}
                              label={label.text}/>

                    </Box>
                ))}
            </Card>

            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Box
                            sx={{
                                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                                padding: '8px 10px',
                                fontWeight: 600,
                            }}
                        >
                            {props.message ? props.message : ""}
                        </Box>
                        <Autocomplete
                            open
                            disabled={props.disabled}
                            multiple
                            onClose={(event, reason) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            value={pendingValue}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    event.key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    console.log("ret")
                                    return;
                                }
                                console.log("onChange", newValue)
                                setPendingValue(newValue);
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText={defConfig[defLang].novaluesmessage}
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Box
                                        component={DoneIcon}
                                        sx={{width: 17, height: 17, mr: '5px', ml: '-2px'}}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden',
                                        }}
                                    />
                                    <Box
                                        component="span"
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            flexShrink: 0,
                                            borderRadius: '6px',
                                            mr: 1,
                                            mt: '5px',
                                            backgroundColor: "success.light"
                                        }}
                                        /*style={{ backgroundColor: '#1b5e20' }}*/
                                    />
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            '& span': {
                                                color:
                                                    theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                                            },
                                        }}
                                        id={option.value}
                                    >
                                        {option.text}

                                    </Box>
                                    <Box
                                        component={CloseIcon}
                                        sx={{opacity: 0.6, width: 18, height: 18}}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden',
                                        }}
                                    />
                                </li>
                            )}
                            options={[...props.field.originalvalues].sort((a, b) => {
                                // Display the selected defConfig first.
                                let ai = value.indexOf(a);
                                ai = ai === -1 ? value.length + props.field.originalvalues.indexOf(a) : ai;
                                let bi = value.indexOf(b);
                                bi = bi === -1 ? value.length + props.field.originalvalues.indexOf(b) : bi;
                                return ai - bi;
                            })}
                            getOptionLabel={(option) => option.text}
                            renderInput={(params) => (
                                <StyledInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder={defConfig[defLang].filterdefConfigmessage}
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </React.Fragment>
    );
};

CompMultiAutocomplete.propTypes = {};

export default CompMultiAutocomplete;

