import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { getImagePath } from "../../utils/Functions";
import { useDispatch, useSelector } from "react-redux";
import Slide from "@mui/material/Slide";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Utils from "../../utils/Utils";
import { styled, useTheme } from "@mui/material/styles";

const FixedButton = styled(Button)(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  borderRadius: 4,
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFF",
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.success.main,
    boxShadow: theme.shadows[1],
    fontWeight: 600,
    fontSize: 14,
  },
}));

const Header = (props) => {
  const { defConfig, params, defLang } = useSelector((state) => state.Login);
  const dispatch = useDispatch();

  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [isToggled, setToggled] = useState(false);
  const [scroll, setScroll] = useState(0);

  const [isFront, setIsFront] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY >= 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
    for (let i in Utils.includeSideBarAdmin) {
      if (location.pathname === Utils.includeSideBarAdmin[i]) {
        setIsFront(false);
      }
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleToggle = () => setToggled(!isToggled);

  return !isFront ? (
    ""
  ) : (
    <>
      <header
        style={{
          background: defConfig.headerbackground1,
          color: defConfig.headercolor1,
        }}
        className={`header-area  header-height-2`}
      >
        <div
          style={{
            background: defConfig.headerbackground1,
            color: defConfig.headercolor1,
          }}
          className={
            scroll
              ? "header-bottom header-bottom-bg-color sticky-bar"
              : "header-bottom header-bottom-bg-color sticky-bar"
          }
        >
          <>
            <div className="full-container header-container">
              <div className="header-wrap">
                <div className="logo logo-width-1 ">
                  <Link to={location.pathname.includes("donar") ? "#" : "/"}>
                    <img
                      src={getImagePath(defConfig.logoimage)}
                      style={{
                        width: fullScreen
                          ? defConfig.imageheaderwidthMob
                          : defConfig.imageheaderwidth,
                      }}
                      alt={defConfig.companyname}
                    />
                  </Link>
                </div>

                <div className="header-right">
                  <div className="search-style-2"></div>
                  <div className="header-action-right">
                    <div className="header-action-2  ">
                      {defConfig[defLang].headericons?.map((item, i) => {
                        return (
                          <div
                            key={"div1" + i}
                            className="header-action-icon-2 "
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {};
export default Header;
