import "./CasaIdeas.css";

import React, { useEffect, useState } from "react";
import LinearProgressWithLabel from "../../components/loading/LinearProgressWithLabel";

import useMediaQuery from "@mui/material/useMediaQuery";
import CardContainer from "../../components/elements/CardContainer";

import { useTheme } from "@mui/styles";
import { Box, TextField } from "@mui/material";

const CasaIdeas = (props) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);

  const urlImageDesktopCasaIdeas =
    process.env.REACT_APP_URL_CONFIG +
    "/assets/imgs/casaideas/casaIdeasDesktop.jpg";
  const urlImageMobileCasaIdeas =
    process.env.REACT_APP_URL_CONFIG +
    "/assets/imgs/casaideas/casaIdeasMobile.jpg";
  const urlButton =
    process.env.REACT_APP_URL_CONFIG + "/assets/imgs/casaideas/button.png";
  const CasaIdeasDoc =
    process.env.REACT_APP_URL_CONFIG + "/assets/files/elsabordelcrecersano.pdf";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const downloadFile = async () => {
    // "https://www.unicef.org.bo/assets/files/elsabordelcrecersano.pdf"
    let response = await fetch(CasaIdeasDoc);

    const reader = response.body.getReader();

    const contentLength = +response.headers.get("Content-Length");

    let receivedLength = 0;
    let chunks = [];
    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      chunks.push(value);
      receivedLength += value.length;

      const currentProgress = Math.floor(
        (receivedLength / contentLength) * 100
      );
      if (currentProgress - progress > 3) {
        setProgress(currentProgress);
      }
    }

    let chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (let chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }

    const fileURL = window.URL.createObjectURL(new Blob([chunksAll]));
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = "elsabordelcrecersano.pdf";
    alink.click();
    setCompleted(true);
  };

  useEffect(() => {}, []);

  const bodyInfoTeleton1 = [
    {
      id: 1,
      full: "true",
      childs: [
        {
          id: 0,
          size: "",
          title: "",

          updown: "JUSTIMAGE",
          body: "",
          button: "",
          buttonlink: "",

          link: "",
          image: "",
          type: "text",
          imagepath: urlImageDesktopCasaIdeas,
        },
      ],
    },
  ];

  const bodyInfoTeleton1Mobile = [
    {
      id: 1,
      full: "true",
      childs: [
        {
          id: 0,
          size: "",
          title: "",
          updown: "JUSTIMAGE",
          body: "",
          button: "",
          buttonlink: "",
          buttonstyle: {},
          link: "#donation-form",
          image: "",
          type: "text",
          imagepath: urlImageMobileCasaIdeas,
        },
      ],
    },
  ];

  const DownloadMessage = ({ progress, fullScreen }) => (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "auto",
        alignItems: "center",
        gap: "0.3rem",
        marginTop: "10px",
      }}
    >
      <p
        style={
          fullScreen
            ? { color: "black", marginTop: "15px" }
            : { color: "white" }
        }
      >
        El archivo terminar&aacute; su descarga muy pronto.
      </p>
      <Box sx={{ width: "100%", marginLeft: "25px" }}>
        <LinearProgressWithLabel value={progress} fullScreen={fullScreen} />
      </Box>
    </div>
  );

  return (
    <>
      {fullScreen ? (
        <section className="home-slider position-relative ">
          <CardContainer
            key={"card1"}
            data={bodyInfoTeleton1Mobile}
            config={{}}
          />
          <Box
            autoComplete="off"
            component="form"
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              downloadFile();
            }}
          >
            <div>
              <TextField
                label="Nombre y Apellido"
                variant="filled"
                required
                sx={{
                  // Class for the label of the filled input field
                  "& .MuiInputLabel-filled": {
                    color: "#2e2e2e",
                    fontWeight: "bold",
                  },
                  "& .MuiFilledInput-root": {
                    backgroundColor: "#ffffff !important",
                    borderRadius: "10px !important",
                  },
                  "& .MuiFilledInput-root.Mui-focused": {
                    backgroundColor: "#ffffff",
                    borderRadius: "10px !important",
                  },
                  "& .MuiFilledInput-root :invalid": {
                    backgroundColor: "#ffffff",
                    borderRadius: "10px !important",
                  },
                }}
                style={{ width: "100%", marginTop: "15px" }}
              />
              <TextField
                label="Correo Electrónico"
                variant="filled"
                required
                type={"email"}
                sx={{
                  // Class for the label of the filled input field
                  "& .MuiInputLabel-filled": {
                    color: "#2e2e2e",
                    fontWeight: "bold",
                  },
                  "& .MuiFilledInput-root": {
                    backgroundColor: "#ffffff",
                    borderRadius: "10px !important",
                  },
                  "& .MuiFilledInput-root.Mui-focused": {
                    backgroundColor: "#ffffff",
                    borderRadius: "10px !important",
                  },
                  "& .MuiFilledInput-root :invalid": {
                    backgroundColor: "#ffffff",
                    borderRadius: "10px !important",
                  },
                }}
                style={{ width: "100%", marginTop: "15px" }}
              />
              <TextField
                label="Celular"
                variant="filled"
                type="number"
                required
                sx={{
                  // Class for the label of the filled input field
                  "& .MuiInputLabel-filled": {
                    color: "#2e2e2e",
                    fontWeight: "bold",
                  },
                  "& .MuiFilledInput-root": {
                    backgroundColor: "#ffffff",
                    borderRadius: "10px !important",
                  },
                  "& .MuiFilledInput-root.Mui-focused": {
                    backgroundColor: "#ffffff",
                    borderRadius: "10px !important",
                  },
                  "& .MuiFilledInput-root :invalid": {
                    backgroundColor: "#ffffff",
                    borderRadius: "10px !important",
                  },
                }}
                style={{ width: "100%", marginTop: "15px" }}
              />
              {!completed && (
                <>
                  {loading && (
                    <DownloadMessage
                      progress={progress}
                      fullScreen={fullScreen}
                    />
                  )}
                  {!loading && (
                    <button
                      type={"submit"}
                      style={{
                        width: "80%",
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        marginTop: "20px",
                        marginLeft: "10%",
                      }}
                    >
                      <img src={urlButton} />
                    </button>
                  )}
                </>
              )}
            </div>
          </Box>
        </section>
      ) : (
        <>
          <section className="home-slider position-relative">
            <CardContainer key={"card1"} data={bodyInfoTeleton1} config={{}} />
            <Box
              component="form"
              autoComplete="off"
              style={{
                zIndex: 9999,
                left: "12%",
                bottom: "10%",
                position: "absolute",
                width: "32%",
              }}
              onSubmit={(e) => {
                e.preventDefault();
                setLoading(true);
                downloadFile();
              }}
            >
              <div>
                <TextField
                  label="Nombre y Apellido"
                  variant="filled"
                  required
                  sx={{
                    // Class for the label of the filled input field
                    "& .MuiInputLabel-filled": {
                      color: "#2e2e2e",
                      fontWeight: "bold",
                    },
                    "& .MuiFilledInput-root": {
                      backgroundColor: "#ffffff !important",
                      borderRadius: "10px !important",
                    },
                    "& .MuiFilledInput-root.Mui-focused": {
                      backgroundColor: "#ffffff",
                      borderRadius: "10px !important",
                    },
                    "& .MuiFilledInput-root :invalid": {
                      backgroundColor: "#ffffff",
                      borderRadius: "10px !important",
                    },
                  }}
                  style={{ width: "100%", marginTop: "15px" }}
                />
                <TextField
                  label="Correo Electrónico"
                  variant="filled"
                  required
                  type={"email"}
                  sx={{
                    // Class for the label of the filled input field
                    "& .MuiInputLabel-filled": {
                      color: "#2e2e2e",
                      fontWeight: "bold",
                    },
                    "& .MuiFilledInput-root": {
                      backgroundColor: "#ffffff",
                      borderRadius: "10px !important",
                    },
                    "& .MuiFilledInput-root.Mui-focused": {
                      backgroundColor: "#ffffff",
                      borderRadius: "10px !important",
                    },
                    "& .MuiFilledInput-root :invalid": {
                      backgroundColor: "#ffffff",
                      borderRadius: "10px !important",
                    },
                  }}
                  style={{ width: "100%", marginTop: "15px" }}
                />
                <TextField
                  label="Celular"
                  variant="filled"
                  required
                  sx={{
                    // Class for the label of the filled input field
                    "& .MuiInputLabel-filled": {
                      color: "#2e2e2e",
                      fontWeight: "bold",
                    },
                    "& .MuiFilledInput-root": {
                      backgroundColor: "#ffffff",
                      borderRadius: "10px !important",
                    },
                    "& .MuiFilledInput-root.Mui-focused": {
                      backgroundColor: "#ffffff",
                      borderRadius: "10px !important",
                    },
                    "& .MuiFilledInput-root :invalid": {
                      backgroundColor: "#ffffff",
                      borderRadius: "10px !important",
                    },
                  }}
                  style={{ width: "100%", marginTop: "15px" }}
                />
                {!completed && (
                  <>
                    {loading && (
                      <DownloadMessage
                        progress={progress}
                        fullScreen={fullScreen}
                      />
                    )}
                    {!loading && (
                      <button
                        type={"submit"}
                        style={{
                          width: "50%",
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          marginTop: "20px",
                          marginLeft: "25%",
                        }}
                      >
                        <img src={urlButton} />
                      </button>
                    )}
                  </>
                )}
              </div>
            </Box>
          </section>
        </>
      )}
    </>
  );
};

CasaIdeas.propTypes = {};

export default CasaIdeas;
