import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {hasChildren} from '../../utils/Functions';
import * as ProcessActions from "../../redux/actions/process.actions";
import {
    Avatar,
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    MenuList,
    Typography
} from "@mui/material";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SidebarNav = (props) => {
    const dispatch = useDispatch();
    const {user, requesting, defLang, defConfig, params, username} = useSelector((state) => state.Login);
    const navigate = useNavigate();
    const [address, setAddress] = useState([{label: 'The Shawshank Redemption', year: 1994},
        {label: 'The Godfather', year: 1972},]);
    const handleClick = (event) => {
        if (event.link.indexOf("manage") >= 0) {
            dispatch(ProcessActions.getComponentConfig(event.code))
        }
        navigate(process.env.REACT_APP_ADMMIN_PATH + event.link)
    };

    return (
        <>
            <MenuList>
                <MenuItem>
                    <ListItem sx={{display: "flex", justifyContent: "center", mt: 4}}>
                        <Avatar
                            alt={username}
                            src="/images/users/user.png"
                            sx={{maWidth: 150, width: 150, height: 150}}
                        />
                    </ListItem>
                </MenuItem>
                <MenuItem>
                    <Typography variant="h6" sx={{pl: 1, mt: 1, mb: -2, color: "#fff"}}>
                        {defConfig[defLang].welcomeh1}
                    </Typography>

                </MenuItem>
                <MenuItem>
                    <Typography variant="h6" sx={{pl: 1, mb: 1, color: "#fff"}} fontWeight="bolder">
                        {username}
                    </Typography>
                </MenuItem>
                {/*
        <MenuItem>
        	<Autocomplete
			      disablePortal
			      size="small"
			      id="combo-address"
			      options={address}
			      fullWidth
			      value="The Godfather"
			    	sx={{ width: "95%", mt:2, mb:4 }}
			      renderInput={(params) => <TextField fullWidth

                  sx={{backgroundColor:"#fff", borderRadius:2}} {...params}  />}
			    />
        </MenuItem>  */}
            </MenuList>

            <List
                sx={{width: '100%'}}
                component="nav"
            >
                {props.menu.map((item, key) => {
                    const MenuItem = ({item}) => {
                        const Component = hasChildren(item) ? MultiLevel : SingleLevel;
                        return <Component item={item}/>;
                    };
                    const SingleLevel = ({item}) => {
                        return (
                            <ListItemButton onClick={() => {
                                handleClick(item)
                            }}>

                                <ListItemText primary={item.title}/>
                            </ListItemButton>
                        );
                    };
                    const MultiLevel = ({item}) => {
                        const {items: children} = item;
                        const [open, setOpen] = useState(false);
                        const handleClick = () => {
                            setOpen((prev) => !prev);
                        };
                        return (
                            <>
                                <ListItemButton style={{}} onClick={handleClick}>

                                    <ListItemText primary={item.title}/>
                                    {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </ListItemButton>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <List sx={{pl: 1}} component="div">
                                        {children.map((child, key) => (
                                            <MenuItem key={key} item={child}/>
                                        ))}
                                    </List>
                                </Collapse>
                            </>
                        );
                    };
                    return (
                        <MenuItem key={key} item={item}/>
                    );
                })}
            </List>
        </>
    );

}

export default SidebarNav;
