import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Grid, TextField, Typography} from "@mui/material";
//import BrandSlider from "./../components/sliders/Brand";
import ButtonGroup from '@mui/material/ButtonGroup';

import {useNavigate, useSearchParams} from 'react-router-dom';
import CardContainer from "../components/elements/CardContainer";

import useMediaQuery from '@mui/material/useMediaQuery';
import {styled, useTheme} from '@mui/material/styles';
import Button from '@mui/material/Button';
import {getImagePath, setCookie} from '../utils/Functions';

var wH = window.innerHeight
const cTextField = styled(TextField)(({theme}) => ({

    height: 65

}));

var fieldType = "hidden"


function genRandonString(length) {
    var chars = '123456789';
    var charLength = chars.length;
    var result1 = '';
    for (var i = 0; i < 6; i++) {
        result1 += chars.charAt(Math.floor(Math.random() * charLength));
    }

    var chars2 = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charLength2 = chars2.length;
    var result2 = '';
    for (var i = 0; i < 5; i++) {
        result2 += chars2.charAt(Math.floor(Math.random() * charLength2));
    }

    var chars3 = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charLength3 = chars3.length;
    var result3 = '';
    for (var i = 0; i < 6; i++) {
        result3 += chars3.charAt(Math.floor(Math.random() * charLength3));
    }

    return result1 + result2 + result3;
}

const ColorButton = styled(Button)(({theme}) => ({
    color: theme.palette.getContrastText("#000"),
    fontSize: 20,
    fontWeight: "700",
    padding: 1,
    height: 65,
    borderRadius: 3,

    width: "100%",
    backgroundColor: "#f8632c",
    '&:hover': {
        backgroundColor: "#f8632c",
    },
}));


const Home = (props) => {


    const dispatch = useDispatch()
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showAmount, setShowAmount] = useState(false);
    const [error, setError] = useState("");

    const [amount, setAmount] = useState(120);


    const bodyInfo1 = [


        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",

                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",

                    "link": "",
                    "image": "",
                    "type": "text",
                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/home_web.png",
                    "imagestyle": {height: wH}
                }
            ]
        },
    ]

    const bodyInfo1Mobile = [

        {
            "id": 1,
            "full": "true",
            "childs": [
                {
                    "id": 0,
                    "size": "",
                    "title": "",

                    "updown": "JUSTIMAGE",
                    "body": "",
                    "button": "",
                    "buttonlink": "",
                    "buttonstyle": {},
                    "link": "#donation-form",
                    "image": "",
                    "type": "text",

                    "imagepath": process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/home_mobile.png"
                }
            ]
        },

    ]
    const [searchParams] = useSearchParams();
    const [finalData, setFinalData] = useState({

        monto: "120",
        texto: "",
        amount: "",
        trxuuid: genRandonString(17),
        utm_source: searchParams.get("utm_source") ? searchParams.get("utm_source") : "false",
        utm_medium: searchParams.get("utm_medium") ? searchParams.get("utm_medium") : "false",
        utm_campaign: searchParams.get("utm_campaign") ? searchParams.get("utm_campaign") : "false",
        utm_id: searchParams.get("utm_id") ? searchParams.get("utm_id") : "false",
        utm_term: searchParams.get("utm_term") ? searchParams.get("utm_term") : "false",
        utm_content: searchParams.get("utm_content") ? searchParams.get("utm_content") : "false",
    })


    const [monto, setMonto] = useState("");


    useEffect(() => {

        let utm_source = ""
        let utm_id = ""
        let utm_content = ""
        let utm_medium = ""
        let utm_campaign = ""
        let utm_term = ""
        let _finalData = finalData

        if (searchParams.get("utm_source")) {
            utm_source = searchParams.get("utm_source")
            _finalData["utm_source"] = utm_source
        }

        if (searchParams.get("utm_term")) {

            _finalData["utm_term"] = utm_term
            utm_term = searchParams.get("utm_term")
        }

        if (searchParams.get("utm_id")) {

            utm_id = searchParams.get("utm_id")
            _finalData["utm_id"] = utm_id
        }

        if (searchParams.get("utm_content")) {

            utm_content = searchParams.get("utm_content")
            _finalData["utm_content"] = utm_content
        }

        if (searchParams.get("utm_medium")) {

            utm_medium = searchParams.get("utm_medium")
            _finalData["utm_medium"] = utm_medium
        }

        if (searchParams.get("utm_campaign")) {

            utm_campaign = searchParams.get("utm_campaign")
            _finalData["utm_campaign"] = utm_campaign
        }


        setTimeout(() => {
            setFinalData(_finalData)
        }, 100);


        setCookie(process.env.REACT_APP_NAME + "utm_source", utm_source, 0.1)
        setCookie(process.env.REACT_APP_NAME + "utm_id", utm_id, 0.1)
        setCookie(process.env.REACT_APP_NAME + "utm_content", utm_content, 0.1)
        setCookie(process.env.REACT_APP_NAME + "utm_medium", utm_medium, 0.1)
        setCookie(process.env.REACT_APP_NAME + "utm_campaign", utm_campaign, 0.1)
        setCookie(process.env.REACT_APP_NAME + "utm_term", utm_term, 0.1)

    }, [finalData])


    const handleChange = (event) => {

        if (event.target.value === "") {
            setAmount(event.target.value)
        } else {

            if (/^[0-9]+$/.test(event.target.value)) {
                //console.log("mal")
                setError("")
                setAmount(event.target.value)
                if (event.key === 'Enter') {
                    //console.log("vaaa")
                    donate(event)
                }
            }
        }


    };

    const donate = (event) => {
        console.log("donate")
        if (parseInt(amount) >= 60) {
            //navigate("/donar/"+amount);

            finalData["monto"] = amount + ""
            finalData["amount"] = amount + ""

            //console.log(finalData)
            setTimeout(() => {
                document.getElementById("formCyber").submit();
            }, 100);

        } else {
            setError("El monto debe ser mayor a Bs 60")
        }
    }

    return (
        <div style={{minHeight: wH}}>
            {/*  <IntroPopup />*/}


            {fullScreen ?
                <>
                    <section className="home-slider position-relative ">
                        <CardContainer key={"card1"} data={bodyInfo1Mobile} config={{}}/>
                    </section>

                    <img src={getImagePath(process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/Texto_web.png")}
                         style={{
                             position: "absolute",
                             width: "290px",
                             top: "150px",
                             left: "-20px",
                         }}/>
                </>
                :
                <>
                    <section className="home-slider position-relative">
                        <CardContainer key={"card1"} data={bodyInfo1} config={{}}/>
                    </section>
                    <img src={getImagePath(process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/Logo.png")}
                         style={{
                             position: "absolute",
                             width: "130px",
                             top: "-25px",
                             left: "122px",
                         }}/>
                    <img src={getImagePath(process.env.REACT_APP_URL_CONFIG + "/assets/imgs/unicef/Texto_web.png")}
                         style={{
                             position: "absolute",
                             width: "680px",
                             top: "35px",
                         }}/>
                </>
            }

            <div className="donation-form" id="donation-form">


                <form id="formCyber" action="https://pdp.unicef.org.bo/public/formulario22" method="post">


                    <input type={fieldType} name="_token" value="dRcbRPGC0d0Wr8btDNMkpMhyJp4bMlrDHCpwnCRN"/>
                    <input type={fieldType} name="_token" value="dRcbRPGC0d0Wr8btDNMkpMhyJp4bMlrDHCpwnCRN"/>
                    <input type={fieldType} name="access_key" value="b07eaab29f8f3c03987860013cf9cb80"/>
                    <input type={fieldType} name="profile_id" value="FAD45E5D-7004-4962-BD59-AB4B3A36C431"/>
                    <input type={fieldType} name="transaction_uuid" value={finalData.trxuuid}/>
                    <input type={fieldType} name="monto" value={showAmount ? "texto" : amount}/>
                    <input type={fieldType} name="texto" value={showAmount ? amount : ""}/>
                    <input type={fieldType} name="amount" value={amount}/>
                    <input type={fieldType} name="utm_source" value={finalData.utm_source}/>
                    <input type={fieldType} name="utm_medium" value={finalData.utm_medium}/>
                    <input type={fieldType} name="utm_campaign" value={finalData.utm_campaign}/>
                    <input type={fieldType} name="utm_id" value={finalData.utm_id}/>
                    <input type={fieldType} name="utm_term" value={finalData.utm_term}/>
                    <input type={fieldType} name="utm_content" value={finalData.utm_content}/>
                    <input type={fieldType} name="sourceid" value="UNICEF"/>
                    <input type={fieldType} name="merchant_defined_data97" value="Transaccion+unica"/>


                    <Grid container spacing={0}>

                        <Grid item xs={12}>
                            <Typography
                                sx={{mb: {xs: 4, md: 3, lg: 2}, mt: {xs: 4, md: 3, lg: 2}}}

                                variant={fullScreen ? "h3" : "h4"}
                                style={{textAlign: "center", color: fullScreen ? "#009ce0" : "#fff"}}>
                                Selecciona tu donación mensual
                            </Typography>

                        </Grid>

                        <Grid item lg={3} xs={3}>
                            <ColorButton className={amount === 120 ? "donate-button-active" : "donate-button"} style={{
                                textTransform: "capitalize",
                                border: "2px solid #fff",
                                backgroundColor: amount === 120 ? "#009ce0" : "transparent"
                            }} onClick={() => {
                                setError("");
                                setAmount(120);
                                setMonto("");
                                setShowAmount(false)
                            }} variant="contained">Bs 120</ColorButton>
                        </Grid>

                        <Grid item lg={3} xs={3}>
                            <ColorButton className={amount === 90 ? "donate-button-active" : "donate-button"} style={{
                                textTransform: "capitalize",
                                border: "2px solid #fff",
                                backgroundColor: amount === 90 ? "#009ce0" : "transparent"
                            }} onClick={() => {
                                setError("");
                                setAmount(90);
                                setMonto("");
                                setShowAmount(false)
                            }} variant="contained">Bs 90</ColorButton>
                        </Grid>
                        <Grid item lg={3} xs={3}>
                            <ColorButton className={amount === 60 ? "donate-button-active" : "donate-button"} style={{
                                textTransform: "capitalize",
                                border: "2px solid #fff",
                                backgroundColor: amount === 60 ? "#009ce0" : "transparent"
                            }} onClick={() => {
                                setError("");
                                setAmount(60);
                                setMonto("");
                                setShowAmount(false)
                            }} variant="contained">Bs 60</ColorButton>
                        </Grid>
                        <Grid item lg={3} xs={3}>
                            <ColorButton
                                className={amount !== 120 && amount !== 90 && amount !== 60 ? "donate-button-active" : "donate-button"}
                                style={{
                                    textTransform: "capitalize",
                                    border: "2px solid #fff",
                                    backgroundColor: amount !== 120 && amount !== 90 && amount !== 60 ? "#009ce0" : "transparent"
                                }} onClick={() => {
                                setAmount("");
                                setShowAmount(true);
                                setMonto("texto");
                            }} variant="contained">Otro</ColorButton>
                        </Grid>
                        <Grid item lg={12} xs={12}

                              sx={{mb: {xs: 4, md: 3, lg: 2}, mt: {xs: 4, md: 2, lg: 1}}}

                        >
                            <div>
                                <h6 style={{padding: 4}}>*** No olvides activar tu tarjeta para compras por
                                    internet</h6>
                            </div>
                        </Grid>

                        <Grid item lg={12} xs={12} mt={1} style={{textAlign: "center"}}>
                            {showAmount ?
                                <ButtonGroup
                                    style={{width: "100%"}}
                                    disableElevation
                                    variant="contained"
                                    aria-label="Disabled elevation buttons"
                                >
                                    <TextField style={{width: "50%"}}
                                               className="amount-text"
                                               value={amount}
                                               onChange={handleChange}>
                                        onKeyDown={handleChange}
                                    </TextField>
                                    <ColorButton onClick={donate} style={{width: "50%"}}>Dona Ahora</ColorButton>
                                </ButtonGroup>
                                :
                                <ColorButton style={{borderRadius: 35, width: fullScreen ? "50%" : "100%"}}
                                             onClick={donate}>Dona Ahora</ColorButton>
                            }
                        </Grid>

                        {error !== "" &&
                            <Grid item lg={12} xs={12} mt={1}>
                                <div style={{
                                    background: "#000",
                                    zIndex: "99",
                                    fontSize: "1rem",
                                    fontWeight: 600,
                                    textAlign: "center",
                                    color: "#fff",
                                    padding: 5,
                                    borderRadius: 5
                                }}>
                                    <WarningAmberIcon variant="h6" sx={{fontSize: "2rem", marginRight: 1}}/>
                                    {error}
                                </div>
                            </Grid>
                        }


                    </Grid>
                </form>
            </div>


        </div>
    );
    /*

      const bodyInfo1B = [



        {
          "id": 1,
          "childs": [
            {
              "id": 0,
              "size": "",
              "title": "TÚ PUEDES CAMBIAR ESTAS HISTORIAS",
              titlestyle:{ color:"#f26a21"},
              buttonstyle: {float:"right", position: "relative", top: 14 },

              "button": "DONA AHORA",
              "buttonlink": "#donation-form",
              "image": "",
              "type": "text",
              "imagepath": "",
              "link": "#donation-form",
              "subtitle": "",
              "updown": "IMAGEDOWN",
              "body": "Cada día en promedio 4 niñas, niños y adolescentes son víctimas de algún tipo de violencia en el país. Por eso creamos la línea gratuita Familia Segura, un servicio gratuito de apoyo psico-emocional para prevenir y atender la violencia."
            },
            {
              "id": 2,
              "size": "",
              "title": "",

              "button": "",
              "buttonlink": "",
              "image": "",
              "type": "text",
              "imagepath": "blob:https://www.youtube.com/a0b5a273-1e3e-454d-8bc2-95d195229c17",
              "subtitle": "",
              "updown": "JUSTIMAGE", //JUSTVIDEO
              "body": " ",
              "link": "#"
            },

          ]
        },


      ]


      const bodyInfo1BMobile = [



        {
          "id": 1,
          "childs": [
            {
              "id": 0,
              "size": "",
              "title": "TÚ PUEDES CAMBIAR ESTAS HISTORIAS",
              titlestyle:{ color:"#f26a21"},
              buttonstyle: {float:"left", width:"100%", textAlign:"center" },

              "button": "DONA AHORA",
              "buttonlink": "#donation-form",
              "image": "",
              "type": "text",
              "imagepath": "",
              "link": "#donation-form",
              "subtitle": "",
              "updown": "IMAGEDOWN",
              "body": "Cada día en promedio 4 niñas, niños y adolescentes son víctimas de algún tipo de violencia en el país. Por eso creamos la línea gratuita Familia Segura, un servicio gratuito de apoyo psico-emocional para prevenir y atender la violencia."
            },
            {
              "id": 2,
              "size": "",
              "title": "",

              "button": "",
              "buttonlink": "",
              "image": "",
              "type": "text",
              "imagepath": "blob:https://www.youtube.com/a0b5a273-1e3e-454d-8bc2-95d195229c17",
              "subtitle": "",
              "updown": "JUSTIMAGE", //JUSTVIDEO
              "body": " ",
              "link": "#"
            },

          ]
        },


      ]


      const bodyInfo2 = [
        {
          "id": 1,
          "childs": [
            {
              "id": 0,
              "size": "",
              "title": "Tú también puedes ser un Héroe o Heroína de la Niñez",
              titlestyle:{ color:"#80bd41" , textAlign: "center"},

              "button": "",
              "buttonlink": "",
              "image": "",
              "type": "text",
              "imagepath": "",
              "link": "",
              "subtitle": "",
              "updown": "IMAGEDOWN",
              "body": ""
            }
          ]
        }
    ]

    const bodyInfo3 = [
        {
          "id": 1,
          "childs": [
            {
              "id": 0,
              "size": "",
              "title": "ÚNETE TU TAMBIÉN",
              titlestyle:{ color:"#f26a21" , textAlign: "center" },

              "button": "",
              "buttonlink": "",
              "image": "",
              "type": "text",
              "imagepath": "",
              "link": "",
              "subtitle": "",
              "updown": "IMAGEDOWN",
              "body": ""
            }
          ]
        },
        {
            "id": 1,
            "childs": [
              {
                "id": 0,
                "size": "",
                "title": "",
                buttonstyle: {textAlign: "center", position: "relative"   },
                "style":{textAlign: "center"},
                "button": "DONA AHORA",
                "buttonlink": "#donation-form",
                "image": "",
                "type": "text",
                "imagepath": "",
                "link": "#donation-form",
                "subtitle": "",
                "updown": "IMAGEDOWN",
                "body": ""
              }
            ]
          }
    ]


    const slider1=
    [
        {
          "id": 1,
          "childs": [
            {
              "id": 0,
              "size": "",
              "title": "JULIO DIAZ JUSTINIANO",
              "subtitle": "JULIO",
              "body": '"Mi esposa y yo somos “héroes”. Hace aproximadamente un año mi esposa perdió su trabajo y seis meses después yo perdí el mío. Lógicamente nuestra situación económica pasó a ser apremiante. A pesar de ello, nunca dejamos de aportar, porque nosotros creemos en el trabajo que se realiza gracias a nuestros aportes, porque estamos convencidos que los niños que reciben nuestra ayuda serán los héroes del mañana."',
              "button": "",
              "buttonlink": "",
              "image": "",
              "type": "multiple",
              "imagepath": process.env.REACT_APP_URL_CONFIG+"/assets/imgs/unicef/julio-1.jpg",
              "link": ""
            }
          ]
        },
        {
          "id": 2,
          "childs": [
            {
              "id": 0,
              "size": "",
              "title": "NATHALIE PINAYA BARREDA",
              "subtitle": "NATHALIE",
              "body": '"Fue el 24 de octubre de 2019, mientras caían lágrimas de mis ojos, cuando confirmé que ser HEROÍNA DE LA ÑINEZ, no solo se trata de dar un aporte a UNICEF, sino que es un compromiso con la niñez del país. Ese día recibí un video, Nayerli, una niña de 10 años de Montero que mostraba entusiasmada su nuevo baño, con inodoro y ducha, ella no tenía acceso a saneamiento básico, ya que antes tenía que ir a un hueco para hacer sus necesidades. Terminó el video con un agradecimiento a todos los héroes y dijo que tener un baño le daba salud y dignidad. Cerré los ojos y me pregunté ¿Qué haría yo sin tener acceso a un baño?."',
              "button": "",
              "buttonlink": "",
              "image": "",
              "type": "multiple",
              "imagepath": process.env.REACT_APP_URL_CONFIG+"/assets/imgs/unicef/nathali.jpg"
            }
          ]
        },
        {
            "id": 3,
            "childs": [
              {
                "id": 0,
                "size": "",
                "title": "CARLOS CORREA CUELLAR",
                subtitlestyle:{ color:"#e2231a", fontFamily: "Roboto" },
                "subtitle": "CARLOS",
                "body": '"Cuando me ofrecieron la oportunidad de aportar con mi granito de arena para cambiar la realidad de tantos niños y niñas, no lo pensé dos veces, y cada día me parece una mejor decisión. Son tiempos difíciles, muchos estamos batallando, tratando de dar lo mejor a nuestras familias, pero no podemos ignorar la triste y dura situación que enfrentan muchos niños y niñas."',
                "button": "",
                "buttonlink": "",
                "image": "",
                "type": "multiple",
                "imagepath": process.env.REACT_APP_URL_CONFIG+"/assets/imgs/unicef/carlios.webp"
              }
            ]
          },
      ]

      let slider2=
      [
          {
            "id": 1,

                "id": 0,
                "size": "",
                "title": "Ella es Julia ",
                "subtitle": "",
                "body": 'Tiene 7 años y durante la pandemia jugaba a ser invisible para esconderse de su agresor. ',
                "button": "",
                "buttonlink": "",
                "image": "",
                "type": "multiple",
                "imagepath": process.env.REACT_APP_URL_CONFIG+"/assets/imgs/unicef/1.webp",
                "link": ""
          },
          {
            "id": 2,

                "id": 0,
                "size": "",
                "title": "El es Carlitos",
                "subtitle": "",
                "body": 'No pudo volverá a clases porque tienen que trabajar, su familia necesita de su ayuda.',
                "button": "",
                "buttonlink": "",
                "image": "",
                "type": "multiple",
                "imagepath": process.env.REACT_APP_URL_CONFIG+"/assets/imgs/unicef/2.webp"
          },
          {
              "id": 3,
                "size": "",
                "title": "Ella es Anita",
                subtitlestyle:{ color:"#e2231a", fontFamily: "Roboto" },
                "subtitle": "",
                "body": 'Está en tratamiento psicoemocional debido a que fue victima de abuso sexual.',
                "button": "",
                "buttonlink": "",
                "image": "",
                "type": "multiple",
                "imagepath": process.env.REACT_APP_URL_CONFIG+"/assets/imgs/unicef/3.webp"
        }

        ]
*/
}

Home.propTypes = {};

export default Home;
