import actionTypes from "../action.types";
import * as ProcessService from "../../services/process.service";

export const clearMessages = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLEAR_MESSAGE });
  };
};

export const processing = (proccessname) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROCESS_REQUEST,
      payload: { proccessname: proccessname },
    });
  };
};

export const executed = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_SUCCESS, payload: { message: data } });
  };
};

export const processData = (componentName, action, data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROCESS_REQUEST,
      payload: { proccessname: componentName },
    });
    ProcessService.processData(dispatch, componentName, action, data);
  };
};

export const getFormData = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROCESS_REQUEST,
      payload: { proccessname: "getFormData" },
    });
    dispatch({ type: actionTypes.CLEAR_FORM });
    ProcessService.getFormData(dispatch, data);
  };
};

export const getComponentConfig = (data) => {
  console.log("getComponentConfig");
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROCESS_REQUEST,
      payload: { proccessname: "getComponentConfig" },
    });
    ProcessService.getComponentConfig(dispatch, data);
  };
};

export const saveDonationPersonalData = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SAVE_DONATION_PERSONAL_DATA,
      payload: data,
    });
    ProcessService.getComponentConfig(dispatch, data);
  };
};
