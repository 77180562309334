import React from "react";
import PropTypes from "prop-types";
import {Button, Container, Dialog, Grid, TextField, Typography,} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import * as Actions from "../../redux/actions/user.actions";
import {useDispatch, useSelector} from "react-redux";

import {styled} from '@mui/material/styles';

const FixedButton = styled(Button)(({theme}) => ({
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 10
}));


const validationSchema = yup.object({

    password: yup
        .string("Ingrese su contrase\u00F1a")
        //.min(8, "Password should be of minimum 8 characters length")
        .required("Contrase\u00F1a requerida"),

    newpassword: yup
        .string("Ingrese su contrase\u00F1a")
        .min(8, "La contrase\u00F1a debe contener al menos 8 caracteres")

        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[+-.!@#\$%\^&\*])(?=.{8,})/,
            "Debe contener 8 Caracteres, al menos 1 May\u00FAscula, 1 Min\u00FAscula, 1 N\u00FAmero y 1 Caracter Especial (Ej. # $ % & !)"
        )
        .required("Contrase\u00F1a requerida"),

    newpassword2: yup.string("Ingrese su contrase\u00F1a")
        .min(8, "La contrase\u00F1a debe contener al menos 8 caracteres")
        .oneOf([yup.ref('newpassword'), null], 'Las contrase\u00F1as no coinciden'),

});

const SignInDialog = (props) => {
    const {open, onClose} = props;
    const dispatch = useDispatch();
    const [resultMsg, setResultMsg] = React.useState('');


    const {defConfig, params, defLang, failed, error} = useSelector(
        (state) => state.Login
    );

    const {processSuccess, processError, requesting, processMessage} = useSelector(
        (state) => state.Process
    )

    const formik = useFormik({
        initialValues: {
            password: "",
            newpassword: "",
            newpassword2: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            // alert(JSON.stringify(values, null, 2));
            const data = {old_password: values.password, password: values.newpassword, proccessname: "CHANGE_PASSWORD"};

            console.log("data", data)
            dispatch(Actions.updatePassword(data));
            //window.location.reload()

        },
    });

    React.useEffect(() => {

        if (!requesting) {
            if (processSuccess) {
                formik.resetForm({})
                onClose();
            }
        }
        // loadCaptchaEnginge(6);
    }, [requesting, processSuccess, error, processMessage, processError, failed]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs">
            <Container>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container sx={{mt: 2, mb: 3}} spacing={1}>
                        <Grid item lg={12} xs={12}>
                            <Typography
                                variant="h5"
                            >
                                {defConfig[defLang].changepasslabel}
                            </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12} sx={{mt: 1}}>
                            <TextField
                                label="Contrase&ntilde;a Actual"
                                placeholder="Ingrese su Contrase&ntilde;a actual"
                                fullWidth
                                id="password"
                                name="password"
                                size="small"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.password && Boolean(formik.errors.password)
                                }
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12} sx={{mt: 2}}>
                            <TextField
                                label="Nueva Contrase&ntilde;a"
                                placeholder="Ingrese su nueva Contrase&ntilde;a"
                                fullWidth
                                id="newpassword"
                                name="newpassword"
                                size="small"
                                type="password"
                                value={formik.values.newpassword}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.newpassword && Boolean(formik.errors.newpassword)
                                }
                                helperText={formik.touched.newpassword && formik.errors.newpassword}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12} sx={{mt: 2}}>
                            <TextField
                                label="Repita la nueva Contrase&ntilde;a"
                                placeholder="Repita su nueva Contrase&ntilde;a"
                                fullWidth
                                id="newpassword2"
                                name="newpassword2"
                                size="small"
                                type="password"
                                value={formik.values.newpassword2}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.newpassword2 && Boolean(formik.errors.newpassword2)
                                }
                                helperText={formik.touched.newpassword2 && formik.errors.newpassword2}
                            />
                        </Grid>


                        <Grid
                            item
                            lg={12}
                            sx={{
                                mt: 1,
                            }}
                        >
                            {processError ?
                                <div style={{color: 'red'}}>{processError}</div>
                                : "	"
                            }
                            {processMessage ?
                                <div style={{color: 'green'}}>{processMessage}</div>
                                : "	"
                            }
                        </Grid>


                        <Grid
                            container
                            lg={12}
                            xs={12}
                            sx={{
                                mt: 1,
                            }}
                        >

                            <Grid item xs={6} md={6} lg={6} sx={{mt: 1, mb: 1, ml: {xs: 0, md: 0}, mr: {xs: 0, md: 0}}}>
                                <Button
                                    type="button"
                                    fullWidth
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        onClose()
                                    }}
                                    sx={{
                                        textTransform: "initial",
                                        fontWeight: {xs: "bold", md: "normal"},
                                        fontSize: {xs: "1rem", md: "0.9rem"}
                                    }}
                                >
                                    {defConfig[defLang].cancellabel}
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} sx={{mt: 1, mb: 1, ml: {xs: 0, md: 0}, mr: {xs: 0, md: 0}}}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    size="sm"
                                    variant="contained"
                                    sx={{
                                        textTransform: "initial",
                                        fontWeight: {xs: "bold", md: "normal"},
                                        fontSize: {xs: "1rem", md: "0.9rem"}
                                    }}
                                >
                                    {defConfig[defLang].changelabel}
                                </Button>
                            </Grid>


                        </Grid>

                    </Grid>
                </form>


            </Container>
        </Dialog>
    );
};

SignInDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SignInDialog;
