import actionTypes from "../action.types";

const initialState = {
  personalData: {},
};

const DonationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SAVE_DONATION_PERSONAL_DATA:
      return {
        ...state,
        personalData: {
          ...state.personalData,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default DonationReducer;
